export const navigation = [
  {
    title: 'Welcome',
    url: '/instructor/welcome/welcome-to-accounting-games'
  },
  // {
  //   title: 'Games Overview',
  //   url: '/instructor/welcome/mastering-fundamentals'
  // },
  // {
  //   title: 'How Scoring Works',
  //   url: '/instructor/welcome/how-scoring-works'
  // },
  // {
  //   title: 'Ways to Use',
  //   url: '/instructor/welcome/ways-to-use'
  // },
  // {
  //   title: 'Setting Up Class',
  //   url: '/instructor/welcome/setting-up-for-class'
  // }
];