import React, { createContext, useContext, useState } from 'react';

const SoundStatusContext = createContext();

export const useSoundStatus = () => {
  return useContext(SoundStatusContext);
};

export const SoundStatusProvider = ({ children }) => {
  const initialSoundStatus = localStorage.getItem('sound') || 'enabled';
  const [soundStatus, setSoundStatus] = useState(initialSoundStatus);

  const toggleSoundStatus = () => {
    const newStatus = soundStatus === 'enabled' ? 'disabled' : 'enabled';
    setSoundStatus(newStatus);
    localStorage.setItem('sound', newStatus);
  };

  return (
    <SoundStatusContext.Provider value={{ soundStatus, toggleSoundStatus }}>
      {children}
    </SoundStatusContext.Provider>
  );
};

export default SoundStatusProvider;
