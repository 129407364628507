export const leftbarItemsInstructor = [
  {
    title: 'Dashboard',
    url: '/instructor/dashboard',
    class: 'dashboard'
  },
  {
    title: 'Settings',
    url: '/instructor/settings/assignments',
    class: 'settings'
  },
  {
    title: 'Insights',
    url: '/instructor/insights/',
    class: 'insights'
  },
];

export const leftbarItemsUser = [
  {
    title: 'Welcome',
    url: '/welcome',
    class: 'dashboard'
  },
  {
    title: 'Assignments',
    url: '/assignments',
    class: 'games'
  },
  {
    title: 'Insights',
    url: '/insights/decision-insights/overall',
    class: 'insights'
  },
];