import { useRef, useEffect } from 'react';
import AnswerCorrectSound from './sounds/answer-correct.mp3';
import AnswerWrongSound from './sounds/answer-wrong.mp3';
import './styles.css';

export default function AnswerOptions({ 
  options,
  selectedOption,
  answerStatus,
  optionsDisabled,
  onOptionClick,
  playSound
}) {
  const answerCorrectEffect = useRef(new Audio(AnswerCorrectSound));
  const answerWrongEffect = useRef(new Audio(AnswerWrongSound));

  useEffect(() => {
    if(playSound) {
      if (answerStatus === 'correct') {
        answerCorrectEffect.current.play().catch(e => console.log('Error playing answer correct sound:', e));
      } else if (answerStatus === 'incorrect') {
        answerWrongEffect.current.play().catch(e => console.log('Error playing answer wrong sound:', e));
      }
    }
  }, [answerStatus, playSound]);

  return (
    <div className="transactions-answer-options">
      {options.map((option, index) => (
        <button 
          key={index}
          className={`${(option === selectedOption && answerStatus) ? `active ${answerStatus}` : ''}`}
          onClick={() => {
            if (!optionsDisabled) {
              onOptionClick(option);
            }
          }}
        >
          {option}
        </button>
      ))}
    </div>
  );
}
