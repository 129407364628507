import './styles.css';

export default function Hint({time}) {
  return (
    <div className="timer-wrapper">
      <div className="timer">
        {time}
      </div>
    </div>
  );
}
