import { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';

import {
  gamesQuery,
  getGamesSessions,
  createGameSession, 
  updateGameSession
} from './api';

import useAuth from '../../auth/useAuth';
import { useSoundStatus } from '../../context/SoundStatusContext';

import Modal from '../UI/modal';
import Hint from '../UI/hint';
import FallingBlock from '../UI/game/falling-block';
import Column from '../UI/game/column';
import FooterMessage from '../UI/game/footer-message';
import Header from '../UI/game/header';

import ClickSound from './sounds/click.mp3';
import ItemHitSpikeSound from './sounds/item-hit-spike.mp3';

import './styles.css';

import { 
  tutorialBlockItems,
  tutorialOptions
} from './data';

const maxPlayTime = 18000;

const blockHeight = 32;
const blockMaxWidth = 92;
const fallingAreaBottomGap = 19;
const blockFallingSpeed = 5;

export default function NormalBalanceTutorial() {
  const { 
    getUser,
    logout,
    getCurrentNormalGameId
  } = useAuth();
  const user = getUser();

  const gameId = getCurrentNormalGameId();

  const { soundStatus } = useSoundStatus();

  const clickEffect = useRef(new Audio(ClickSound));
  const itemHitSpikeEffect = useRef(new Audio(ItemHitSpikeSound));

  useEffect(() => {
    const playClickSound = () => {
      if (soundStatus === 'enabled') {
        clickEffect.current.play().catch(e => console.error('Error playing click sound:', e));
      }
    };

    document.addEventListener('click', playClickSound);

    return () => {
      document.removeEventListener('click', playClickSound);
    };
  }, [soundStatus]);

  const navigate = useNavigate();

  const fallingAreaRef = useRef(null);

  const [isFooterMessageShown, setIsFooterMessageShown] = useState(false);
  const [footerMessageType, setFooterMessageType] = useState();
  const [footerMessageDescription, setFooterMessageDescription] = useState();

  const [isReady, setIsReady] = useState(false);
  const [showIntro, setShowIntro] = useState(false);

  const handleMouseEnter = (id, hoverContent) => {
    setHoveredItem(id);

    setHintContent(hoverContent);
    setIsHintShown(true);
  }

  const handleMouseLeave = () => {
    setHoveredItem(0);
    setIsHintShown(false);
  }

  const handleMouseDown = () => {
    setHoveredItem(0);
    setIsHintShown(false);
  }

  const showFooterMessage = (type, description) => {
    setFooterMessageType(type);
    setFooterMessageDescription(description);
    setIsFooterMessageShown(true);
  }

  const [isHintShown, setIsHintShown] = useState(true);
  
  const [hintContent, setHintContent] = useState(<span>Where should <b>Assets</b> be placed? Let’s try to <b>drag and drop</b> it under <b>{tutorialOptions[0].name}</b>.</span>);
  
  const { data: gamesSessions } = useQuery({
    queryKey: ['gamesSessionsTutorial'],
    queryFn: getGamesSessions,
  });

  useEffect(() => {
    let redirectUrl = '';
    if(gameId && gamesSessions) {
      const currentGameSessions = gamesSessions.filter(session => session.user === user?.id && session.game === gameId);
      const incompleteSession = currentGameSessions.find(session => !session.completed);
      if (incompleteSession?.is_ready) {
        redirectUrl = `/normal/level/1/`;
      }
      navigate(redirectUrl);
    }
  }, [gamesSessions, navigate, user?.id, gameId]);

  const queryClient = useQueryClient();

  const createGameSessionMutation = useMutation({
    mutationFn: (data) => createGameSession(data),
    onSuccess: () => {
      queryClient.invalidateQueries(['gamesSessionsTutorial']);
    },
    onError() {
      console.log('Server error');
    }
  });
  
  const updateGameSessionMutation = useMutation({
    mutationFn: (data) => updateGameSession(data),
    onSuccess: () => {
      queryClient.invalidateQueries(['gamesSessionsTutorial']);
    },
    onError() {
      console.log('Server error');
    }
  });

  const handleReadyButtonClick = () => {
    setIsReady(true);
  }

  useEffect(() => {
    if (isReady) {
      setIsReady(false);
      if(gamesSessions.length) {
        const currentSession = gamesSessions.find(session => session.user === user?.id && session.game === gameId && session.is_ready !== true && !session.completed);
        if(currentSession) {
          updateGameSessionMutation.mutate({ id: currentSession.id, levelNumber: 1, status: 'in_progress' });
        } else {
          createGameSessionMutation.mutate({ game: gameId, user: user?.id });
        }
      } else {
        createGameSessionMutation.mutate({ game: gameId, user: user?.id });
      }
    }
  }, [user?.id, isReady, gamesSessions, gameId]);

  const [isModalShown, setIsModalShown] = useState(false);
  const modalContent = 
    <>
      <div className="modal-content">
        <p>Awesome! You’ve nailed this game already.</p>
        <p>You can get a higher score by <b>clearing the bricks accurately and fast</b>.</p>
        <p>Let’s start the real game.</p>
      </div>
      <div className="next-button" onClick={handleReadyButtonClick}>I’m Ready!</div>
    </>;

  const [isPlaying, setIsPlaying] = useState(false);
  const [time, setTime] = useState(0);
  const timeRef = useRef(time);
  useEffect(() => {
    timeRef.current = time;
  }, [time]);

  
  useEffect(() => {
    const timer = setTimeout(() => {
      setIsPlaying(true);
    }, 300);

    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    if (!isPlaying) return;

    const timerInterval = setInterval(() => {
      const newTime = timeRef.current + 1;
      setTime(newTime);
      if (newTime >= maxPlayTime) {
        clearInterval(timerInterval);
      }
    }, 1000);

    return () => clearInterval(timerInterval);
  }, [isPlaying]);

  const [fallingAreaWidth, setFallingAreaWidth] = useState(0);
  const [fallingAreaHeight, setFallingAreaHeight] = useState(0);

  useEffect(() => {
    const updateFallingAreaSizes = () => {
      const width = fallingAreaRef.current?.clientWidth || 0;
      const height = fallingAreaRef.current?.clientHeight || 0;
      setFallingAreaWidth(width);
      setFallingAreaHeight(height);
    };

    updateFallingAreaSizes();
    window.addEventListener('resize', updateFallingAreaSizes);

    return () => window.removeEventListener('resize', updateFallingAreaSizes);
  }, []);

  const [fallingBlocks, setFallingBlocks] = useState([]);
  const [availableBlockItems, setAvailableBlockItems] = useState([...tutorialBlockItems]);
  const [hoveredItem, setHoveredItem] = useState(0);

  const availableBlockItemsRef = useRef(availableBlockItems);

  useEffect(() => {
    availableBlockItemsRef.current = availableBlockItems;
  }, [availableBlockItems]);

  useEffect(() => {
    if (!isPlaying) return;
  
    const generateRandomBlock = () => {
      if (availableBlockItemsRef.current.length === 0) {
        return;
      }
      const randomIndex = Math.floor(Math.random() * availableBlockItemsRef.current.length);
      const randomBlock = availableBlockItemsRef.current[randomIndex];
      
      const isLeftSide = Math.random() < 0.5;
      let left = 'auto';
      let right = 'auto';
  
      if (isLeftSide) {
        left = Math.random() * (fallingAreaWidth / 2 - 25) + 5;
      } else {
        right = Math.random() * (fallingAreaWidth / 2 - 25) + 5;
      }
  
      const newBlock = {
        id: Date.now(),
        name: randomBlock.name,
        answers: randomBlock.answers,
        description: randomBlock.description,
        secondsFalling: randomBlock.secondsFalling,
        accuracyCoefficient: randomBlock.accuracyCoefficient,
        left: left,
        right: right,
        y: 0,
        width: blockMaxWidth,
      };
  
      setFallingBlocks(prevBlocks => [...prevBlocks, newBlock]);
  
      setAvailableBlockItems(prev => prev.filter((_, index) => index !== randomIndex));
    };
  
    // Initial call
    generateRandomBlock();
  
    // Interval calls
    const generateRandomBlockInterval = setInterval(generateRandomBlock, 3000);
    return () => clearInterval(generateRandomBlockInterval);
  }, [isPlaying, fallingAreaWidth]);
  
  const fallBlocks = () => {
    const bottomGap = blockHeight + fallingAreaBottomGap + blockFallingSpeed;
    setFallingBlocks((prevBlocks) => {
      const updatedBlocks = prevBlocks.map(block => {
        const blockY = hoveredItem === block.id ? block.y : block.y + blockFallingSpeed;
        return({
          ...block,
          y: blockY,
          secondsFalling: block.secondsFalling + 1
        })
      });

      const blocksToRemove = updatedBlocks.filter(block => block.y > fallingAreaHeight - bottomGap);

      if (blocksToRemove.length > 0) {
        if (soundStatus === 'enabled') {
          itemHitSpikeEffect.current.play().catch(e => console.log('Error playing item hit spike sound:', e));
        }
        
        const newAvailableBlocks = blocksToRemove.map(block => ({
          name: block.name,
          answers: block.answers,
          description: block.description,
          secondsFalling: block.secondsFalling,
          accuracyCoefficient: block.accuracyCoefficient
        }));

        setAvailableBlockItems(prevItems => {
          const filteredNewBlocks = newAvailableBlocks.filter(newBlock => 
            !prevItems.some(item => item.name === newBlock.name)
          );

          return [...prevItems, ...filteredNewBlocks];
        });

        return updatedBlocks.filter(block => !blocksToRemove.includes(block));
      }

      return updatedBlocks;
    });

  };

  useEffect(() => {
    if (!isPlaying) return;
    const fallBlocksInterval = setInterval(fallBlocks, 1000);
  
    return () => {
      clearInterval(fallBlocksInterval);
    };
  }, [isPlaying, fallingAreaHeight, hoveredItem]);
    
  const handleDrop = (item, column, callback) => {
    const isCorrect = item.answers.includes(column.name);

    const result = { is_correct: isCorrect };

    callback(null, result);

    const footerMessagePrefix = 'The Normal Balance for ';

    const footerMessageMiddle = result.is_correct
        ? ' is a '
        : ' is NOT a ';

    const footerMessageSuffix = ' Balance.';

    const footerMessage = <p>{footerMessagePrefix}<b>{item.name}</b>{footerMessageMiddle}<b>{column.name}</b>{footerMessageSuffix}</p>;

    if(isCorrect) {
      showFooterMessage('success', footerMessage);
      setTimeout(() => {
        setIsModalShown(true);
      }, 1000);
    } else {
      showFooterMessage('error', footerMessage);
      const { name, answers, description, secondsFalling } = item;
      setAvailableBlockItems(prevAvailableBlockItems => [
        ...prevAvailableBlockItems, 
        { name, answers, description, secondsFalling, accuracyCoefficient: 0.5 }
      ]);
    }

    setFallingBlocks(prevBlocks => prevBlocks.filter(block => block.id !== item.id));
  };

  const handleLogoutClick = () => {
    logout();
  }

  return (
    <div className="game-level-wrapper">
      {/* {
        showIntro && (
          <Intro
            title={`Level 1`}
            sidesToClose={true}
          />
      )} */}
      <Header 
        time={time}
        totalScore={0}
        handleLogoutClick={handleLogoutClick}
      />
      <section className="main-section">
        <div className="container columns">
          <Column
            side='left'
            options={tutorialOptions[0]}
            levelNumber={1}
            handleDrop={handleDrop}
            gameType="Normal Balance"
          />
          <main className="content">
            <div className="falling-area-container">
              <div className="falling-area" ref={fallingAreaRef}>
                {fallingBlocks.map((block) => (
                  <FallingBlock
                    key={block.id}
                    id={block.id}
                    name={block.name}
                    answers={block.answers}
                    description={block.description}
                    secondsFalling={block.secondsFalling}
                    accuracyCoefficient={block.accuracyCoefficient}
                    left={block.left}
                    right={block.right}
                    y={block.y}  
                    onMouseEnter={() => handleMouseEnter(block.id, block.description)}
                    onMouseLeave={handleMouseLeave}
                    onMouseDown={handleMouseDown}
                    {...(isHintShown && {
                      additionalContent: (
                        <Hint
                          content={hintContent}
                        />
                      )
                    })}
                  />
                ))}
              </div>
            </div>
          </main>
          <Column
            side='right'
            options={tutorialOptions[1]}
            levelNumber={1}
            handleDrop={handleDrop}
            gameType="Normal Balance"
          />
        </div>
      </section>
      <footer className="game-footer">
        {isFooterMessageShown && (
          <FooterMessage
            type={footerMessageType}
            description={footerMessageDescription}
          />
        )}
      </footer>
      {isModalShown && (
        <Modal
          content={modalContent}
        />
      )}
    </div>
  );
}
