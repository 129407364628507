import './styles.css';

import BadgeNoob from './images/noob.png';
import BadgeNewbie from './images/newbie.png';
import BadgeWarrior from './images/warrior.png';
import BadgeMaster from './images/master.png';

export const badges = [
  {
    title: 'Noob',
    image: BadgeNoob
  },
  {
    title: 'Newbie',
    image: BadgeNewbie
  },
  {
    title: 'Warrior',
    image: BadgeWarrior
  },
  {
    title: 'Master',
    image: BadgeMaster
  }
];

export default function InstructorBadges() {
  return (
    <ul className="instructor-badges">
      {badges.map((badge, index) => (
        <li key={index}>
          <img src={badge.image} alt={badge.title} />
        </li>
      ))}
    </ul>
  );
}
