import { useEffect, useState } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';

import useAuth from '../../auth/useAuth';

import './styles.css';
import LayoutWithLeftBar from '../UI/layout';

import { leftbarItemsInstructor, leftbarItemsUser } from './data';

export default function Root() {
  const location = useLocation();
  const navigate = useNavigate();
  const [isLayoutWithLeftBar, setIsLayoutWithLeftBar] = useState(false);

  const { isInstructor } = useAuth();

  useEffect(() => {
    if (location.pathname === '/') {
      navigateToLogin(navigate);
    }
    
    const LayoutWithLeftBar = (location.pathname.includes('/instructor') && !location.pathname.includes('/onboarding')) 
      || location.pathname.includes('/assignments') 
      || (location.pathname.includes('/welcome') && !location.pathname.includes('acc/welcome'))
      || location.pathname.includes('/insights');
    setIsLayoutWithLeftBar(LayoutWithLeftBar);
  }, [location, navigate]);

  const leftbarItems = isInstructor() ? leftbarItemsInstructor : leftbarItemsUser;

  return (
    <div className="Root">
      {
        isLayoutWithLeftBar ? (
          <LayoutWithLeftBar leftbarItems={leftbarItems}>
            <Outlet />
          </LayoutWithLeftBar>
        ) : (
          <>
            <Outlet />
          </>
        )
      }
    </div>
  );
}

function navigateToLogin(navigate) {
  navigate(`acc/login`);
}
