import SubPagesLayout from "../UI/layout/subPages";

import WelcomeVideo from './videos/intro-student.mp4';

import { navigation } from './data';
import Video from '../UI/introGameLayout/video';

export default function Welcome() {
  return (
    <SubPagesLayout 
      navigation={navigation}
      contentClassName="video-welcome-content"
    >
      <h1>Welcome</h1>
      <h2>Welcome to Accounting Games!</h2>
      <div className="welcome-video-wrapper">
        <Video videoSrc={WelcomeVideo} />
      </div>
    </SubPagesLayout>
  );
}
