import React, { useEffect, useRef, useState } from 'react';

import { useSoundStatus } from '../../../../context/SoundStatusContext';

import Pagination from './pagination';
import AnswerOptions from './answerOptions';
import Buttons from './buttons';

import TypingSound from './sounds/typing.mp3';

import './styles.css';

export default function TransactionsDecisions({ 
  transactions,
  activeTransaction,
  answerOptions,
  selectedAnswerOption,
  answerStatus,
  transactionToShow,
  correctlyAnsweredQuestions,
  onAnswerOptionClick,
  isNextButtonVisible,
  isPrevButtonVisible,
  onNextButtonClick,
  onPrevButtonClick
}) {  
  const descriptionRef = useRef(null);
  const questionRef = useRef(null);
  const [typedDescription, setTypedDescription] = useState('');
  const [typedQuestion, setTypedQuestion] = useState('');
  
  const typingEffect = useRef(new Audio(TypingSound));

  const { soundStatus } = useSoundStatus();

  useEffect(() => {
    if (transactions[activeTransaction]?.description) {
      typingEffect.current.volume = 0.2;
      typingEffect.current.loop = true;

      if (soundStatus === 'enabled') {
        typingEffect.current.play().catch(e => console.log('Error playing typing sound:', e));
      }

      const typedDescriptionInstance = new Typed(descriptionRef.current, {
        strings: [transactions[activeTransaction].description],
        typeSpeed: 20,
        smartBackspace: true,
        loop: false,
        showCursor: true,
        onComplete: (self) => {
          typingEffect.current.pause();
          setTypedDescription(self.strings.join(''));
          if (typedDescriptionInstance) {
            typedDescriptionInstance.destroy();
          }
        }
      });
  
      return () => {
        if (typedDescriptionInstance) {
          typingEffect.current.pause();
          typedDescriptionInstance.destroy();
        }
      };
    }
  }, [transactions, activeTransaction]);

  useEffect(() => {
    if (typedDescription && !typedQuestion) {
      typingEffect.current.volume = 0.2;
      typingEffect.current.loop = true;

      if (soundStatus === 'enabled') {
        typingEffect.current.play().catch(e => console.log('Error playing typing sound:', e));
      }

      const typedQuestionInstance = new Typed(questionRef.current, {
        strings: ['How would the above impact the company’s net profits in January?'],
        typeSpeed: 20,
        smartBackspace: true,
        loop: false,
        showCursor: true,
        onComplete: (self) => {
          typingEffect.current.pause();
          setTypedQuestion(self.strings.join(''));
          if (typedQuestionInstance) {
            typedQuestionInstance.destroy();
          }
        }
      });

      return () => {
        if (typedQuestionInstance) {
          typingEffect.current.pause();
          typedQuestionInstance.destroy();
        }
      };
    }
  }, [typedDescription, typedQuestion]);

  const questionWasAnswered = correctlyAnsweredQuestions.find(correctlyAnsweredQuestion => correctlyAnsweredQuestion.id === transactions[activeTransaction].id);
  const optionsDisabled = !!questionWasAnswered;
  const currentSelectedOption = (optionsDisabled && !transactionToShow) ? questionWasAnswered.answer : selectedAnswerOption;
  const currentAnswerStatus = (optionsDisabled && !transactionToShow) ? 'correct' : answerStatus;
  const playSound = !!answerStatus && soundStatus === 'enabled';

  return (
    <div className="transactions-decisions-wrapper">
      <Pagination 
        transactions={transactions}
        activeTransaction={activeTransaction}
      />
      <div className="transaction-description-question-wrapper">
        <p className="transaction-description"><span ref={descriptionRef}>{typedDescription && typedDescription}</span></p>
        
        <p className="transaction-question">
          {typedQuestion && <span ref={questionRef}>{typedQuestion}</span>}
          {!typedQuestion && <span ref={questionRef}></span>}
        </p>
      </div>

      <AnswerOptions
        options={answerOptions} 
        selectedOption={currentSelectedOption}
        answerStatus={currentAnswerStatus}
        optionsDisabled={optionsDisabled}
        onOptionClick={onAnswerOptionClick}
        playSound={playSound}
      />

      <Buttons
        isNextVisible={isNextButtonVisible}
        isPrevVisible={isPrevButtonVisible}
        onNextButtonClick={onNextButtonClick}
        onPrevButtonClick={onPrevButtonClick}
      />
    </div>
  );
}
