import './styles.css';

export default function BalanceSheetRow({
  name,
  value,
  isNegative,
  hasChildren,
  isChild
}) {

  return (
    <div className={`balance-sheet-row ${hasChildren ? 'has-children' : 0} ${isChild ? 'child' : ''}`}>
      <span>{name}</span>
      <span className={isNegative ? 'negative' : ''}>{value}</span>
    </div>
  );
}
