import './styles.css';

export default function InstructorAccountItemRadioButton({ label, isChecked, onCheck }) {
  const radioButtonId = `radio-${label.replace(/\s+/g, '-').toLowerCase()}`;

  return (
    <div className="account-item-radio">
      <input 
        type="radio" 
        id={radioButtonId} 
        checked={isChecked} 
        onChange={onCheck}
      />
      <label htmlFor={radioButtonId}>{label}</label>
    </div>
  );
}