import OptionItem from './optionItem'; 

export default function DropAreaList({
  items,
  onItemsDropped,
  isItemsDisabled
}) {
  return (
    <>
      {items.map((item, index) =>
        (
          <OptionItem
            key={index}
            id={item.id}
            accountItemId={item.accountItemId}
            name={item.name}
            description={item.description}
            type={item.type}
            onItemDropped={onItemsDropped}
            isIncorrect={item.isIncorrect}
            isItemDisabled={isItemsDisabled}
          />
        )
      )}
    </>
  );
}