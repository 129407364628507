import axiosClient from '../../api/axiosClient.js';

async function fetchGamesPage(url, allGames = []) {
  const response = await axiosClient.get(url);
  const games = [...allGames, ...response.data.results];
  
  if (response.data.next) {
    return fetchGamesPage(response.data.next, games);
  } else {
    return games;
  }
}

async function getGames() {
  return fetchGamesPage(`/api/games/games/`);
}

export const gamesQuery = () => {
  return {
    queryKey: ['games'],
    queryFn: async () => getGames(),
    refetchOnWindowFocus: false,
    cacheTime: 0
  };
};

async function fetchGamesSessions(url, allSessions = []) {
  const response = await axiosClient.get(url);
  const sessions = [...allSessions, ...response.data.results];
  
  if (response.data.next) {
    return fetchGamesSessions(response.data.next, sessions);
  } else {
    return sessions;
  }
}

export async function getGamesSessions() {
  return fetchGamesSessions(`/api/games/sessions/`);
}

export async function createGameSession({game, user}) {
  const { data } = await axiosClient.post(`/api/games/sessions/`, {
    game,
    user,
  });

  return data;
}
export async function updateGameSession({id, levelNumber, status, isSessionCompleted}) {
  const levelKey = `level${levelNumber}`;
  const requestBody = {
    is_ready: true,
    [levelKey]: status,
  };

  if (isSessionCompleted) {
    requestBody.completed = true;
  }

  const { data } = await axiosClient.patch(`/api/games/sessions/${id}/`, requestBody);

  return data;
}

async function getScenarioLevel({scenarioId, level}) {
  const response = await axiosClient.get(`/api/games/scenario-levels/?scenarios=${scenarioId}&level=${level}`);
  return response.data.results[0];
}

export const scenarioLevelQuery = ({scenarioId, level}) => {
  return {
    queryKey: [`gameScenarioLevel${level}`, scenarioId],
    queryFn: async () => getScenarioLevel({scenarioId, level}),
    refetchOnWindowFocus: false,
    enabled: !!scenarioId
  };
};

async function fetchScenarioLevelsPage(url, allScenarioLevels = []) {
  const response = await axiosClient.get(url);
  const scenarioLevels = [...allScenarioLevels, ...response.data.results];
  
  if (response.data.next) {
    return fetchScenarioLevelsPage(response.data.next, scenarioLevels);
  } else {
    return scenarioLevels;
  }
}

async function getScenarioLevels({scenarioId}) {  
  return scenarioId ? fetchScenarioLevelsPage(`/api/games/scenario-levels/?scenarios=${scenarioId}`) : [];
}

export const scenarioLevelsQuery = ({scenarioId, level}) => {
  return {
    queryKey: [`gameScenarioLevels_${level}`, scenarioId],
    queryFn: async () => getScenarioLevels({scenarioId}),
    refetchOnWindowFocus: false,
    cacheTime: 0
  };
};

async function fetchDecisionsPage(url, allTransactionDecisions = []) {
  const response = await axiosClient.get(url);
  const transactionDecisions = [...allTransactionDecisions, ...response.data.results];
  
  if (response.data.next) {
    return fetchDecisionsPage(response.data.next, transactionDecisions);
  } else {
    return transactionDecisions;
  }
}

export async function getDecisions({userId, gameId, level}) {
  return fetchDecisionsPage(`/api/equation/decisions/?user=${userId}&game=${gameId}&level=${level}`);
}

export async function makeDecision({
  user,
  game,
  timeElapsed,
  question,
  box,
  itemOption,
  selectedOption,
  selectedChange,
  selectedNormal,
  timestamp,
  groupId,
  gameSessionId
}) {
  const { data } = await axiosClient.post(`api/equation/decisions/`, {
    user,
    game,
    time_elapsed: timeElapsed,
    question,
    box,
    item_option: itemOption,
    selected_change: selectedChange,
    selected_normal: selectedNormal,
    selected_option: selectedOption,
    timestamp,
    group_id: groupId,
    game_session: gameSessionId
  });

  return data;
}

export async function updateDecision({id}) {
  const { data } = await axiosClient.put(`api/equation/decisions/${id}/`, {
    question_answered_correctly: true
  });

  return data;
}

export async function getScoresStats({gameId, attempt}) {
  const response = await axiosClient.get(`/api/equation/scores/stats/${gameId}/?attempt=${attempt}`);
  return response.data;
}

export const scoresStatsQuery = ({gameId, attempt}) => {
  return {
    queryKey: ['scoresStats'],
    queryFn: async () => getScoresStats({gameId, attempt}),
    refetchOnWindowFocus: false,
    enabled: !!gameId && !!attempt
  };
};