import './styles.css';

export default function Modal({content, isModalOverlayVisible = true, isModalSmall = false}) {
  return (
    <div className={`modal-overlay ${!isModalOverlayVisible ? 'hidden' : ''}`}>
      <div className={`modal ${isModalSmall ? 'small' : ''}`}>
        {content}
      </div>
    </div>
  );
}
