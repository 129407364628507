import './styles.css';

export default function Hint({
  content,
  buttonText,
  onButtonClick,
  side = ''
}) {
  const isHTML = typeof content === 'string' && /<\/?[a-z][\s\S]*>/i.test(content);

  return (
    <div className={`hint-container ${side}`}>
      <div className="hint">
        {isHTML ? (
          <span dangerouslySetInnerHTML={{ __html: content }} />
        ) : (
          <span>{content}</span>
        )}
        {
          buttonText && onButtonClick && 
            <button onClick={onButtonClick}>{buttonText}</button>
        }
      </div>
    </div>
  );
}
