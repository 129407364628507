import { useQuery, useMutation } from '@tanstack/react-query';

import SubPagesLayout from '../../../UI/layout/subPages';
import AssignmentsList from '../../../UI/assignments';
import InstructorAddAssignmentsRow from '../../../UI/instructor/settings/assignments/addRow';

import { gamesQuery, deleteGame, sessionsQuery } from './api';

import { navigation } from './data';

export default function InstructorSettingsAssignments() {
  const { data: games, refetch: refetchGames } = useQuery(gamesQuery());

  const sessionsResponse = useQuery(sessionsQuery()) || {};
  const sessions = sessionsResponse.data;

  const deleteGameMutation = useMutation({
    mutationFn: (data) => deleteGame(data)
  });

  const handleGameDelete = async (gameId, gameDeletedCallback) => {
    deleteGameMutation.mutate(
      { gameId },
      {
        onSuccess: () => {
          gameDeletedCallback();
          refetchGames();
        },
      }
    );
  };

  return (
    <SubPagesLayout
      navigation={navigation}
      contentClassName="adjustment-content-container"
    >
      <InstructorAddAssignmentsRow />
      <AssignmentsList 
        games={games}
        sessions={sessions}
        onGameDeletionConfirm={handleGameDelete}
      />
    </SubPagesLayout>
  );
}
