import './styles.css';

export default function Modal({title, content}) {
  return (
    <div className="intro-game-level-modal-container">
      <div className="intro-game-level-modal">
        <h2>{title}</h2>
        <div className="intro-game-level-modal-content">
          {content}
        </div>
      </div>
    </div>
  );
}
