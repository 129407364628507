import { useIsInstructorContainingPage } from '../../utils';

import Layout from '../UI/introGameLayout/layout';

import './styles.css';

export default function NotFound() {
  const isInstructorPage = useIsInstructorContainingPage();

  return (
    <Layout isHeaderVisible={!isInstructorPage}>
      <div className="page-content page-content-not-found">
        <div className="page-container">
          <h1 className="page-title">404</h1>
          <h2>Page not found</h2>
        </div>
      </div>
    </Layout>
  );
}
