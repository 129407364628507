import { useNavigate } from 'react-router-dom';

import SubPagesLayout from '../../UI/layout/subPages';
import InstructorBadges from '../../UI/instructor/welcome/badges';

import { navigation } from './data'; 

export default function InstructorWelcomeHowScoringWorks() {
  const navigate = useNavigate();

  const handlePrevButtonCLick = () => {
    navigate('/instructor/welcome/mastering-fundamentals');
  }

  const handleNextButtonCLick = () => {
    navigate('/instructor/welcome/ways-to-use');
  }

  return (
    <SubPagesLayout 
      navigation={navigation}
      onFirstButtonClick={handlePrevButtonCLick}
      onSecondButtonClick={handleNextButtonCLick}
    >
      <h1>How Scoring Works</h1>
      <h2>Points, Rewards, and Badges</h2>
      <p>The exercises feature a scoring system designed to encourage ongoing practice. Students earn points for making accurate and quick decisions, which then convert into rewards and badges that showcase their progress, development, and mastery of the material.</p>
      <InstructorBadges />
    </SubPagesLayout>
  );
}
