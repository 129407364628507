import { useState, useEffect } from 'react';
import { useDrag } from 'react-dnd';

import Hint from '../../../UI/hint';

export default function DroppedItem({
  item,
  side,
  isGameSorted,
  onItemDropped,
  onItemCorrectlyPlaced,
  onSortedItemClick,
  container
}) {
  const isItemDraggable = !item.isCorrectlyPlaced && isGameSorted;
  const [fleshRed, setFleshRed] = useState(item.isWrong);
  const [fleshGreen, setFleshGreen] = useState(!item.isWrong && !isItemDraggable && !item.hasAnimated);
  const [isCorrectlyPlaced, setIsCorrectlyPlaced] = useState(item.isCorrectlyPlaced);
  const [shouldAnimate, setShouldAnimate] = useState(!item.hasAnimated && !isGameSorted);

  useEffect(() => {
    setFleshRed(item.isWrong);
    if(isGameSorted) {
      setShouldAnimate(false);
      setIsCorrectlyPlaced(item.isCorrectlyPlaced);

      if(!item.isSortingTutorial) {
        setIsHintShown(false);
      }
    } else {
      setFleshGreen(!item.isWrong && !isItemDraggable && !item.hasAnimated);
    }
  }, [item.isWrong, isItemDraggable, isGameSorted, item.isCorrectlyPlaced, item.hasAnimated]);
  
  const [{ isDragging }, drag] = useDrag({
    type: 'block',
    item: {...item, onItemDropped},
    canDrag: isItemDraggable,
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    })
  });

  const pointsText = item.points >= 90
    ? 'Mastery!'
    : item.points >= 70
      ? 'Amazing!'
      : item.points >= 50
        ? 'Impressive!'
        : 'Not bad!';

  const handleClick = () => {
    if (isItemDraggable) {
      onSortedItemClick(item, container, (error, result) => {
        if (error) {
          console.log('Error in Decision Made: ', error);
          return;
        }
  
        if(result.is_correct) {
          onItemCorrectlyPlaced(item);
          setIsHintShown(false);
        } else {
          setShouldAnimate(true);
          setFleshRed(true);

          setTimeout(() => {
            setShouldAnimate(true);
            setFleshRed(false);
          }, 1500);
        }
      });
    }
  };

  const [isHintShown, setIsHintShown] = useState(item.isSortingTutorial || false);

  const handleMouseEnter = () => {
    setIsHintShown(true);
  }

  const handleMouseLeave = () => {
    setIsHintShown(false);
  }

  const handleMouseDown = () => {
    setIsHintShown(false);
  }

  const showPoints = !item.isWrong && item.points && !item.hasAnimated;

  const [hintContent, setHintContent] = useState(item.description);

  const handleItemHintNextButtonClick = () => {
    item.onHintButtonClick();

    const containerName = container.name;

    let gameTypeColumnText = '';
    if(containerName === 'Debit' || containerName === 'Credit') {
      gameTypeColumnText = 'Normal Balance';
    } else {
      gameTypeColumnText = 'Financial Statement Element';
    }
    const article = /^[aeiou]/i.test(container.name) ? 'an' : 'a';
    item.description = `Is this ${article} <b>${container.name} account?</b> If so, click on the account to confirm. If not, drag it to the ${gameTypeColumnText} it belongs under.`;
    setHintContent(item.description);
    item.onHintButtonClick = null;
  }

  return(
    <div
      key={`${side}-block-item-${item.id}`}
      className='block-item-container'
    >
      <div 
        className={`
          block-item 
          ${fleshRed ? 'flash-red' : ''} 
          ${fleshGreen ? 'flash-green' : ''} 
          ${shouldAnimate ? 'animate' : ''} 
          ${isCorrectlyPlaced ? 'correctly-placed' : ''}
          ${isItemDraggable ? 'with-hover-effect' : ''}
        `}
        style={{ 
          cursor: 'pointer',
          opacity: isDragging ? 0 : 1,
        }}
        ref={isItemDraggable ? drag : null}
        onClick={handleClick}
        onMouseEnter={isItemDraggable ? handleMouseEnter : null}
        onMouseLeave={(isItemDraggable && !item.keepHintVisible) ? handleMouseLeave : null}
        onMouseDown={!item.keepHintVisible ? handleMouseDown : null}
      >
        {
          showPoints &&
            <div className="block-item-points">
              <span>{pointsText}</span>
              <span>+ {Math.floor(item.points)}</span>
            </div>
        }
        {item.name}
      </div>

      {
        isHintShown &&
          <Hint 
            content={item.description}
            side={side}
            buttonText={item.hintButtonText}
            onButtonClick={item.onHintButtonClick ? handleItemHintNextButtonClick : null}
          />
      }
    </div>
  )
}