import axiosClient from '../../api/axiosClient.js';

async function fetchScenarioLevelsPage(url, allScenarioLevels = []) {
  const response = await axiosClient.get(url);
  const scenarioLevels = [...allScenarioLevels, ...response.data.results];
  
  if (response.data.next) {
    return fetchScenarioLevelsPage(response.data.next, scenarioLevels);
  } else {
    return scenarioLevels;
  }
}

async function getScenarioLevels(scenarioId) {  
  return scenarioId ? fetchScenarioLevelsPage(`/api/games/scenario-levels/?scenarios=${scenarioId}`) : [];
}

export const scenarioLevelsQuery = (scenarioId) => {
  return {
    queryKey: ['scenarioLevels', scenarioId],
    queryFn: async () => getScenarioLevels(scenarioId),
    refetchOnWindowFocus: false
  };
};

export async function makeTransactionDecision({game, question, selectedOption, timeElapsed}) {
  const { data } = await axiosClient.post(`api/profit/decisions/`, {
    game,
    question,
    selected_option: selectedOption,
    time_elapsed: timeElapsed
  });

  return data;
}

async function fetchTransactionDecisionsPage(url, allTransactionDecisions = []) {
  const response = await axiosClient.get(url);
  const transactionDecisions = [...allTransactionDecisions, ...response.data.results];
  
  if (response.data.next) {
    return fetchTransactionDecisionsPage(response.data.next, transactionDecisions);
  } else {
    return transactionDecisions;
  }
}

export async function getTransactionDecisions({userId, gameId}) {
  return fetchTransactionDecisionsPage(`/api/profit/decisions/?user=${userId}&game=${gameId}`);
}

async function fetchGamesSessions(url, allSessions = []) {
  const response = await axiosClient.get(url);
  const sessions = [...allSessions, ...response.data.results];
  
  if (response.data.next) {
    return fetchGamesSessions(response.data.next, sessions);
  } else {
    return sessions;
  }
}

export async function getGamesSessions() {
  return fetchGamesSessions(`/api/games/sessions/`);
}

export async function createGameSession({game, user}) {
  const { data } = await axiosClient.post(`/api/games/sessions/`, {
    game,
    user
  });

  return data;
}
export async function updateGameSession({id}) {
  const { data } = await axiosClient.patch(`/api/games/sessions/${id}/`, {
    is_ready: true
  });

  return data;
}

export async function getScoresStats({gameId}) {
  const response = await axiosClient.get(`/api/profit/scores/stats/${gameId}/`);
  return response.data;
}

export const scoresStatsQuery = ({gameId}) => {
  return {
    queryKey: ['scoresStats'],
    queryFn: async () => getScoresStats({gameId}),
    refetchOnWindowFocus: false
  };
};

export async function getScoresPrinciples({userId, gameId}) {
  const response = await axiosClient.get(`/api/profit/scores/principles/?user_id=${userId}&game_id=${gameId}/`);
  return response.data;
}

export const scoresPrinciplesQuery = ({userId, gameId}) => {
  return {
    queryKey: ['scoresPrinciples'],
    queryFn: async () => getScoresPrinciples({userId, gameId}),
    refetchOnWindowFocus: false
  };
};


export const decisionsQuery = ({userId, gameId}) => {
  return {
    queryKey: ['decisions', { userId, gameId }],
    queryFn: () => getTransactionDecisions({ userId, gameId }),
    refetchOnWindowFocus: false
  };
};