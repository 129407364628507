import { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';

import useAuth from '../../auth/useAuth';
import { useSoundStatus } from '../../context/SoundStatusContext';

import { 
  scenarioLevelsQuery,
  scoresStatsQuery,
  gamesQuery,
  getGamesSessions
} from './api';

import BadgeMaster from './images/badge-master.png';
import BadgeWarrior from './images/badge-warrior.png';
import BadgeNewbie from './images/badge-newbie.png';
import BadgeNoob from './images/badge-noob.png';

import BadgeMasterSound from './sounds/badge-master.mp3';
import BadgeWarriorSound from './sounds/badge-warrior.mp3';
import BadgeNewbieSound from './sounds/badge-newbie.mp3';
import BadgeNoobSound from './sounds/badge-noob.mp3';

import { fadeOutSound } from '../../utils';

import Layout from '../UI/introGameLayout/layout';

import './styles.css';

export default function GameBadge() {
  const navigate = useNavigate();

  const { getGuidedPlay, getCurrentClassificationGameId, getUser } = useAuth();

  const user = getUser();

  const shouldGuidedPagesBeDisplayed = getGuidedPlay();

  const { soundStatus } = useSoundStatus();
  const currentSoundEffect = useRef(null);

  const masterEffect = useRef(new Audio(BadgeMasterSound));
  const warriorEffect = useRef(new Audio(BadgeWarriorSound));
  const newbieEffect = useRef(new Audio(BadgeNewbieSound));
  const noobEffect = useRef(new Audio(BadgeNoobSound));

  const [score, setScore] = useState(0);
  const [badge, setBadge] = useState('');

  const gameId = getCurrentClassificationGameId();
  const [scenarioId, setScenarioId] = useState(0);
  
  const { data: games } = useQuery(gamesQuery()) || {};

  useEffect(() => {
    if(games) {
      const game = games.find(game => game.game_type.includes('Account Classification') && game.id === gameId);

      if(game && game.scenario) {
        setScenarioId(game.scenario);
      }
    }
  }, [games, gameId]);

  const { data: gamesSessions } = useQuery({
    queryKey: ['gamesSessionsAccuracy'],
    queryFn: getGamesSessions,
    refetchOnWindowFocus: false,
  });

  const [currentGameSessionAttempt, setCurrentGameSessionAttempt] = useState(0);

  useEffect(() => {
    if(gamesSessions && gameId && user) {
      const currentGameSession = gamesSessions.find(session => session.user === user?.id && session.game === gameId && !session.completed);
      
      if (currentGameSession) {
        setCurrentGameSessionAttempt(currentGameSession?.attempt);
      } else {
        const completedGameSession = gamesSessions
          .filter(session => session.user === user?.id && session.game === gameId && session.completed)
          .reduce((maxSession, session) => (session.id > (maxSession?.id || 0) ? session : maxSession), null);
  
          setCurrentGameSessionAttempt(completedGameSession?.attempt);
      }
    }
  }, [gamesSessions, gameId, user]);

  const { data: scenarioLevels } = useQuery(scenarioLevelsQuery({scenarioId}));
  const { data: scoresStats } = useQuery(scoresStatsQuery({ gameId, attempt: currentGameSessionAttempt }));

  useEffect(() => {
    if(scoresStats && scenarioLevels?.length) {
      const totalPoints = scoresStats.reduce((acc, scoresStat) => acc + scoresStat.user_points, 0);
      setScore(totalPoints);
      
      const totalQuestions = scenarioLevels.reduce((acc, scenarioLevel) => acc + scenarioLevel.questions.length, 0);

      const calculatedBadge = totalPoints >= 90 * totalQuestions
        ? 'Master'
        : totalPoints >= 70 * totalQuestions
          ? 'Warrior'
          : totalPoints >= 50 * totalQuestions
            ? 'Newbie'
            : 'Noob';

      setBadge(calculatedBadge);
    }
  }, [scoresStats, scenarioLevels]);

  useEffect(() => {
    if(badge) {
      if (soundStatus === 'enabled') {
        let soundEffect;
        switch (badge) {
          case 'Master':
            soundEffect = masterEffect.current;
            break;
          case 'Warrior':
            soundEffect = warriorEffect.current;
            break;
          case 'Newbie':
            soundEffect = newbieEffect.current;
            break;
          case 'Noob':
            soundEffect = noobEffect.current;
            break;
          default:
            return;
        }
  
        currentSoundEffect.current = soundEffect;
        currentSoundEffect.current.volume = 0.3;
        soundEffect.play().catch(e => console.error('Error playing sound:', e));
      } else {
        if (currentSoundEffect.current) {
          currentSoundEffect.current.pause();
        }
      }

      return () => {
        if (currentSoundEffect.current) {
          currentSoundEffect.current.pause();
          currentSoundEffect.current.currentTime = 0;
        }
      };
    }
  }, [badge, soundStatus]);

  const badgeImages = {
    Master: BadgeMaster,
    Warrior: BadgeWarrior,
    Newbie: BadgeNewbie,
    Noob: BadgeNoob,
  };

  const badgeDescriptions = {
    Master: 'Outstanding! You’ve earned the Accounting Master badge!',
    Warrior: 'Wonderful! You’ve earned the Accounting Warrior badge!',
    Newbie: 'Not Bad! You’ve earned the Accounting Newbie badge!',
    Noob: 'You’ve earned the Accounting Noob badge!'
  };

  useEffect(() => {
    if (score > 0 && scenarioLevels) {
      const fadeOutTimer = setTimeout(() => {
        if (currentSoundEffect.current) {
          fadeOutSound(currentSoundEffect.current, 2000); // Fade out over 2 seconds
        }
      }, 8000);
      
      const navigateTimer = setTimeout(() => {
        navigate(`/classification/level/${scenarioLevels.length}/accuracy`);
      }, 10000);

      return () => {
        clearTimeout(fadeOutTimer);
        clearTimeout(navigateTimer);
      };
    }
  }, [score, navigate, scenarioLevels, shouldGuidedPagesBeDisplayed]);

  return (
    <Layout isHeaderVisible={true}>
      <div className="page-content badge-page-content">
        <h1 className="badge-page-title">Level {scenarioLevels?.length}</h1>
        {
          score > 0 &&
            <>
              <p className="badge-page-description">{badgeDescriptions[badge]}</p>
              <div className="badge-container">
                <div className="badge-info">
                  <h3 className="badge-game-type">Account Classification</h3>
                  <h2 className="badge-rank">{badge}</h2>
                </div>
                <img className="badge-image" src={badgeImages[badge]} alt={`${badge} Badge`} />
              </div>
            </>
        }
      </div>
    </Layout>
  );
}