import useAuth from '../../../../auth/useAuth';

export default function UserName() {
  const { getUser } = useAuth();

  const user = getUser();
  const username = user?.alias_name 
    ? user?.alias_name 
    : `${user?.first_name}.${user?.last_name.charAt()}`;

  return (
    <span className="header-username">{username}</span>
  );
};
