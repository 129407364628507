import { useState, useEffect } from 'react';
import { useSoundStatus } from '../../../../context/SoundStatusContext';
import SoundEnabledIcon from './images/icon-sound-enabled.svg';
import SoundDisabledIcon from './images/icon-sound-disabled.svg';

export default function SoundButton() {
  const [iconSrc, setIconSrc] = useState();

  const { soundStatus, toggleSoundStatus } = useSoundStatus();

  useEffect(() => {
    const icon = soundStatus === 'enabled' ? SoundEnabledIcon : SoundDisabledIcon;
    setIconSrc(icon);
  }, [soundStatus]);

  return (
    <button 
      onClick={toggleSoundStatus}
      className="sound-status"
    >
      <img src={iconSrc} alt={`Sound is ${soundStatus}`}/>
    </button>
  );
};
