import Bars from './bars';
import './styles.css';

export default function Analysis({
  heading = 'Analysis',
  data
}) {
  return (
    <div className="summary-pages-analysis">
      <div className="analysis-header">
        <h2>{heading}</h2>
      </div>
      <div className="analysis-body">
        {
          data.map((analysisBlock, index) => (
            <div key={index} className="analysis-block">
              {
                analysisBlock.title &&
                  <div className="analysis-block-title-wrapper">
                    <h3 className="analysis-block-title">{analysisBlock.title}</h3>
                    {
                      analysisBlock.subtitle &&
                        <h4 className="analysis-block-subtitle">{analysisBlock.subtitle}</h4>
                    }
                  </div>
              }
              <Bars bars={analysisBlock.bars} label={analysisBlock.barsLabel} />
            </div>
          ))
        }
      </div>
    </div>
  );
}
