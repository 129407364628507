import { Link, useLocation } from 'react-router-dom';

import './styles.css';

import { getPathSegments } from '../../../../utils';
import useAuth from '../../../../auth/useAuth';

export default function SubPagesLayout({
  children,
  navigation,
  contentClassName = '',
  firstButtonName = 'Previous',
  onFirstButtonClick,
  secondButtonName = 'Next',
  onSecondButtonClick,
  showPagination = true,
  currentPage = 1,
  pagesQuantity = 1
}) {
  const location = useLocation();
  const { isStudent } = useAuth();

  return (
    <div className="subpages-layout">
      <div className="subpages-layout-leftbar">
        <ul>
          {navigation.map((navigationItem, index) => {
            const isActiveLink = (location.pathname === navigationItem.url) // highlight the exact same link as we are on
              || (getPathSegments(location.pathname).slice(0, 3).join('/') === getPathSegments(navigationItem.url).slice(0, 3).join('/')) // highlight links with the same 3 levels of depth
              || (isStudent() && getPathSegments(location.pathname).slice(0, 2).join('/') === getPathSegments(navigationItem.url).slice(0, 2).join('/')) // highlight links with the same 2 levels of depth for students
              || (location.pathname === '/instructor/dashboard' && navigationItem.url === '/instructor/welcome/welcome-to-accounting-games') // highlight first Welcome link on Dashboard page opened
              || (location.pathname === '/instructor/insights/' && navigationItem.url === '/instructor/insights/overall/leaderboard'); // highlight first insights link on Insights page opened

            return (
              <li key={index} className={`${navigationItem.children ? 'has-children' : ''} ${isActiveLink ? 'contains-active-link' : ''}`}>
                <Link
                  to={navigationItem.url}
                  className={`${isActiveLink ? 'active' : ''}`}
                  title={navigationItem.title}
                >
                  {navigationItem.title}
                </Link>
                {
                  navigationItem.children &&
                    <ul className="subpages-layout-children">
                      {navigationItem.children.map((navigationChild, index) => {
                        const isActiveLink = location.pathname === navigationChild.url
                        || (getPathSegments(location.pathname).slice(0, 4).join('/') === getPathSegments(navigationChild.url).slice(0, 4).join('/')) // highlight links with the same 4 levels of depth
                        || (location.pathname === '/instructor/insights/' && navigationChild.url === '/instructor/insights/overall/leaderboard'); // highlight first insights overall link on Insights page opened;
                        return (
                          <li key={index}>
                            <Link
                              to={navigationChild.url}
                              className={`${isActiveLink ? 'active' : ''}`}
                              title={navigationChild.title}
                            >
                              {navigationChild.title}
                            </Link>
                          </li>
                        );
                      })}
                    </ul>
                }
              </li>
            );
          })}
        </ul>
      </div>
      <div className={`subpages-layout-content ${contentClassName}`}>
        <div className="content-wrapper">
          {children}
        </div>

        {(onFirstButtonClick || onSecondButtonClick) && (
          <div className="subpages-layout-bottom-navigation">
            {onFirstButtonClick && (
              <button className="subpages-first-button" onClick={onFirstButtonClick}>
                {firstButtonName}
              </button>
            )}
            {
              showPagination &&
                <span className="subpages-layout-pagination">{currentPage} / {pagesQuantity}</span>
            }
            {onSecondButtonClick && (
              <button className="button-blue subpages-second-button" onClick={onSecondButtonClick}>
                {secondButtonName}
              </button>
            )}
          </div>
        )}
      </div>
    </div>
  );
}
