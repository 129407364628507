import React, { useEffect, useState, useRef } from 'react';
import './styles.css';

export default function IncomeStatement({
  title,
  revenues,
  expenses,
  shouldSlideIn = false
}) {
  const [displayedRevenues, setDisplayedRevenues] = useState(0);
  const [displayedExpenses, setDisplayedExpenses] = useState(0);
  const [displayedNetProfit, setDisplayedNetProfit] = useState(0);

  const prevRevenuesRef = useRef(0);
  const prevExpensesRef = useRef(0);

  useEffect(() => {
    updateScoreWithDelay(setDisplayedRevenues, displayedRevenues, revenues);

    updateScoreWithDelay(setDisplayedExpenses, displayedExpenses, expenses);

    const netProfit = revenues + expenses;

    if (expenses <= 0) {
      updateScoreWithDelay(setDisplayedNetProfit, displayedNetProfit, netProfit);
    } else {
      const displayedNetProfitValue = revenues - expenses;
      updateScoreWithDelay(setDisplayedNetProfit, displayedNetProfit, displayedNetProfitValue);
    }

    prevRevenuesRef.current = revenues;
    prevExpensesRef.current = expenses;
  }, [revenues, expenses]);

  const updateScoreWithDelay = (setState, currentScore, endScore) => {
    if (currentScore !== endScore) {
      const duration = 1500;
      const steps = 20;
      const stepTime = duration / steps;
      let currentStep = 0;

      const totalChange = endScore - currentScore;
      const changePerStep = totalChange / steps;

      const interval = setInterval(() => {
        currentStep++;
        const newScore = currentScore + changePerStep * currentStep;
        setState(newScore);

        if (currentStep === steps) {
          clearInterval(interval);
          setState(endScore);
        }
      }, stepTime);

      return () => clearInterval(interval);
    }
  };

  const isNegativeExpenses = displayedExpenses < 0;
  const isNegativeProfit = displayedNetProfit < 0;

  return (
    <div className={`transactions-income-statement ${shouldSlideIn ? 'should-slide-in' : ''}`}>
      <h2>{title}</h2>
      <ul className="income-statement-items">
        <li>
          <span>Revenues</span>
          <span>
            {displayedRevenues.toLocaleString('en-US', {
              style: 'currency',
              currency: 'USD',
              minimumFractionDigits: 0
            })}
          </span>
        </li>
        <li>
          <span>Expenses</span>
          <span className={isNegativeExpenses ? 'negative' : ''}>
            {displayedExpenses.toLocaleString('en-US', {
              style: 'currency',
              currency: 'USD',
              minimumFractionDigits: 0
            })}
          </span>
        </li>
        <li>
          <span>Net Profit</span>
          <span className={isNegativeProfit ? 'negative' : ''}>
            {displayedNetProfit.toLocaleString('en-US', {
              style: 'currency',
              currency: 'USD',
              minimumFractionDigits: 0
            })}
          </span>
        </li>
      </ul>
    </div>
  );
}
