export default function QuestionDecicionItem({ 
  title,
  isCorrect,
  viewLink,
  activity,
  textColor,
  backgroundColor
}) {
  const status = isCorrect ? 'Correct' : 'Incorrect';
  return (
    <li>
      <span className="question-decision-info">
        <span>
          <span className="question-decision-title">{title}</span>
          <span className={`question-decision-status ${status.toLowerCase()}`}>{status}</span>
        </span>
        <a href={viewLink} target="_blank" rel="noreferrer">View</a>
      </span>
      <span className="question-decision-activity" style={{color: textColor, backgroundColor: backgroundColor}}>
        {activity}
      </span>
    </li>
  );
};
