import { useNavigate } from 'react-router-dom';

import SubPagesLayout from '../../UI/layout/subPages';

import InstructorVideo from './videos/intro-instructor.mp4';

import { navigation } from './data';
import Video from '../../UI/introGameLayout/video';

export default function InstructorWelcomeToAccountingGames() {
  const navigate = useNavigate();

  const handleNextButtonCLick = () => {
    navigate('/instructor/welcome/mastering-fundamentals');
  }

  return (
    <SubPagesLayout 
      navigation={navigation}
      contentClassName="video-welcome-content"
      // onSecondButtonClick={handleNextButtonCLick}
    >
      <h1>Welcome</h1>
      <h2>Welcome to Accounting Games!</h2>
      <div className="welcome-video-wrapper">
        <Video videoSrc={InstructorVideo} />
      </div>
      {/* <p>We are excited to introduce these new, engaging resources for your financial accounting classes. Our goal is to support your teaching by providing tools that bring accounting principles to life in a fun and memorable way.</p>
      <InstructorGames /> */}
    </SubPagesLayout>
  );
}
