import './styles.css';

export default function InstructorAccountItemCheckbox({ label, isChecked, onCheck }) {
  const checkboxId = `checkbox-${label.replace(/\s+/g, '-').toLowerCase()}`;

  return (
    <div className="account-item-checkbox">
      <input 
        type="checkbox" 
        id={checkboxId} 
        checked={isChecked} 
        onChange={onCheck}
      />
      <label htmlFor={checkboxId}>{label}</label>
    </div>
  );
}