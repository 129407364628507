import React, { useEffect, useRef, useState } from 'react';

import { useSoundStatus } from '../../../../context/SoundStatusContext';

import Avatar from './../avatar';
import './styles.css';

import MessageAppearsSound from './sounds/message-appears.mp3';

export default function Message({
  name,
  text,
  alignment,
  onTypingCompleted,
  fadeIn,
  displayAvatar,
  size = '',
  shouldPlayAppearMessage = true
}) {
  const messageAppearsEffect = useRef(null);
  const [displayedText, setDisplayedText] = useState('');

  const { soundStatus } = useSoundStatus();

  useEffect(() => {
    if (!messageAppearsEffect.current) {
      messageAppearsEffect.current = new Audio(MessageAppearsSound);

      if (soundStatus === 'enabled' && shouldPlayAppearMessage) {
        messageAppearsEffect.current.play().catch(e => console.log('Error playing message appears sound:', e));
      }
    }

  }, []);

  useEffect(() => {
    setDisplayedText(text);
    const displayTimeout = setTimeout(() => {
      if (onTypingCompleted) {
        onTypingCompleted();
      }
    }, 500);

    return () => clearTimeout(displayTimeout);
  }, [text, onTypingCompleted, soundStatus]);

  return (
    <div className={`popup-message-container ${name.toLowerCase()}-message-container ${alignment}-aligned ${fadeIn ? 'fade-in' : ''}`}>
      {displayAvatar && <Avatar name={name} />}
      <div className={`popup-message-text-wrapper ${!displayAvatar ? 'without-avatar' : ''} ${size}`}>
        <span className="popup-message-name">{name}</span>
        <div className="popup-message-text">
        <span dangerouslySetInnerHTML={{ __html: displayedText }}></span>
        </div>
      </div>
    </div>
  );
}
