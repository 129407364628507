export const popupMessagesTutorialStart = [
  {
    name: 'Bailey',
    text: 'Welcome! I\'m Bailey, and this is Benny!',
    alignment: 'left'
  },
  {
    name: 'Benny',
    text: 'Here at Ledger Legends, we\'re all about helping small businesses navigate the twists and turns of their financial journey.',
    alignment: 'right'
  },
  {
    name: 'Bailey',
    text: 'Today is training day! Your assignment is to explore the financial data of one of our clients, Emma, the owner of <b>Bean Counter</b>. Since you\'re new, this will be a simulated experience.',
    alignment: 'left'
  },
  {
    name: 'Benny',
    text: 'We want to see how well you understand the core principles, assumptions, and constraints that drive financial reporting.',
    alignment: 'right'
  },
  {
    name: 'Bailey',
    text: 'Ready to dive into the world of numbers with us?',
    alignment: 'left'
  },
];

export const popupMessagesTutorialEnd = [
  {
    name: 'Bailey',
    text: 'Nice work! $500 would be the correct profits. Here’s your Mastery Skills Index Score:  1500',
    alignment: 'left'
  },
  {
    name: 'Benny',
    text: 'Now it’s time to introduce you to Emma and her small business <b>Bean Counter</b>, a vibrant coffee shop in the heart of Universe town',
    alignment: 'right'
  }
];

export const tutorialTransactions = [
  {
    id: 1,
    description: 'Let’s assume you have a business and in the month of January, you received $1,000 in cash from selling and delivering your product to various customers.',
    value: 1000,
    explanation: 'Under ""Revenue Recognition principle"", the sales would be recorded as revenues and would increase profits. Click Next to continue.'
  },
  {
    id: 2,
    description: 'Let’s assume the product that you sold costs you $500 to make.',
    value: 500,
    explanation: 'Under Matching principles, this is an expense and it should be recorded in the same accounting period of the associated sale. Click Next to continue.'
  }
];

export const tutorialAnswers = [
  {
    id: 1,
    answer: 'Increase Profits'
  },
  {
    id: 2,
    answer: 'Decrease Profits'
  }
];

export const popupMessagesGameStart = [
  {
    name: 'Emma',
    text: 'Hey there! Welcome to Bean Counter. I\'m Emma.',
    alignment: 'left'
  },
  {
    name: 'Emma',
    text: 'Bean Counter\'s been buzzing for three years, serving up the best brews and bites in town.',
    alignment: 'left'
  },
  {
    name: 'Emma',
    text: 'I need your help in putting together my financial numbers for the month.  Investors are asking about monthly profits and I have no idea if I’m even profitable.',
    alignment: 'left'
  },
  {
    name: 'Emma',
    text: 'Could you review our business activity for the month and let us know if we’re profitable?',
    alignment: 'left'
  }
];

export const popupMessagesGameEnd = [
  {
    name: 'Emma',
    text: 'Thanks, {name}! Your help saved me so much time! I now have a better grasp of my performance and can convey that to investors.',
    alignment: 'left'
  },
  {
    name: 'Benny',
    text: 'Nice work {name}!',
    alignment: 'right'
  },
  {
    name: 'Bailey',
    text: 'It’s review time!',
    alignment: 'left'
  }
];

export const transactionsAnswerOptions = [
  'Increase Profits',
  'Decrease Profits',
  'No Impact'
];

export const popupMessagesPeerBenchmark = [
  {
    name: 'Benny',
    text: 'Here’s how you perform in relation to others',
    alignment: 'left'
  }
];

export const popupMessagesPrinciplesMastery = [
  {
    name: 'Bailey',
    text: 'Here’s how well you understood each of the principles, assumptions, and/or constraints that appeared in the task',
    alignment: 'left'
  }
];