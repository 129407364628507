import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';

import {
  getGamesSessions,
  createGameSession, 
  updateGameSession
} from './api';

import useAuth from '../../auth/useAuth';

import Layout from '../UI/introGameLayout/layout';

import BackgroundImage from './images/tutorial-background.jpg';
import BackgroundStars1 from './images/background-stars-1.png';
import BackgroundStars2 from './images/background-stars-2.png';

import TutorialProcessSound from './sounds/tutorial-process.mp3';

import { 
  popupMessagesTutorialStart,
  popupMessagesTutorialEnd,
  tutorialTransactions,
  tutorialAnswers,
  transactionsAnswerOptions
} from './data';

import BackgroundSound from '../UI/introGameLayout/backgroundSound';
import PopupMessages from '../UI/introGameLayout/popupMessages';
import TransactionsDecisions from '../UI/theProfit/transactionsDecisions';
import IncomeStatement from '../UI/theProfit/incomeStatement';

import './styles.css';
import Explanation from '../UI/introGameLayout/explanation';

export default function Tutorial() {
  const navigate = useNavigate();
  
  const { getUser, getCurrentGameId } = useAuth();
  const user = getUser();
  const username = user?.alias_name 
    ? user?.alias_name 
    : user?.first_name 
      ? user?.first_name
      : user?.username
        ? user?.username 
        : '';
        
  const currentGameId = getCurrentGameId();

  const [isExplanationCenterVisible, setIsExplanationCenterVisible] = useState(false);

  const handleStartPopupMessagesButtonClick = () => {
    setIsPopupMessagesVisible(false);
    setIsExplanationCenterVisible(true);
    setIsBackgroundStarsVisible(false);
  };

  const { data: gamesSessions } = useQuery({
    queryKey: ['gamesSessionsTutorial'],
    queryFn: getGamesSessions,
  });

  useEffect(() => {
    if (gamesSessions && gamesSessions.some(session => session.user === user?.id && session.is_ready === true && session.game === currentGameId)) {
      navigate('/the-profit/level/1');
    }
  }, [gamesSessions, navigate, user?.id, currentGameId]);

  const queryClient = useQueryClient();

  const createGameSessionMutation = useMutation({
    mutationFn: (data) => createGameSession(data),
    onSuccess: () => {
      queryClient.invalidateQueries(['gamesSessionsTutorial']);
    },
    onError() {
      console.log('Server error');
    }
  });
  
  const updatedGameSessionMutation = useMutation({
    mutationFn: (data) => updateGameSession(data),
    onSuccess: () => {
      queryClient.invalidateQueries(['gamesSessionsTutorial']);
    },
    onError() {
      console.log('Server error');
    }
  });

  const handleEndPopupMessagesButtonClick = () => {
    if(gamesSessions.length) {
      const currentSession = gamesSessions.find(session => session.user === user?.id && session.game === currentGameId);
      if(currentSession) {
        updatedGameSessionMutation.mutate({ id: currentSession.id });
      } else {
        createGameSessionMutation.mutate({ game: currentGameId, user: user?.id });
      }
    } else {
      createGameSessionMutation.mutate({ game: currentGameId, user: user?.id });
    }
  };

  const [isBackgroundStarsVisible, setIsBackgroundStarsVisible] = useState(true);
  
  const [isPopupMessagesVisible, setIsPopupMessagesVisible] = useState(true);
  const [popupMessages, setPopupMessages] = useState(popupMessagesTutorialStart);
  const [popupMessagesButtonText, setPopupMessagesButtonText] = useState('Let\'s go');
  const [popupMessagesButtonHandler, setPopupMessagesButtonHandler] = useState(() => handleStartPopupMessagesButtonClick);

  const [isFooterVisible, setIsFooterVisible] = useState(false);
  const [isTransactionsVisible, setIsTransactionsVisible] = useState(false);
  const [isIncomeStatementVisible, setIsIncomeStatementVisible] = useState(false);
  const [shouldIncomeStatementSlideIn, setShouldIncomeStatementSlideIn] = useState(true);
  const [selectedAnswerOption, setSelectedAnswerOption] = useState(null);
  const [answerStatus, setAnswerStatus] = useState(null);
  const [isExplanationBottomVisible, setIsExplanationBottomVisible] = useState(false);
  const [avatarName, setAvatarName] = useState('Bailey');
  const [explanationBottomContent, setExplanationBottomContent] = useState('Here’s the first part of the transaction. Review and decide if it impacts profits.');
  const [correctlyAnsweredQuestions, setCorrectlyAnsweredQuestions] = useState([]);
  const [isNextButtonVisible, setIsNextButtonVisible] = useState(false);
  const [isPrevButtonVisible, setIsPrevButtonVisible] = useState(false);

  const [revenuesTotal, setRevenuesTotal] = useState(0);
  const [expensesTotal, setExpensesTotal] = useState(0);

  const transactions = tutorialTransactions;
  const [activeTransaction, setActiveTransaction] = useState(0);

  const [totalScore, setTotalScore] = useState(0);

  useEffect(() => {
    if(isExplanationCenterVisible) {
      const timer = setTimeout(() => {
        setIsExplanationCenterVisible(false);
        setIsFooterVisible(true);
        setIsTransactionsVisible(true);
        setIsIncomeStatementVisible(true);
        setIsExplanationBottomVisible(true);
      }, 5000);

      return () => clearTimeout(timer);
    }
  }, [isExplanationCenterVisible]);

  const handleAnswerOptionButtonClick = (option) => {
    if(activeTransaction === 0) {
      setShouldIncomeStatementSlideIn(false);
    }

    setSelectedAnswerOption(option);
    setAnswerStatus(null);
    setIsExplanationBottomVisible(false);
    
    const transaction = transactions[activeTransaction];
    const questionId = transaction.id;
    
    if(tutorialAnswers.find(tutorialAnswer => tutorialAnswer.id === questionId).answer === option) {
      setExplanationBottomContent(transaction.explanation + ' Click "Next" to continue.');
      setIsNextButtonVisible(true);
      
      if(activeTransaction >= 1) {
        setIsPrevButtonVisible(true);
      }

      setAnswerStatus('correct');
      setCorrectlyAnsweredQuestions([...correctlyAnsweredQuestions, {id: questionId, answer: option}]);
      setTotalScore(prevTotalScore => prevTotalScore + transaction.value);

      if(option === 'Increase Profits') {
        setRevenuesTotal(revenuesTotal + transaction.value);
      } else if(option === 'Decrease Profits') {
        setExpensesTotal(expensesTotal - transaction.value);
      }
    } else {
      setAnswerStatus('incorrect');
      setExplanationBottomContent(transaction.explanation + ' Try again.');
    }
    setIsExplanationBottomVisible(true);
  };

  const handleNextButtonClick = () => {
    if(activeTransaction === 0) {
      setAvatarName('Benny');
      setExplanationBottomContent('Here’s the second part of the transaction. Review and decide if it impacts profits.');
    }

    if(!correctlyAnsweredQuestions.some(correctlyAnsweredQuestion => correctlyAnsweredQuestion.id === transactions[activeTransaction + 1]?.id)) {
      setIsNextButtonVisible(false);
      setIsPrevButtonVisible(false);
    }

    if((activeTransaction + 1) < transactions.length) {
      setActiveTransaction(prevActiveTransaction => prevActiveTransaction + 1);
    } else {
      setIsTransactionsVisible(false);
      setIsExplanationBottomVisible(false);
      setPopupMessages(popupMessagesTutorialEnd);
      setIsPopupMessagesVisible(true);
      setPopupMessagesButtonText('Next');
      setPopupMessagesButtonHandler(() => handleEndPopupMessagesButtonClick);
    }

    setSelectedAnswerOption(null);
    setAnswerStatus(null);
  };

  const handlePrevButtonClick = () => {
    if(activeTransaction <= 1) {
      setIsPrevButtonVisible(false);
    }
    setIsNextButtonVisible(true);
    setActiveTransaction(prevActiveTransaction => prevActiveTransaction - 1);
    setIsExplanationBottomVisible(false);
    setSelectedAnswerOption(null);
    setAnswerStatus(null);
  };

  return(
    <Layout
      backgroundImage={BackgroundImage}
      isBackgroundBlurred={true}
      isHeaderVisible={true}
      isFooterVisible={isFooterVisible}
      time={0}
      totalScore={totalScore}
      isExplanationVisible={isExplanationBottomVisible}
      isAvatarVisible={true}
      explanationName={avatarName}
      answerStatus={answerStatus}
      explanationContent={explanationBottomContent}
    >
      <BackgroundSound 
        sound={TutorialProcessSound}
      />

      <img src={BackgroundStars1} alt='Stars' className={`floating-stars floating-stars-1 ${isBackgroundStarsVisible ? 'visible' : ''}`}/>
      <img src={BackgroundStars2} alt='Stars' className={`floating-stars floating-stars-2 ${isBackgroundStarsVisible ? 'visible' : ''}`}/>

      {
        isPopupMessagesVisible && 
          <PopupMessages
            messages={popupMessages}
            buttonText={popupMessagesButtonText}
            onButtonClick={popupMessagesButtonHandler}
          />
      }

      {
        isExplanationCenterVisible &&
          <Explanation 
            isAvatarVisible={true}
            name={avatarName}
            content="Before we get started, let’s walk through a business transaction to better prepare you for this task."
          />
      }

      {
        isTransactionsVisible &&
          <TransactionsDecisions 
            key={activeTransaction}
            transactions={transactions}
            activeTransaction={activeTransaction}
            answerOptions={transactionsAnswerOptions}
            selectedAnswerOption={selectedAnswerOption}
            answerStatus={answerStatus}
            correctlyAnsweredQuestions={correctlyAnsweredQuestions}
            onAnswerOptionClick={handleAnswerOptionButtonClick}
            isNextButtonVisible={isNextButtonVisible}
            isPrevButtonVisible={isPrevButtonVisible}
            onNextButtonClick={handleNextButtonClick}
            onPrevButtonClick={handlePrevButtonClick}
          />
      }

      {
        isIncomeStatementVisible &&
          <IncomeStatement 
            title={`${username} Co. Income Statement January 2024`}
            revenues={revenuesTotal}
            expenses={expensesTotal}
            shouldSlideIn={shouldIncomeStatementSlideIn}
          />
      }
    </Layout>
  );
}
