import Explanation from '../explanation';
import Header from './../../game/header';

import './styles.css';

export default function Layout({
  backgroundImage,
  backgroundColor,
  isBackgroundBlurred,
  isHeaderVisible,
  isFooterVisible,
  isContentVertical,
  time,
  totalScore,
  isExplanationVisible,
  isAvatarVisible,
  explanationName,
  answerStatus,
  explanationContent,
  children,
  scoreLabel = "Mastery Index"
}) {
  const backgroundStyle = {
    ...(backgroundImage ? { backgroundImage: `url(${backgroundImage})` } : {}),
    ...(backgroundColor ? { backgroundColor } : {}),
  };

  return (
    <div className={`game-level-wrapper intro-game-level-wrapper ${isBackgroundBlurred ? 'blurred' : ''}`} style={backgroundStyle}>
      {
        isHeaderVisible &&
          <Header 
            time={time}
            totalScore={totalScore}
            scoreLabel={scoreLabel}
          />
      }
      <div className={`page-content intro-game-level-page-content ${isContentVertical ? 'vertical' : ''}`} >
        {children}
      </div>
      {
        isFooterVisible &&
          <footer className="intro-game-level-page-footer">
            {
              isExplanationVisible &&
                <Explanation 
                  isAvatarVisible={isAvatarVisible}
                  name={explanationName}
                  status={answerStatus}
                  content={explanationContent}
                />
            }
          </footer>
      }
    </div>
  );
}
