import { useState } from 'react';
import { useDrag } from 'react-dnd';

import './styles.css';
import Hint from '../../hint';

export default function OptionItem({
  id,
  accountItemId,
  name,
  description,
  type,
  onItemDropped,
  isIncorrect,
  isItemDisabled = false
}) {
  const [{ isDragging }, drag] = useDrag({
    type: 'recordOption',
    item: { id, accountItemId, name, description, type, onItemDropped },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
    end: (dropResult) => {
      if(onItemDropped) {
        onItemDropped(dropResult);
      }
    }
  });

  const [isHintShown, setIsHintShown] = useState(false);

  const handleMouseEnter = () => {
    setIsHintShown(true);
  }

  const handleMouseLeave = () => {
    setIsHintShown(false);
  }

  const handleMouseDown = () => {
    setIsHintShown(false);
  }

  let optionContentClass;

  switch (name) {
    case 'Debit':
      optionContentClass = 'option-item-debit';
      break;
    case 'Credit':
      optionContentClass = 'option-item-credit';
      break;
    default:
      optionContentClass = '';
      break;
  }

  return (
    <div className={`transaction-records-option-item-wrapper transaction-records-option-${type}-wrapper`}>
      <div 
        ref={(type !== 'value' && !isItemDisabled) ? drag : null}
        className={`transaction-records-option-item ${optionContentClass} ${isIncorrect ? 'incorrect' : ''}`}
        style={{
          opacity: isDragging ? 0 : 1
        }}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        onMouseDown={handleMouseDown}
      >
        <span>{name}</span>
      </div>
      {
        (isHintShown && !isItemDisabled) &&
          <Hint 
            content={description}
            side="left"
          />
      }
    </div>
  );
}
