import { useState, useEffect } from 'react';

export default function BarItem({ 
  title,
  score,
  maxScore,
  formattedScore,
  color,
  label
}) {
  const [animatedWidth, setAnimatedWidth] = useState('0%');
  const [animatedScore, setAnimatedScore] = useState(0);
  const animationSpeed = 25; // pixels per second

  useEffect(() => {
    const targetWidthPercentage = (score / (maxScore || 1) * 100);
    const duration = (targetWidthPercentage / animationSpeed) * 1000;

    let elapsed = 0;

    const interval = setInterval(() => {
      elapsed += 10;
      if (elapsed >= duration) {
        clearInterval(interval);
        setAnimatedWidth(`${targetWidthPercentage.toFixed(2)}%`);
        setAnimatedScore(formattedScore? Math.round(formattedScore) : 0);
      } else {
        const progress = elapsed / duration;
        setAnimatedWidth(`${(targetWidthPercentage * progress).toFixed(2)}%`);
        setAnimatedScore(Math.round(score * progress));
      }
    }, 10);

    return () => clearInterval(interval);
  }, [score, maxScore, formattedScore]);

  const scoreStyle = {
    width: animatedWidth,
    ...(color && { backgroundColor: color }) // Add backgroundColor property if color prop exists
  };

  return (
    <li>
      <span className="analysis-title">{title}</span>
      <span className="analysis-score-wrapper">
        <span
          className="analysis-score"
          style={scoreStyle}
        >
          <span>{animatedScore}{label}</span>
        </span>
      </span>
    </li>
  );
};
