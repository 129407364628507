import { useState, useEffect, useRef } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

import { useSoundStatus } from '../../context/SoundStatusContext';

import Header from '../UI/game/header';
import Modal from '../UI/modal';
import Column from '../UI/game/column';
import FooterMessage from '../UI/game/footer-message';

import { shuffleArray } from '../../utils';

import './styles.css';

import ClickSound from './sounds/click.mp3';
import AnswerCorrectSound from './sounds/answer-correct.mp3';
import AnswerWrongSound from './sounds/answer-wrong.mp3';

import { sortingTurorialOptions, wrongTutorialQuestionsToBePlaced } from './data';

const getDeepestLevelItems = (options) => {
  const deepestItems = [];

  function traverse(node) {
    if (!node.children || node.children.length === 0) {
      deepestItems.push({id: node.id, itemId: node.itemId});
    } else {
      node.children.forEach(child => traverse(child));
    }
  }

  options.forEach(item => traverse(item));

  return deepestItems;
}

const findDeepestParentId = (itemParents, options, currentDepth = 0, maxDepthInfo = { depth: 0, id: null }) => {
  options.forEach(option => {
    if (itemParents.includes(option.itemId)) {
      if (currentDepth >= maxDepthInfo.depth) { // Use >= to include top-level elements
        maxDepthInfo.depth = currentDepth;
        maxDepthInfo.id = option.id;
      }
    }
    if (option.children && option.children.length > 0) {
      findDeepestParentId(itemParents, option.children, currentDepth + 1, maxDepthInfo);
    }
  });
  return maxDepthInfo.id;
};


export default function ClassificationSortingTutorial({levelNumber}) {
  const clickEffect = useRef(new Audio(ClickSound));
  const answerCorrectEffect = useRef(new Audio(AnswerCorrectSound));
  const answerWrongEffect = useRef(new Audio(AnswerWrongSound));

  const navigate = useNavigate();
  
  const { soundStatus } = useSoundStatus();

  const [scenarioLevel, setScenarioLevel] = useState({
    description: '',
    fallingSpeed: 1,
    enabled: false
  });

  const [isPlaying, setIsPlaying] = useState(false);
  const [options, setOptions] = useState(sortingTurorialOptions);
  const [placedItems, setPlacedItems] = useState({});

  const [isHintOverlayShown, setIsHintOverlayShown] = useState(true);

  const handleHintNextButtonClick = () => {
    setIsHintOverlayShown(false);
  }

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsPlaying(true);
    }, 300);
  
    let placedItemsByDropArea = {};
    const numWrongQuestions = wrongTutorialQuestionsToBePlaced.length;
    const numWrongQuestionsInCorrectArea = Math.ceil(0.2 * numWrongQuestions);
    const numWrongQuestionsInRandomAreas = numWrongQuestions - numWrongQuestionsInCorrectArea;
    
    let prePlacedItemsRandomIterator = 0;

    const deepestLevelItems = getDeepestLevelItems(options);

    const shuffledWrongQuestionsToBePlaced = shuffleArray(wrongTutorialQuestionsToBePlaced);

    shuffledWrongQuestionsToBePlaced.forEach(item => {
      if(item.name === 'Salaries & Wages Payable') {
        item.onHintButtonClick = handleHintNextButtonClick;
      }
      let dropAreaId = 0;
      if(prePlacedItemsRandomIterator <= numWrongQuestionsInRandomAreas) {
        const filteredDeepestLevels = deepestLevelItems.filter(deepestLevelItem => !item.parents.includes(deepestLevelItem.itemId));
        const randomIndex = Math.floor(Math.random() * filteredDeepestLevels.length);
        dropAreaId = filteredDeepestLevels[randomIndex]?.id;

        prePlacedItemsRandomIterator++;
      } else {
        dropAreaId = findDeepestParentId(item.parents, options);
      }
          
      if (dropAreaId) {
        placedItemsByDropArea[dropAreaId] = placedItemsByDropArea[dropAreaId] || [];
        placedItemsByDropArea[dropAreaId].push({ ...item });
      }
    });
    
    setPlacedItems(placedItemsByDropArea);

    return () => clearTimeout(timer);
  }, []);

  const [totalScore, setTotalScore] = useState(0);
  const [shouldScorePlaySound, setShouldScorePlaySound] = useState(false);

  // const [itemsToBeSorted, setItemsToBeSorted] = useState([]);

  const [isFooterMessageShown, setIsFooterMessageShown] = useState(false);
  const [footerMessageType, setFooterMessageType] = useState();
  const [footerMessageDescription, setFooterMessageDescription] = useState();

  const showFooterMessage = (type, description) => {
    setFooterMessageType(type);
    setFooterMessageDescription(description);
    setIsFooterMessageShown(true);
  }

  const [isModalVisible, setIsModalVisible] = useState(false);

  const [time, setTime] = useState(0);
  const timeRef = useRef(time);
  useEffect(() => {
    timeRef.current = time;
  }, [time]);

  useEffect(() => {
    if (!isPlaying) return;
    const timerInterval = setInterval(() => {
      const newTime = timeRef.current + 1;
      setTime(newTime);
    }, 1000);

    return () => clearInterval(timerInterval);
  }, [isPlaying, scenarioLevel]);
  
  useEffect(() => {
    const playClickSound = () => {
      if (soundStatus === 'enabled') {
        clickEffect.current.play().catch(e => console.error('Error playing click sound:', e));
      }
    };

    document.addEventListener('click', playClickSound);

    return () => {
      document.removeEventListener('click', playClickSound);
    };
  }, [soundStatus]);

  const handleSortedItemClick = (item, container, callback) => {
    const isCorrect = item.parents.includes(container.itemId);
    const result = {is_correct: isCorrect};
    const footerMessagePrefix = '';

    callback(null, result);

    const footerMessageMiddle = isCorrect
      ? ' belongs in '
      : ' does NOT belong in ';

    const footerMessageSuffix = '.';

    const footerMessage = <p>{footerMessagePrefix}<b>{item.name}</b>{footerMessageMiddle}<b>{container.name}</b>{footerMessageSuffix}</p>;

    if(isCorrect) {
      answerCorrectEffect.current.play().catch(e => console.log('Error playing answer correct sound:', e));

      setShouldScorePlaySound(true);
      showFooterMessage('success', footerMessage);
      setIsModalVisible(true);
      setIsPlaying(false);
    } else {
      answerWrongEffect.current.play().catch(e => console.log('Error playing answer wrong sound:', e));

      showFooterMessage('error', footerMessage);
    }
  };

  const handleReadyButtonClick = () => {
    navigate('/classification/level/4');
  }

  return (
    <div className="game-level-wrapper">
      {
        isHintOverlayShown &&
          <div className='background-overlay'/>
      }
      <Header 
        time={time}
        totalScore={totalScore}
        shouldScorePlaySound={shouldScorePlaySound}
      />
      <section className="main-section main-section-sorting">
        <div className="container columns">
          <Column
            side='left'
            options={options[0]}
            levelNumber={levelNumber}
            prePlacedItems={placedItems}
            gameType="Account Classification"
            isGameSorted={true}
            onSortedItemClick={handleSortedItemClick}
          />
          <main className="content"></main>
          <Column
            side='right'
            options={options[1]}
            levelNumber={levelNumber}
            prePlacedItems={placedItems}
            gameType="Account Classification"
            isGameSorted={true}
            onSortedItemClick={handleSortedItemClick}
          />
        </div>
      </section>
      <footer className="game-footer">
        {isFooterMessageShown && (
          <FooterMessage
            type={footerMessageType}
            description={footerMessageDescription}
          />
        )}
      </footer>
      {isModalVisible && (
        <Modal
          content={ 
            <>
              <div className="modal-content align-center">
                <p>Awesome! You now understand how to make decisions in this level.</p> 
                <p>Let’s start the real game.</p>
              </div>
              <div className="next-button" onClick={handleReadyButtonClick}>I’m Ready!</div>
            </>
          }
        />
      )}
    </div>
  );
}
