import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';

import Layout from '../UI/introGameLayout/layout';

import {
  gamesQuery,
  getGamesSessions,
  scenarioLevelsQuery,
  getDecisions,
  getTransactionDecisions,
  getNormalDecisions,
  getEquationDecisions
} from './api';
import useAuth from '../../auth/useAuth';

import { getHighestActiveGame } from '../../utils';

import './styles.css';

import StudentImage from './images/transition-student.png';
import InstructorImage from './images/transition-instructor.jpg';
import NormalImage from './images/transition-normal.png';
import TheProfitImage from './images/transition-the-profit.png';
import BalancedEquationImage from './images/transition-balanced-equation.png';

export default function Transition() {
  const navigate = useNavigate();
  const { 
    getUser,
    isInstructor,
    isStudent,
    getCurrentClassificationGameId,
    getCurrentNormalGameId,
    getCurrentBalancedGameId,
    getTransitionGameType,
    getGuidedPlay
  } = useAuth();
  const user = getUser();

  const shouldGuidedPagesBeDisplayed = getGuidedPlay();
  
  const [transitionImage, setTransitionImage] = useState();
  const [imageClass, setImageClass] = useState('');
  const [pageTitle, setPageTitle] = useState('');
  const [pageSubtitle, setPageSubtitle] = useState('');
  const [textUnderTheImage, setTextUnderTheImage] = useState('');

  const gameType = getTransitionGameType();
  const [currentGameId, setCurrentGameId] = useState(0);
  const [scenarioId, setScenarioId] = useState(0);

  const { data: games } = useQuery(gamesQuery()) || {};
  useEffect(() => {
    if(games) {
      const selectedGameId = gameType.includes('Account Classification')
        ? getCurrentClassificationGameId()
        : gameType.includes('Normal Balance')
          ? getCurrentNormalGameId()
          : gameType.includes('Balanced Equation')
            ? getCurrentBalancedGameId()
            : 0;
      const game = selectedGameId
        ? games.find(game => game.id === selectedGameId)
        : getHighestActiveGame(games);

      if(game && game.scenario) {
        setCurrentGameId(game.id);
        setScenarioId(game.scenario);
      }
    }
  }, [games, gameType]);

  const { data: gamesSessions } = useQuery({
    queryKey: ['gamesSessions'],
    queryFn: getGamesSessions,
  });

  const scenarioLevelsResponse = useQuery(scenarioLevelsQuery(scenarioId)) || {};

  const { data: decisionsResponse } = useQuery({
    queryKey: ['decisionsResponse', { userId: user?.id, gameId: currentGameId }],
    queryFn: () => getDecisions({ userId: user?.id, gameId: currentGameId }),
    enabled: !!user?.id && !!currentGameId,
  });

  const { data: transactionDecisionsResponse } = useQuery({
    queryKey: ['transactionDecisionsResponse', { userId: user?.id, gameId: currentGameId }],
    queryFn: () => getTransactionDecisions({ userId: user?.id, gameId: currentGameId }),
    enabled: !!user?.id && !!currentGameId,
  });
  
  const { data: equationDecisionsResponse } = useQuery({
    queryKey: ['equationDecisionsResponse', { userId: user?.id, gameId: currentGameId }],
    queryFn: () => getEquationDecisions({ userId: user?.id, gameId: currentGameId }),
    enabled: !!user?.id && !!currentGameId,
  });
  
  const { data: normalDecisionsResponse } = useQuery({
    queryKey: ['normalDecisionsResponse', { userId: user?.id, gameId: currentGameId }],
    queryFn: () => getNormalDecisions({ userId: user?.id, gameId: currentGameId }),
    enabled: !!user?.id && !!currentGameId,
  });

  useEffect(() => {
    const userIsInstructor = isInstructor();
    const userIsStudent = isStudent();

    if (user && gameType) {
      if(gameType.includes('Account Classification')) {
        if (userIsInstructor) {
          setTransitionImage(InstructorImage);
          setImageClass('transition-image-instructor');
          setPageTitle('Welcome To The Account Classification Game!');
          setPageSubtitle(`Hi${user?.first_name ? ' ' + user?.first_name : ''}!`);
        } else if (userIsStudent) {
          setTransitionImage(StudentImage);
          setImageClass('transition-image-student');
          setPageTitle('Account Classification Game');
          // setPageSubtitle(`Hello${user?.alias_name ? ' ' + user?.alias_name : ''}! You are invited to play the`);
        }
      } else if (gameType.includes('Normal Balance')) {
        setTransitionImage(NormalImage);
        setImageClass('transition-image-student');
        setPageTitle('Welcome To The Normal Balance Game!');
        setPageSubtitle(`Hi${user?.alias_name ? ' ' + user?.alias_name : ''}!`);
      } else if(gameType.includes('The Profit')) {
        setTransitionImage(TheProfitImage);
        setPageTitle('The Profit Game');
      } else if(gameType.includes('Balanced Equation')) {
        setTransitionImage(BalancedEquationImage);
        setPageTitle('The Balanced Equation');
        setTextUnderTheImage('As in life, so too in accounting: balance is key');
      }
    }
  }, [
    navigate,
    getUser,
    isInstructor,
    isStudent,
    games,
    currentGameId,
    user,
    gameType
  ]);

  useEffect(() => {
    let redirectUrl = '';
    const scenarioLevels = scenarioLevelsResponse.data || [];
    const sortedScenarioLevels = scenarioLevels.filter(scenarioLevel => scenarioLevel.level !== null).sort((a, b) => a.level - b.level);

    if(gameType.includes('The Profit')) {
      redirectUrl = '/the-profit/tutorial';
      if (gamesSessions && gamesSessions.some(session => session.user === user?.id && session.is_ready === true && session.game === currentGameId)) { 
        const transactionDecisions = transactionDecisionsResponse || [];
        
        const hasNotCompletedLevels = sortedScenarioLevels.some((scenarioLevel) => {
          const unansweredTransatctions = scenarioLevel.transactions.find((transaction) => {
            const correctDecision = transactionDecisions.find((transactionDecision) => transactionDecision.question === transaction.id && transactionDecision.is_correct);
            return !correctDecision;
          });
          
          if (unansweredTransatctions) {
            redirectUrl = `/the-profit/level/${scenarioLevel.level}`;
            return true;
          }
          
          return false;
        });
  
        if (!hasNotCompletedLevels) {
          redirectUrl = '/the-profit/debrief';
        }
      } else {
        redirectUrl = '/the-profit/tutorial'
      }
    } else if (gameType.includes('Account Classification')) {
      if(gamesSessions && gamesSessions.some(session => session.user === user?.id && session.is_ready === true && session.game === currentGameId)) {
        const currentGameSessions = gamesSessions.filter(session => session.game === currentGameId && session.user === user?.id);
        const allSessionsCompleted = currentGameSessions.length > 0 && currentGameSessions.every(session => session.completed);

        if (allSessionsCompleted) {
          if (currentGameSessions.length < 3) {
            redirectUrl = '/classification/level/1/tutorial';
          } else {
            redirectUrl = `/classification/level/${sortedScenarioLevels.length}`;
          }
        } else {
          const incompleteSession = currentGameSessions.find(session => !session.completed);

          if (incompleteSession?.is_ready) {
            const decisions = decisionsResponse || [];
            const gameSessionDecisions = decisions.filter(decision => decision.game_session === incompleteSession.id);
            const hasNotCompletedLevels = sortedScenarioLevels.some((scenarioLevel) => {
              const unansweredQuestion = scenarioLevel.questions.find((question) => {
                const correctDecision = gameSessionDecisions.find((decision) => decision.question === question.id && decision.is_correct);
                return !correctDecision;
              });

              if (unansweredQuestion) {
                const levelDecisionsMade = gameSessionDecisions.some(decision => decision.level === scenarioLevel.level);

                if (levelDecisionsMade) {
                  redirectUrl = `/classification/level/${scenarioLevel.level}`;
                } else {
                  if (scenarioLevel.level === 4) {
                    redirectUrl = `/classification/level/4/tutorial`;
                  } else {
                    redirectUrl = `/classification/level/${scenarioLevel.level}`;
                  }
                }
                return true;
              }

              return false;
            });

            if (!hasNotCompletedLevels) {
              redirectUrl = '/classification/feedback';
            }
          } else {
            redirectUrl = '/classification/level/1/tutorial';
          }
        }
      } else if (
        !gamesSessions?.some(
          session =>
            games?.find(game => game.id === session.game && game.game_type === 'Account Classification')
        )
      ) {
        redirectUrl = `/classification/intro`;
      } else {
        redirectUrl = `/classification/level/1/tutorial`;
      }
    } else if (gameType.includes('Normal Balance')) {
      if(gamesSessions && gamesSessions.some(session => session.user === user?.id && session.is_ready === true && session.game === currentGameId)) { 
        const currentGameSessions = gamesSessions.filter(session => session.game === currentGameId && session.user === user?.id);
        const allSessionsCompleted = currentGameSessions.length > 0 && currentGameSessions.every(session => session.completed);
        
        if (allSessionsCompleted) {
          if (currentGameSessions.length < 3) {
            redirectUrl = '/normal/level/1/tutorial';
          } else {
            redirectUrl = `/normal/level/${sortedScenarioLevels.length}`;
          }
        } else {
          const incompleteSession = currentGameSessions.find(session => !session.completed);

          if (incompleteSession?.is_ready) {
            const decisions = normalDecisionsResponse || [];
            const gameSessionDecisions = decisions.filter(decision => decision.game_session === incompleteSession.id);
            const hasNotCompletedLevels = sortedScenarioLevels.some((scenarioLevel) => {
              const unansweredQuestion = scenarioLevel.normal_questions.find((question) => {
                const correctDecision = gameSessionDecisions.find((decision) => decision.question === question.id && decision.is_correct);
                return !correctDecision;
              });
  
              if (unansweredQuestion) {
                const levelDecisionsMade = gameSessionDecisions.some(decision => decision.level === scenarioLevel.level);
                if(levelDecisionsMade) {
                  redirectUrl = `/normal/level/${scenarioLevel.level}`;
                } else {
                  if(shouldGuidedPagesBeDisplayed) {
                    redirectUrl = `/normal/level/${scenarioLevel.level}/intro`;
                  } else {
                    if(scenarioLevel.level === 5) {
                      redirectUrl = `/normal/level/5/tutorial`;
                    } else {
                      redirectUrl = `/normal/level/${scenarioLevel.level}`;
                    }
                  }
                }
                return true;
              }
              
              return false;
            });
      
            if (!hasNotCompletedLevels) {
              redirectUrl = `/normal/level/${sortedScenarioLevels.length}/accuracy`;
            }
          } else {
            redirectUrl = '/normal/level/1/tutorial';
          }
        }
      } else {
        if(shouldGuidedPagesBeDisplayed) {
          redirectUrl = '/normal/level/1/intro';
        } else {
          redirectUrl = '/normal/level/1/tutorial';
        }
      }
    } else if (gameType.includes('Balanced Equation')) {
      if(gamesSessions && gamesSessions.some(session => session.user === user?.id && session.is_ready === true && session.game === currentGameId)) {
        const currentGameSessions = gamesSessions.filter(session => session.game === currentGameId && session.user === user?.id);
        const allSessionsCompleted = currentGameSessions.length > 0 && currentGameSessions.every(session => session.completed);

        if (allSessionsCompleted) {
          if (currentGameSessions.length < 3) {
            redirectUrl = '/balanced-equation/level/1/tutorial';
          } else {
            redirectUrl = `/balanced-equation/level/${sortedScenarioLevels.length}`;
          }
        } else {
          const incompleteSession = currentGameSessions.find(session => !session.completed);

          if (incompleteSession?.is_ready) {
            const decisions = equationDecisionsResponse || [];
            const gameSessionDecisions = decisions.filter(decision => decision.game_session === incompleteSession.id);
            
            if(gameSessionDecisions.length) {
              const hasNotCompletedLevels = sortedScenarioLevels.some((scenarioLevel) => {
                const unansweredQuestion = scenarioLevel.be_questions.find((question) => {
                  const correctDecision = gameSessionDecisions.find((decision) => decision.question === question.id && decision.question_answered_correctly);
                  return !correctDecision;
                });
                
                if (unansweredQuestion) {
                  const levelDecisionsMade = gameSessionDecisions.some(decision => decision.level === scenarioLevel.level);
                  if(levelDecisionsMade) {
                    redirectUrl = `/balanced-equation/level/${scenarioLevel.level}`;
                  } else {
                    if(scenarioLevel.level < 4) {
                      redirectUrl = `/balanced-equation/level/${scenarioLevel.level}/tutorial`;
                    } else {
                      redirectUrl = `/balanced-equation/level/${scenarioLevel.level}`;
                    }
                  }
                  return true;
                }
                
                return false;
              });
        
              if (!hasNotCompletedLevels) {
                redirectUrl = `/balanced-equation/level/${sortedScenarioLevels.length}/`;
              }
            } else {
              redirectUrl = '/balanced-equation/level/1/';
            }
          } else {
            redirectUrl = '/balanced-equation/level/1/tutorial';
          }
        }
      } else if (
        !gamesSessions?.some(
          session =>
            games?.find(game => game.id === session.game && game.game_type === 'Balanced Equation')
        )
      ) {
        redirectUrl = `/balanced-equation/level/1/intro`;
      } else {
        redirectUrl = '/balanced-equation/level/1/tutorial';
      }
    }

    const timer = setTimeout(() => {
      navigate(redirectUrl);
    }, 4500);

    return () => clearTimeout(timer);
  }, [
    gameType,
    scenarioLevelsResponse.data,
    currentGameId,
    gamesSessions,
    decisionsResponse,
    transactionDecisionsResponse,
    normalDecisionsResponse,
    equationDecisionsResponse,
    user?.id,
    navigate
  ])

  return (
    <Layout isHeaderVisible={true}>
      <div className="page-content transition-page-content">
        {
          pageSubtitle &&
            <h2 className="page-subtitle">{pageSubtitle}</h2>
        }
        <h1 className="page-title">{pageTitle}</h1>
        <div className="loader"/>
        {
          transitionImage &&
            <img src={transitionImage} alt="Welcome" className={`transition-image ${imageClass}`} />
        }
        {
          textUnderTheImage &&
            <p className="transition-under-the-image">{textUnderTheImage}</p>
        }
      </div>
    </Layout>
  );
}
