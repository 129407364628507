import React, { useEffect, useRef, useState, useMemo } from 'react';

import IntroClosingSound from './sounds/intro-closing.mp3';
import IntroOpeningSound from './sounds/intro-opening.mp3';

import { useSoundStatus } from '../../../../context/SoundStatusContext';

import './styles.css';

export default function Intro({
  title,
  description,
  sidesToClose = false,
  onShowingCompleted
}) {
  const { soundStatus } = useSoundStatus();

  const introClosingEffect = useRef(new Audio(IntroClosingSound));
  const introOpeningEffect = useRef(new Audio(IntroOpeningSound));
  
  const [isShowingCompleted, setIsShowingCompleted] = useState(false);

  useEffect(() => {
    if(sidesToClose && soundStatus === 'enabled') {
      introClosingEffect.current.play().catch(e => console.log('Error playing intro closing sound:', e));
    }
  }, [sidesToClose, soundStatus]);

  const { subtitle, descriptionParts } = useMemo(() => {
    const parts = description ? description.split(':').map(part => part.trim()) : [];
    const extractedSubtitle = parts.shift();
    return { subtitle: extractedSubtitle, descriptionParts: parts };
  }, [description]);

  const concatenatedDescription = useMemo(() => {
    return descriptionParts.join("<br><br>");
  }, [descriptionParts]);

  const formatSubtitleWithBullets = (text) => {
    const parts = text.split('/').filter(part => part.trim() !== '');
    if (parts.length <= 1) return text;

    return parts.map(part => `<br/><span class="bullet">•</span> ${part.trim()}`).join('');
  };

  const formattedSubtitle = useMemo(() => {
    return subtitle ? formatSubtitleWithBullets(subtitle) : '';
  }, [subtitle]);

  useEffect(() => {
    const showingCompletedTimer = setTimeout(() => {
      setIsShowingCompleted(true);
      if (soundStatus === 'enabled') {
        introOpeningEffect.current.play().catch(e => console.log('Error playing intro opening sound:', e));
      }

      const onShowingCompletedTimer = setTimeout(() => {
        if (onShowingCompleted) {
          onShowingCompleted();
        }
      }, 900);

      return () => clearTimeout(onShowingCompletedTimer);
    }, 5000);

    return () => clearTimeout(showingCompletedTimer);
  }, [soundStatus]);

  const leftSideClass = sidesToClose 
    ? "intro-side-left-join"
    : isShowingCompleted 
      ? "intro-side-left-slide-out"
      : "";
  
  const rightSideClass = sidesToClose 
    ? "intro-side-right-join"
    : isShowingCompleted 
      ? "intro-side-right-slide-out"
      : "";

  return (
    <div className="intro-wrapper">
      <div className={`intro-side intro-side-left ${leftSideClass}`}>
        <div className="intro-content-container">
          <div className="intro-content">
            <h1>{title}</h1>
            <h2 dangerouslySetInnerHTML={{ __html: formattedSubtitle }}></h2>
          </div>
        </div>
      </div>
      <div className={`intro-side intro-side-right ${rightSideClass}`}>
        <div className="intro-content-container">
          <div className="intro-content">
            <span dangerouslySetInnerHTML={{ __html: concatenatedDescription }}></span>
          </div>
        </div>
      </div>
    </div>
  );
}
