
import { useState, useEffect, useMemo } from 'react';
import { useQuery, useMutation } from '@tanstack/react-query';
import { useParams, useNavigate } from 'react-router-dom';

import SubPagesLayout from '../../../UI/layout/subPages';
import InstructorAssignmentForm from '../../../UI/instructor/settings/assignments/form';

import { gameTypeMappings } from '../../../../vars';
import { formatScnearioLevelToFilterName } from '../../../../utils';

import { 
  navigation,
  gameCreateItemsQuestionsColumns,
  gameCreateItemsOptionsNormal,
  scenarioLevelsDescription
} from './data';

import {
  sessionsQuery,
  coursesQuery,
  gamesQuery,
  scenarioLevelsQuery,
  gamesItemsQuery,
  scenariosQuery,
  editScenario,
  editGame,
  createScenarioLevels,
  createItemQuestions,
  deleteItemQuestions,
  createItemOptions,
  deleteScenarioLevel
} from './api';

import InstructorAssignmentSetLevels from '../../../UI/instructor/settings/assignments/setLevels';

export default function InstructorSettingsEditAssignments() {
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);

  const sessionsResponse = useQuery(sessionsQuery()) || {};
  const sessions = sessionsResponse.data;

  const coursesResponse = useQuery(coursesQuery()) || {};
  const courses = coursesResponse.data;

  const gamesResponse = useQuery(gamesQuery()) || {};
  const games = gamesResponse.data;

  const params = useParams();
  const gameId = Number(params.gameId);
  const [formData, setFormData] = useState({});
  const [scenarioId, setScenarioId] = useState(0);

  const [corporateScenarioId, setCorporateScenarioId] = useState(null);
  const [nonCorporateScenarioId, setNonCorporateScenarioId] = useState(null);

  const [entityTypeScenarioId, setEntityTypeScenarioId] = useState(null);
  const [specialAccountsScenarioId, setSpecialAccountsScenarioId] = useState(null);

  const [filterItems, setFilterItems] = useState({});

  const [editingScenarioLevelIndex, setEditingScenarioLevelIndex] = useState(null);
  const [editingScenarioLevelPrecheckedItems, setEditingScenarioLevelPrecheckedItems] = useState([]);

  const [isGameStarted, setIsGameStarted] = useState(false);

  useEffect(() => {
    if (sessions && gameId) {
      const isCurrentGameSession = sessions.some(session => session.game === gameId);
      setIsGameStarted(isCurrentGameSession);
    }
  }, [sessions, gameId]);

  useEffect(() => {
    if (games && gameId) {
      const currentGame = games.find((game) => game.id === gameId);
      if (currentGame) {
        setFormData({
          name: currentGame.name || '',
          description: currentGame.description || '',
          startTime: currentGame.start_time || '',
          endTime: currentGame.end_time || '',
          gameType: currentGame.game_type
        });

        setScenarioId(currentGame.scenario);
      }
    }
  }, [games, gameId]);
  
  const { data: scenarioLevels, refetch: refetchScenarioLevels } = useQuery(scenarioLevelsQuery({scenarioId}));

  const { data: corporateScenarioLevels } = useQuery(scenarioLevelsQuery({ scenarioId: corporateScenarioId }));
  const { data: nonCorporateScenarioLevels } = useQuery(scenarioLevelsQuery({ scenarioId: nonCorporateScenarioId }));

  const { data: entityTypeScenarioLevels } = useQuery(scenarioLevelsQuery({ scenarioId: entityTypeScenarioId }));
  const { data: specialAccountsScenarioLevels } = useQuery(scenarioLevelsQuery({ scenarioId: specialAccountsScenarioId }));

  const { data: scenarios, refetch: refetchScenariosQuery } = useQuery(scenariosQuery());
  
  const gamesItemsResponse = useQuery(gamesItemsQuery()) || {};
  const gamesItems = gamesItemsResponse.data;
  
  const editScenarioMutation = useMutation({
    mutationFn: (data) => editScenario(data)
  });
  
  const editGameMutation = useMutation({
    mutationFn: (data) => editGame(data)
  });

  const createScenarioLevelsMutation = useMutation({
    mutationFn: (data) => createScenarioLevels(data)
  });
  
  const createItemQuestionsMutation = useMutation({
    mutationFn: (data) => createItemQuestions(data)
  });
  
  const deleteItemQuestionsMutation = useMutation({
    mutationFn: (data) => deleteItemQuestions(data)
  });
  
  const createItemOptionsMutation = useMutation({
    mutationFn: (data) => createItemOptions(data)
  });

  const deleteScenarioLevelMutation = useMutation({
    mutationFn: (data) => deleteScenarioLevel(data)
  });

  const handleEditGameSettings = async ({
    name,
    description,
    startTime,
    endTime,
    formSavedCallback
  }) => {
    setIsLoading(true);

    if(formData.name !== name) {
      await editScenarioMutation.mutateAsync({
        id: scenarioId,
        name
      }).catch((error) => {
        console.error('Caught error with mutateAsync:', error);
      });
    }

    await editGameMutation.mutateAsync({
      id: gameId,
      name,
      description,
      startTime,
      endTime
    }).catch((error) => {
      console.error('Caught error with mutateAsync:', error);
    });

    if(formSavedCallback) {
      formSavedCallback();
    }
    setIsLoading(false);
  }

  const handleShowLevelAccountsButtonClick = (scenarioLevelIndex) => {
    setEditingScenarioLevelIndex(scenarioLevelIndex);
    const savedAccountItems = gameTypeShortened?.includes('classification')
      ? scenarioLevels[scenarioLevelIndex]?.questions.map(({ id, account_item }) => ({id, text: account_item.name}))
      : gameTypeShortened?.includes('normal')
      ? scenarioLevels[scenarioLevelIndex]?.normal_questions.map(({ id, account_item }) => ({id, text: account_item.name}))
      : gameTypeShortened?.includes('equation')
      ? scenarioLevels[scenarioLevelIndex]?.be_questions.map(({ id, description }) => ({id, text: description}))
      : [];

    if(savedAccountItems) {
      setEditingScenarioLevelPrecheckedItems(savedAccountItems);
    } else {
      setEditingScenarioLevelPrecheckedItems([]);
    }
    refetchScenariosQuery();
  }

  const gameTypeShortened = useMemo(() => {
    return (
      Object.entries(gameTypeMappings).find(([key]) => formData.gameType?.includes(key))?.[1] || ''
    );
  }, [formData.gameType]);

  useEffect(() => {
    if(scenarios) {
      if(gameTypeShortened === 'equation') {
        const corporateScenario = scenarios.find(item => item.name === 'be-corporate');
        const nonCorporateScenario = scenarios.find(item => item.name === 'be-noncorporate');
    
        setCorporateScenarioId(corporateScenario?.id || null);
        setNonCorporateScenarioId(nonCorporateScenario?.id || null);
      } else if (gameTypeShortened === 'classification' || gameTypeShortened === 'normal') {
        const entityTypeScenario = scenarios.find(item => item.name === 'entity-type');
        const specialAccountsScenario = scenarios.find(item => item.name === 'special-accounts');
    
        setEntityTypeScenarioId(entityTypeScenario?.id || null);
        setSpecialAccountsScenarioId(specialAccountsScenario?.id || null);
      }
    }
  }, [scenarios, gameTypeShortened]);
  
  useEffect(() => {
    if(corporateScenarioLevels?.length && nonCorporateScenarioLevels?.length) {
      const filterItems = {
        'Corporate': {
          ...corporateScenarioLevels.reduce((acc, { name, be_questions }) => {
            acc[formatScnearioLevelToFilterName(name)] = be_questions.map(({ transaction_id, order, description }) => ({
              id: transaction_id,
              order,
              text: description,
              column: 'Transactions'
            }));
            return acc;
          }, {})
        },
        'Non-Corporate': {
          ...nonCorporateScenarioLevels.reduce((acc, { name, be_questions }) => {
            acc[formatScnearioLevelToFilterName(name)] = be_questions.map(({ transaction_id, order, description }) => ({
              id: transaction_id,
              order,
              text: description,
              column: 'Transactions'
            }));
            return acc;
          }, {})
        },
      };

      setFilterItems(filterItems);
    }
  }, [corporateScenarioLevels, nonCorporateScenarioLevels]);
  
  useEffect(() => {
    if(entityTypeScenarioLevels?.length && specialAccountsScenarioLevels?.length) {
      const filterItems = {
        'Entity Type': {
          ...entityTypeScenarioLevels.reduce((acc, { name, questions }) => {
            acc[formatScnearioLevelToFilterName(name)] = questions.map(({ id, order, account_item }) => {
              const relatedItemQuestion = gamesItems.find((gamesItem) => gamesItem.name === account_item.name);
              const relatedItemQuestionColumn = gameCreateItemsQuestionsColumns.find(column =>
                relatedItemQuestion.parents.some(parentId =>
                  gamesItems.some(item => item.id === parentId && item.name === column)
                )
              );

              return ({
                id,
                order,
                text: account_item.name,
                column: relatedItemQuestionColumn,
                accountItemId: account_item.id
              })
            });
            return acc;
          }, {}),
        },
        'Special Accounts': {
          ...specialAccountsScenarioLevels.reduce((acc, { name, questions }) => {
            acc[formatScnearioLevelToFilterName(name)] = questions.map(({ id, order, account_item }) => {
              const relatedItemQuestion = gamesItems.find((gamesItem) => gamesItem.name === account_item.name);
              const relatedItemQuestionColumn = gameCreateItemsQuestionsColumns.find(column =>
                relatedItemQuestion.parents.some(parentId =>
                  gamesItems.some(item => item.id === parentId && item.name === column)
                )
              );

              return ({
                id,
                order,
                text: account_item.name,
                column: relatedItemQuestionColumn,
                accountItemId: account_item.id
              })
            });
            return acc;
          }, {}),
        },
      };
      
      setFilterItems(filterItems);
    }
  }, [entityTypeScenarioLevels, specialAccountsScenarioLevels]);

  const handleScenarioLevelAccontSelectSaveButtonClick = async (selectedAccountItems, selectedFilter, accountItemsSavedCallback) => {
    setIsLoading(true);
    let scenarioLevelId, scenarioLevelNumber;
    if(typeof editingScenarioLevelIndex !== 'number') {
      const descriptionIndex = gameTypeShortened === 'classification' || gameTypeShortened === 'normal'
        ? 1
        : scenarioLevels.length > 2
          ? 2
          : scenarioLevels.length;
      const resultCreateScenarioLevels = await createScenarioLevelsMutation.mutateAsync({ 
        name: `${courses[0]?.name}${scenarioLevels.length + 1}`,
        levelNumber: scenarioLevels.length + 1,
        scenarios: [scenarioId],
        description: scenarioLevelsDescription[formData.gameType][descriptionIndex]
      });
      scenarioLevelId = resultCreateScenarioLevels?.id;
      scenarioLevelNumber = resultCreateScenarioLevels.level;
    } else {
      scenarioLevelId = scenarioLevels[editingScenarioLevelIndex]?.id;
      scenarioLevelNumber = scenarioLevels[editingScenarioLevelIndex]?.level;
    }
    
    if(scenarioLevelId || typeof editingScenarioLevelIndex === 'number') {
      try {
        const remainingPreCheckedItems = [...editingScenarioLevelPrecheckedItems];
        
        for (const [index, gameCreateItemQuestion] of selectedAccountItems.entries()) {
          const matchedItemIndex = remainingPreCheckedItems.findIndex(
            (item) => item.text === gameCreateItemQuestion.text
          );
          
          if (matchedItemIndex === -1) {
            await createItemQuestionsMutation.mutateAsync({
              gameType: gameTypeShortened,
              order: gameCreateItemQuestion.order || index + 1,
              accountItemId: gameCreateItemQuestion.accountItemId || gameCreateItemQuestion.id,
              scenarioLevelId
            });
          } else {
            remainingPreCheckedItems.splice(matchedItemIndex, 1);
          }
        }

        if(remainingPreCheckedItems.length) {
          for (const remainingItem of remainingPreCheckedItems) {          
            await deleteItemQuestionsMutation.mutateAsync({
              questionId: remainingItem.id,
              gameType: gameTypeShortened
            });
          }              
        }
      } catch (error) {
        console.log('Some mutations failed:', error);
      } finally {
        if(typeof editingScenarioLevelIndex !== 'number') {
          if(gameTypeShortened === 'classification') {
            const gameScenarioLevels = [...entityTypeScenarioLevels, ...specialAccountsScenarioLevels];
            const filterScenarioLevel = gameScenarioLevels.find(level => 
              level.name.toLowerCase().replace(/\s+/g, '-') === selectedFilter.toLowerCase().replace(/\s+/g, '-')
            );

            const scenarioLevelOptions = filterScenarioLevel.options;

            let filterScenarioOptions = [];
            switch (scenarioLevelNumber) {
              case 1:
                filterScenarioOptions = scenarioLevelOptions.filter(option => option.order >= 1 && option.order <= 2);
                break;
              case 2:
                filterScenarioOptions = scenarioLevelOptions.filter(option => 
                  (option.order >= 3 && option.order <= 7)
                );
                break;
              default:
                filterScenarioOptions = scenarioLevelOptions.filter(option => 
                  (option.order >= 3 && option.order <= 7)
                );
                break;
            }
  
            try {
              for (const [index, gameCreateItemOption] of filterScenarioOptions.entries()) {
                await createItemOptionsMutation.mutateAsync({
                  gameType: gameTypeShortened,
                  order: gameCreateItemOption.order || index + 1,
                  accountItemId: gameCreateItemOption.account_item.id,
                  scenarioLevelId
                });
              }          
            } catch (error) {
              console.log('Some mutations failed:', error);
            }
          } else if (gameTypeShortened === 'normal') {
            const gameCreateItemsOptions = gameCreateItemsOptionsNormal;
            
            try {
              for (const [index, gameCreateItemOption] of gameCreateItemsOptions.entries()) {
                const matchedGamesItemOption = gamesItems.find((gamesItem) => gamesItem.name === gameCreateItemOption);
                if(matchedGamesItemOption) {
                  await createItemOptionsMutation.mutateAsync({
                    gameType: gameTypeShortened,
                    order: matchedGamesItemOption.order || index + 1,
                    accountItemId: matchedGamesItemOption.id,
                    scenarioLevelId
                  });
                }
              }          
            } catch (error) {
              console.log('Some mutations failed:', error);
            }
          } else if(gameTypeShortened === 'equation') {
            const balanceEquationScenarioLevels = [...corporateScenarioLevels, ...nonCorporateScenarioLevels];
            const filterScenarioLevel = balanceEquationScenarioLevels.find(level => 
              level.name.toLowerCase().replace(/\s+/g, '-') === selectedFilter.toLowerCase().replace(/\s+/g, '-')
            );
  
            let filterScenarioOptions = [];
            switch (scenarioLevelNumber) {
              case 1:
                filterScenarioOptions = filterScenarioLevel.be_options.filter(option => option.order >= 1 && option.order <= 5);
                break;
              case 2:
                filterScenarioOptions = filterScenarioLevel.be_options.filter(option => 
                  (option.order >= 1 && option.order <= 5) || option.account_item.account === true
                );
                break;
              case 3:
                filterScenarioOptions = filterScenarioLevel.be_options.filter(option => 
                  (option.order >= 1 && option.order <= 7) || option.account_item.account === true
                );
                break;
              default:
                filterScenarioOptions = filterScenarioLevel.be_options.filter(option => 
                  (option.order >= 1 && option.order <= 7) || option.account_item.account === true
                );
                break;
            }
  
            try {
              for (const [index, gameCreateItemOption] of filterScenarioOptions.entries()) {
                await createItemOptionsMutation.mutateAsync({
                  gameType: gameTypeShortened,
                  order: gameCreateItemOption.order || index + 1,
                  accountItemId: gameCreateItemOption.account_item.id,
                  scenarioLevelId: scenarioLevelId
                });
              }          
            } catch (error) {
              console.log('Some mutations failed:', error);
            }
          }
        }
      }
    }
    
    refetchScenarioLevels();
    accountItemsSavedCallback();
    setIsLoading(false);
  }

  const handleCloseButtonClick = () => {
    navigate('/instructor/settings/assignments');
  }

  const handleScenarioLevelDelete = async (scenarioLevelId, scenarioLevelDeletedCallback) => {
    deleteScenarioLevelMutation.mutate(
      { scenarioLevelId },
      {
        onSuccess: () => {
          scenarioLevelDeletedCallback();
          refetchScenarioLevels();
        },
      }
    );
  };

  return (
    <SubPagesLayout
      navigation={navigation}
      contentClassName="adjustment-content-container"
      showPagination={false}
      secondButtonName="Close"
      onSecondButtonClick={handleCloseButtonClick}
    >
      <InstructorAssignmentForm
        onSave={handleEditGameSettings}
        isButtonLoading={isLoading}
        initialValues={formData}
      />

      <InstructorAssignmentSetLevels 
        gameType={formData.gameType}
        scenarioLevels={scenarioLevels}
        gamesItems={gamesItems}
        onScenarioLevelAccountSelectSave={handleScenarioLevelAccontSelectSaveButtonClick}
        isButtonLoading={isLoading}
        onShowLevelAccounts={handleShowLevelAccountsButtonClick}
        filterItems={filterItems}
        editingScenarioLevelIndex={editingScenarioLevelIndex}
        editingScenarioLevelPreCheckedItems={editingScenarioLevelPrecheckedItems}
        onLevelDeletionConfirm={handleScenarioLevelDelete}
        isGameStarted={isGameStarted}
      />
    </SubPagesLayout>
  );
}
