import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useQuery, useMutation } from '@tanstack/react-query';

import Layout from '../UI/introGameLayout/layout';

import { gamesQuery, getGamesSessions } from './api';

import useAuth from '../../auth/useAuth';

import { updateUserAlias } from './api';

import './styles.css';

export default function Onboarding() {
  const navigate = useNavigate();
  const { getUser, saveUser } = useAuth();
  const user = getUser();
  
  const [currentGameId, setCurrentGameId] = useState(0);
  const [currentGameSlug, setCurrentGameSlug] = useState('');

  const { data: games } = useQuery(gamesQuery()) || {};

  useEffect(() => {
    if(games?.length === 1) {
      setCurrentGameId(games[0].id);

      let slug = '';
      const gameType = games[0].game_type;
      switch (true) {
        case gameType.includes('Account Classification'):
          slug = 'classification';
          break;
        case gameType.includes('Normal Balance'):
          slug = 'normal';
          break;
        case gameType.includes('Balanced Equation'):
          slug = 'balanced-equation';
          break;
        case gameType.includes('The Profit'):
          slug = 'the-profit';
          break;
        default:
          slug = 'classification';
          break;
      }
      setCurrentGameSlug(slug);
    }
  }, [games]);

  const { data: gamesSessions } = useQuery({
    queryKey: ['gamesSessions'],
    queryFn: getGamesSessions,
  });

  useEffect(() => {
    let redirectUrl = '';
    if (user && user.alias_name) {
      if(games?.length && gamesSessions) {
        redirectUrl = '/assignments';
      }
      navigate(redirectUrl);
    }
  }, [user, navigate, games, gamesSessions, currentGameId, currentGameSlug]);

  
  const [userAlias, setUserAlias] = useState('');
  const [warningMessage, setWarningMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);


  const updateUserAliasMutation = useMutation({
    mutationFn: (data) => updateUserAlias(data),
    onSuccess() {
      const updatedUser = { ...user, alias_name: userAlias };
      saveUser(updatedUser);
      setIsLoading(false);
    },
    onError() {
      setWarningMessage('Something went wrong');
      setIsLoading(false);
    }
  });

  const handleOnboarding = () => {
    setIsLoading(true);
    updateUserAliasMutation.mutate({
      id: user?.id,
      alias: userAlias,
      email: user?.email,
      username: user?.username
    });

    navigate('/acc/welcome');
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      handleOnboarding();
    }
  };

  const handleUserAliasChange = (e) => {
    setUserAlias(e.target.value);
    
    setWarningMessage('');
  };

  return (
    <Layout isHeaderVisible={true}>
      <div className="page-content">
        <div className="user-onboarding-picture"></div>
        <h2 className="page-subtitle bold">Enter your preferred name</h2>
        <h3>This will be the name shown at all times including when your instructor displays class scores.</h3>
        <div className="enter-alias-container">
          <div className="alias-row form-row">
            <div className="input-container">
              <input 
                type="text"
                value={userAlias}
                onChange={handleUserAliasChange}
                onKeyUp={handleKeyPress}
                placeholder="Preferred name"
              />
            </div>
            <button
              type="submit"
              onClick={handleOnboarding}
              className={`button-red button-submit ${isLoading ? 'loading' : ''}`}
            >
              {!isLoading && 'Start'}
            </button>
          </div>
          {warningMessage && <div className="warning">{warningMessage}</div>}
        </div>
      </div>
    </Layout>
  );
}
