import QuestionDecicionItem from './questionDecisionItem';

import './styles.css';

export default function QuestionsDecisions({ questionsDecisions }) {
  return (
    <ul className="analysis-questions-decisions"> 
      {
        questionsDecisions.map((questionDecionItem, index) => (
          <QuestionDecicionItem
            key={index}
            title={questionDecionItem.title}
            isCorrect={questionDecionItem.isCorrect}
            viewLink={questionDecionItem.viewLink}
            activity={questionDecionItem.activity}
            textColor={questionDecionItem.textColor}
            backgroundColor={questionDecionItem.backgroundColor}
          />
        ))
      }
    </ul>
  );
};
