import { ItemScore } from './itemScore';

import './styles.css';

export const Item = ({ 
  id,
  name,
  score,
  maxScore,
  formattedScore,
  scoreIndicator,
  showFormattedScore,
  backgroundColor,
  lines
}) => {
  return (
    <li>
      <span className="user-name">{name}</span>
      <span className="user-score-wrapper">
        {lines && lines.length > 0 ? (
          <ul className="user-score-lines">
            {lines.map((line, index) => (
              <li key={index}>
                <ItemScore
                  score={line.score}
                  maxScore={maxScore}
                  formattedScore={line.formattedScore}
                  scoreIndicator={scoreIndicator}
                  showFormattedScore={showFormattedScore}
                  backgroundColor={line.backgroundColor}
                />
              </li>
            ))}
          </ul>
        ) : (
          <ItemScore 
            id={id}
            name={name}
            score={score}
            maxScore={maxScore}
            formattedScore={formattedScore}
            scoreIndicator={scoreIndicator}
            showFormattedScore={showFormattedScore}
            backgroundColor={backgroundColor}
          />
        )}
      </span>
    </li>
  );
};
