import BarItem from './barItem';

import './styles.css';

export default function Bars({ bars, label }) {
  const maxScore = bars ? bars.reduce((max, item) => (item.score > max ? item.score : max), -Infinity) : 0;
  return (
    <ul className="analysis-bars"> 
      {
        bars.map((barItem, index) => (
          <BarItem
            key={index}
            title={barItem.title}
            score={barItem.score}
            color={barItem.color}
            formattedScore={barItem.score}
            maxScore={maxScore}
            label={label}
          />
        ))
      }
    </ul>
  );
};
