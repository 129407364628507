import Icon from './icon';

import './styles.css';

export default function FooterMessage({
  type,
  description
}) {
  const title = type === 'success'
    ? 'Correct answer:'
    : type === 'error'
      ? 'Incorrect'
      : 'Definition';

  return (
    <div className="footer-message">
      <div className="footer-message-icon">
        <Icon type={type} />
      </div>
      <div className="footer-message-content">
        <div className="footer-message-title">
          {title}
        </div>
        <div className="footer-message-description">
          {description}
        </div>
      </div>
    </div>
  );
}