import { useDrag } from 'react-dnd';

import './styles.css';

export default function FallingBlock({ 
  id,
  name,
  answers,
  order,
  parents,
  description,
  secondsFalling,
  accuracyCoefficient,
  left,
  right,
  y,
  onMouseEnter,
  onMouseLeave,
  additionalContent,
  onMouseDown
}) {
  const [{ isDragging }, drag] = useDrag({
    type: 'block',
    item: { id, name, answers, order, parents, description, secondsFalling, accuracyCoefficient },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });
  
  const leftValue = left === 'auto' ? left : left + 'px';
  const rightValue = right === 'auto' ? right : right + 'px';

  return (
    <div
      className="falling-block-container"
      ref={drag}
      style={{
        opacity: isDragging ? 0 : 1,
        left: leftValue,
        right: rightValue,
        top: `${y}px`,
      }}
    >
      <div
        className="brick"
        onMouseEnter={() => onMouseEnter(description)}
        onMouseLeave={onMouseLeave}
        onMouseDown={onMouseDown}
      >
        {name}
      </div>
      {
        additionalContent &&
          additionalContent
      }
    </div>
  );
};