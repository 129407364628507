
import { useState, useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';

import {
  getClassificationScoresByQuestion,
  getNormalScoresByQuestion,
  getBalancedEquationScoresByQuestion
} from '../api';

import './styles.css';

import { navigation } from '../data';
import SubPagesLayout from '../../UI/layout/subPages';
import { LeaderboardOverall } from '../../UI/instructor/leaderboard/overall';

import { elementsQuestions } from './data';

export default function DecisionInsightsClassifyingNormal() {
  const navigate = useNavigate();

  const [filterBy, setFilterBy] = useState('accuracy');
  const [data, setData] = useState(null);

  const { data: classificationScoresByQuestion } = useQuery({
    queryKey: ['classificationScoresByQuestionOverall'],
    queryFn: async () => getClassificationScoresByQuestion()
  });

  const { data: normalScoresByQuestion } = useQuery({
    queryKey: ['normalScoresByQuestionOverall'],
    queryFn: async () => getNormalScoresByQuestion()
  });

  const { data: balancedEquationScoresByQuestion } = useQuery({
    queryKey: ['equationScoresByQuestionOverall'],
    queryFn: async () => getBalancedEquationScoresByQuestion()
  });

  useEffect(() => {
    if(classificationScoresByQuestion && normalScoresByQuestion && balancedEquationScoresByQuestion) {
      formatLeaderboardData();
    }
  }, [classificationScoresByQuestion, normalScoresByQuestion, balancedEquationScoresByQuestion]);

  const calculateAverage = (data, param) => {
    if (!data || data.length === 0) return 0;
  
    const total = data.reduce((sum, record) => sum + record[param], 0);
    return total / data.length;
  };

  const filterScoresByQuestions = (scores, questions) => 
    scores.filter(score => questions.includes(score.question_name));
  
  const filterScoresByParent = (scores, parentName) => 
    scores.filter(score => score.parent_names.includes(parentName));
  
  const getScoreData = (scores, filterBy, key, backgroundColor) => {
    const average = calculateAverage(scores, key);
    const formatted = filterBy === 'accuracy' 
      ? (average * 100).toFixed(2) + '%' 
      : average;
    return {
      score: average,
      formattedScore: formatted,
      backgroundColor
    };
  };

  const formatLeaderboardData = () => {
    const filterKey = filterBy === 'accuracy' ? 'total_accuracy' : 'total_points';

    const generateElementData = (element) => {
      const classificationScores = filterScoresByParent(classificationScoresByQuestion, element);
      const normalScores = filterScoresByParent(normalScoresByQuestion, element);
  
      const classificationData = getScoreData(classificationScores, filterBy, filterKey, 'rgba(85, 180, 188, 0.8)');
      const normalData = getScoreData(normalScores, filterBy, filterKey, '#FDD38A');
  
      return {
        name: element,
        lines: [classificationData, normalData]
      };
    };

    const wholeLeaderboardData = [
      {
        name: 'The Elements',
        lines: [
          getScoreData(filterScoresByQuestions(classificationScoresByQuestion, elementsQuestions), filterBy, filterKey, 'rgba(85, 180, 188, 0.8)'),
          getScoreData(filterScoresByQuestions(normalScoresByQuestion, elementsQuestions), filterBy, filterKey, '#FDD38A')
        ]
      },
      ...elementsQuestions.map(element => generateElementData(element))
    ];

    setData(wholeLeaderboardData);
  }


  const handlePrevButtonClick = () => {
    navigate('/insights/decision-insights/overall/');
  }

  const handleFilterClick = (selectedFilterBy) => {
    setFilterBy(selectedFilterBy);
  }

  const maxScore = data 
    ? data.reduce((max, item) => {
        const lineMax = item.lines.reduce((lineMax, line) => (line.score > lineMax ? line.score : lineMax), -Infinity);
        return lineMax > max ? lineMax : max;
      }, -Infinity)
    : 0;

  const labels = [{name: 'Classification', color: 'rgba(85, 180, 188, 0.8)'}, {name: 'Normal', color: '#FDD38A'}];

  return (
    <SubPagesLayout
      navigation={navigation}
      onFirstButtonClick={handlePrevButtonClick}
      currentPage={1}
      pagesQuantity={1}
      contentClassName="decision-insights-content-container"
    >
      <h1>Insights | Classifying & Normal Balances</h1>
      <h2>Classifying Accounts & Their Normal Balances</h2>
      <p>The chart below is an indicator of your grasp of account classifications and their normal balances. You can filter the data to view it based on points, which reflect both speed and accuracy, or focus solely on accuracy.</p>
      <LeaderboardOverall
        title="Performance"
        data={data}
        filterBy={filterBy}
        onFilterByClick={handleFilterClick}
        maxScore={maxScore}
        labels={labels}
      />
      <p><span className="red"><b>Note:</b></span> The chart will continuously update until the deadline has passed on all assignments.</p>
    </SubPagesLayout>
  );
}
