import { Link, useOutlet, useLocation } from 'react-router-dom';

import './styles.css';
import Header from '../game/header';

import { getPathSegments } from '../../../utils';

export default function LayoutWithLeftBar({leftbarItems}) {
  const outlet = useOutlet();
  const location = useLocation();

  return (
    <div className="accounting-layout">
      <Header />
      <main>
        <div className="accounting-leftbar">
          <ul>
            {leftbarItems.map((leftbarItem, index) => {

              const isActive = (location.pathname === leftbarItem.url)
                || (getPathSegments(location.pathname).slice(0, 2).join('/') === getPathSegments(leftbarItem.url).slice(0, 2).join('/')) || (location.pathname.includes('/instructor/welcome') && leftbarItem.url === '/instructor/dashboard');
              
              return (
                <li key={index}>
                  <Link
                    to={leftbarItem.url}
                    className={`${leftbarItem.class} ${isActive ? 'active' : ''}`}
                    title={leftbarItem.title}
                  />
                </li>
              );
            })}
          </ul>
        </div>
        <div className="content">
          {outlet}
        </div>
      </main>
    </div>
  );
}
