import './styles.css';

import IconError from './images/icon-error.svg';
import IconInfo from './images/icon-info.svg';
import IconSuccess from './images/icon-success.svg';
import IconWarning from './images/icon-warning.svg';

const icons = {
  error: IconError,
  info: IconInfo,
  success: IconSuccess,
  warning: IconWarning,
};

export default function Alert({
  type,
  title,
  text,
  confirmButtonText,
  onConfirmButtonClick,
  cancelButtonText,
  onCancelButtonClick
}) {
  const icon = icons[type];

  return (
    <div className="alert-overlay">
      <div className={`alert alert-${type}`}>
        <div className="alert-content-wrapper">
          <div className="alert-icon-wrapper">
            {icon && <img src={icon} alt={type} />}
          </div>
          <div className="alert-content">
            <h4>{title}</h4>
            {
              text &&
                <p>{text}</p>
            }
          </div>
        </div>
        <div className="alert-buttons">
          {
            cancelButtonText &&
              <button className="alert-cancel-button" onClick={onCancelButtonClick}>{cancelButtonText}</button>
          }
          <button className="button-blue" onClick={onConfirmButtonClick}>{confirmButtonText}</button>
        </div>
      </div>
    </div>
  );
}
