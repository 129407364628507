import Bars from './bars';
import QuestionsDecisions from './questionsDecisions';
import './styles.css';

export default function Analysis({
  heading = 'Analysis',
  tabs,
  activeTab,
  isTabsDisabled,
  bars,
  barsLabel,
  analysisType,
  questionsDecisions
}) {
  return (
    <div className={`analysis ${analysisType}`}>
      <div className="analysis-header">
        <h2>{heading}</h2>
        <div className="analysis-header-buttons">
          {
            tabs && tabs.map((tab, index) => (
              <button
                key={index}
                className={activeTab === tab.title ? 'active' : ''}
                onClick={tab.onClick}
                disabled={isTabsDisabled}
              >
                {tab.title}
              </button>
            ))
          }
        </div>
      </div>
      <div className="analysis-body">
        {
          analysisType === 'bars' && bars && bars.length > 0 &&
            <Bars bars={bars} label={barsLabel} />
        }
        {
          analysisType === 'decisions' &&
            <div className="analysis-decisions-wrapper">
              <h3 className="analysis-body-title">Results based on first attempts</h3>
              <QuestionsDecisions questionsDecisions={questionsDecisions}/>
            </div>
        }
      </div>
    </div>
  );
}
