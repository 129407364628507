
import CorrectIcon from './images/icon-correct.svg';
import IncorrectIcon from './images/icon-incorrect.svg';

export default function Icon({ status }) {
  let iconSrc;
  if (status === 'correct') {
    iconSrc = CorrectIcon;
  } else if (status === 'incorrect') {
    iconSrc = IncorrectIcon;
  }

  return (
    <img src={iconSrc} alt={status} />
  );
}