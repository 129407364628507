
import { useState, useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';
import { useNavigate, useParams } from 'react-router-dom';

import {
  gamesQuery,
  getScenarioLevels,
  getBalancedEquationDecisionsInsights,
  getGamesTransactions,
  getItems
} from '../api';

import { navigation } from '../data';
import InstructorTable from '../../../UI/instructor/table';
import InstructorTransactions from '../../../UI/instructor/transactions';
import SubPagesLayout from '../../../UI/layout/subPages';

const tableHeaders = ['Assets', 'Liabilities', 'Equity'];
const level1TableRows = ['Increase', 'Decrease'];
const level3TableRows = ['Debit', 'Credit'];

export default function InstructorInsightsBalanced() {
  const navigate = useNavigate();

  const params = useParams();
  const levelNumber = Number(params.levelNumber) || 1;

  const [gameId, setGameId] = useState(0);
  const [scenarioId, setScenarioId] = useState(0);

  const [transactions, setTransactions] = useState([]);
  const [currentQuestionId, setCurrentQuestionId] = useState(0);

  const [level1TableData, setLevel1TableData] = useState([]);
  const [level2TableData, setLevel2TableData] = useState([]);
  const [level3TableData, setLevel3TableData] = useState([]);

  const gamesResponse = useQuery(gamesQuery()) || {};
  const games = gamesResponse.data;

  useEffect(() => {
    if (games) {
      const game = games.find(game => game.game_type === 'Balanced Equation');

      if(game) {
        setGameId(game.id);
        setScenarioId(game.scenario);
      }
    }
  }, [games]);

  const { data: scenarioLevels, isSuccess: isScenarioLevelsSuccess } = useQuery({
    queryKey: ['balancedEquationScenarioLevels'],
    queryFn: async () => getScenarioLevels(scenarioId),
    enabled: !!scenarioId,
    retry: false,
    refetchOnWindowFocus: false
  });

  const { data: decisionsInsights, isSuccess: isDecisionsInstingsSuccess } = useQuery({
    queryKey: [`balancedEquationDecisionsInsightsLevel${levelNumber}`],
    queryFn: async () => getBalancedEquationDecisionsInsights({gameId, levelNumber}),
    enabled: !!gameId,
    retry: false,
    refetchOnWindowFocus: false
  });

  const { data: gamesTransactions, isSuccess: isGamesTransactionsSuccess } = useQuery({
    queryKey: [`gamesTransactions${levelNumber}`],
    queryFn: async () => getGamesTransactions(),
    enabled: !!gameId,
    retry: false,
    refetchOnWindowFocus: false
  });

  const { data: items, isSuccess: isItemsSuccess } = useQuery({
    queryKey: ['items'],
    queryFn: async () => getItems(),
    retry: false,
    refetchOnWindowFocus: false
  });

  useEffect(() => {
    if (scenarioLevels?.length > 0) {
      const currentScenarioLevel = scenarioLevels.find(scenarioLevel => scenarioLevel.level === levelNumber);
      const scenarioLevelQuestions = currentScenarioLevel?.be_questions.sort((a, b) => a.order - b.order);
  
      setTransactions(scenarioLevelQuestions);
      
      if (scenarioLevelQuestions?.length > 0) {
        setCurrentQuestionId(scenarioLevelQuestions[0]?.id);
      }
    }
  }, [scenarioLevels, levelNumber]);
  
  const generateRows = (rowTitles, currentQuestionId) => {
    const tableType = rowTitles.includes('Increase')
      ? 'increase-decrease'
      : rowTitles.includes('Debit')
        ? 'debit-credit'
        : 'account';
    
    const assetsItem = items.find(item => item.name === 'Assets');
    const liabilitiesItem = items.find(item => item.name === 'Liabilities');
    const equityItem = items.find(item => item.name === 'Equity');

    return rowTitles.map(dataRowTitle => {
      const row = { question: dataRowTitle };
      const insight = decisionsInsights.find(insight => insight.question_id === currentQuestionId);

      const currentTransaction = gamesTransactions.find(transaction => transaction.id === insight?.transaction_id);
      const transactionDebitRelatedItem = items.find(item => item.id === currentTransaction?.debit);
      const transactionCreditRelatedItem = items.find(item => item.id === currentTransaction?.credit);

      let totalValue = 0;
      insight?.item_options.forEach(insightsOption => {
        let isParentMatch = false;
        const isAssetsColumn = insightsOption.name === 'Assets';
        const isLiabilitiesColumn = insightsOption.name === 'Liabilities';
        const isEquityColumn = insightsOption.name === 'Equity';

        if (tableType === 'increase-decrease') {
          const isIncrease = dataRowTitle === 'Increase';
          isParentMatch =
            (isAssetsColumn && (isIncrease ? transactionDebitRelatedItem?.parents.includes(assetsItem.id) : transactionCreditRelatedItem?.parents.includes(assetsItem.id))) ||
            (isLiabilitiesColumn && (isIncrease ? transactionCreditRelatedItem?.parents.includes(liabilitiesItem.id) : transactionDebitRelatedItem?.parents.includes(liabilitiesItem.id))) ||
            (isEquityColumn && (isIncrease ? transactionCreditRelatedItem?.parents.includes(equityItem.id) : transactionDebitRelatedItem?.parents.includes(equityItem.id)));
        } else if(tableType === 'debit-credit') {
          const isDebitRow = dataRowTitle === 'Debit';

          isParentMatch =
            (isAssetsColumn && (isDebitRow ? transactionDebitRelatedItem?.parents.includes(assetsItem.id) : transactionCreditRelatedItem?.parents.includes(assetsItem.id))) ||
            (isLiabilitiesColumn && (isDebitRow ? transactionDebitRelatedItem?.parents.includes(liabilitiesItem.id) : transactionCreditRelatedItem?.parents.includes(liabilitiesItem.id))) ||
            (isEquityColumn && (isDebitRow ? transactionDebitRelatedItem?.parents.includes(equityItem.id) : transactionCreditRelatedItem?.parents.includes(equityItem.id)));
        } else if (tableType === 'account') {
          isParentMatch = 
            (isAssetsColumn && ((transactionDebitRelatedItem?.name === dataRowTitle && transactionDebitRelatedItem?.parents.includes(assetsItem.id)) || (transactionCreditRelatedItem?.name === dataRowTitle && transactionCreditRelatedItem?.parents.includes(assetsItem.id)))) ||
            (isLiabilitiesColumn && ((transactionDebitRelatedItem?.name === dataRowTitle && transactionDebitRelatedItem?.parents.includes(liabilitiesItem.id)) || (transactionCreditRelatedItem?.name === dataRowTitle && transactionCreditRelatedItem?.parents.includes(liabilitiesItem.id)))) ||
            (isEquityColumn && ((transactionDebitRelatedItem?.name === dataRowTitle && transactionDebitRelatedItem?.parents.includes(equityItem.id)) || (transactionCreditRelatedItem?.name === dataRowTitle && transactionCreditRelatedItem?.parents.includes(equityItem.id))));
        }

        const value = insightsOption.selected_options.find(selectedOption => selectedOption.name === row.question)?.count_users || 0;
        row[insightsOption.name] = {
          value,
          isParentMatch
        };

        totalValue += value;
      });

      insight?.item_options.forEach(insightsOption => {
        const itemData = row[insightsOption.name];
        const threshold = Math.min(10, totalValue * 0.2);
        
        if (itemData.isParentMatch) {
          itemData.background = '#D1E9D6';
        } else if (itemData.value && itemData.value >= threshold) {
          itemData.background = '#FD9595';
        } else {
          itemData.background = '#FFFFFF';
        }
      });

      return row;
    });
  };

  useEffect(() => {
    if (!currentQuestionId || !scenarioLevels || !decisionsInsights || !items || !gamesTransactions) {
      return;
    }

    const currentScenarioLevel = scenarioLevels.find(scenarioLevel => scenarioLevel.level === levelNumber);

    let data = generateRows(level1TableRows, currentQuestionId);
    setLevel1TableData(data);
    setLevel2TableData([]);
    setLevel3TableData([]);

    if (levelNumber > 1) {
      const dataRowTitles = currentScenarioLevel?.be_options
        .sort((a, b) => a.order - b.order)
        .reduce((acc, option) => {
          if (!tableHeaders.includes(option.account_item.name) && !level1TableRows.includes(option.account_item.name) && !level3TableRows.includes(option.account_item.name)) {
            acc.push(option.account_item.name);
          }
          return acc;
        }, []);

      data = generateRows(dataRowTitles, currentQuestionId);
      setLevel2TableData(data);

      if(levelNumber > 2) {
        data = generateRows(level3TableRows, currentQuestionId);
        setLevel3TableData(data);
      }
    }
  }, [currentQuestionId, scenarioLevels, decisionsInsights, items, levelNumber, gamesTransactions]);

  const handleTransactionSelect = (transaction) => {
    setCurrentQuestionId(transaction.id)
  }

  const handlePrevButtonClick = () => {
    let redirectUrl = levelNumber === 1 
      ? '/instructor/insights/decision-insights/normal/1' 
      : `/instructor/insights/decision-insights/balanced/${levelNumber - 1}`;
    navigate(redirectUrl);
  }

  const handleNextButtonClick = () => {
    navigate(`/instructor/insights/decision-insights/balanced/${levelNumber + 1}`);
  }

  let description;
  switch(levelNumber) {
    case 1:
      description = 'In level 1 of this exercise, students decided how each transaction impacts the Balance Sheet elements. Below are the distribution of students’ decisions on their first attempt.';
      break;
    case 2:
      description = 'In level 2, students decided which accounts were affected by the transactions listed below and how those accounts were affected. Below are the distribution of students’ decisions on their first attempt.';
      break;
    case 3:
      description = 'In level 3, students decided how each transaction impacts the Balance Sheet elements and if the impact was a debit or a credit to the element. Below are the distribution of students’ decisions on their first attempt.';
      break;
    default:
      description = `In level ${levelNumber} of this exercise, students decided how each transaction impacts the Balance Sheet elements. Below are the distribution of students’ decisions on their first attempt.`;
      break;
  }

  const isDataLoading = !isScenarioLevelsSuccess || !isDecisionsInstingsSuccess || !isItemsSuccess || !isGamesTransactionsSuccess;

  return (
    <SubPagesLayout
      navigation={navigation}
      onFirstButtonClick={handlePrevButtonClick}
      onSecondButtonClick={levelNumber === scenarioLevels?.length ? null : handleNextButtonClick}
      currentPage={levelNumber}
      pagesQuantity={scenarioLevels?.length}
    >
      <h1>Insights | Decisions</h1>
      <h2>Balanced Equation</h2>
      <p>{description}</p>
      <InstructorTransactions 
        transactions={transactions}
        selectedTransactionId={currentQuestionId}
        onSelectChange={handleTransactionSelect}
      />
      <InstructorTable 
        title="Decision: Increase or Decrease"
        tableColumnHeader="Decision Options"
        tableHeaders={tableHeaders}
        tableData={level1TableData}
        isDataLoading={isDataLoading}
      />

      {
        level3TableData.length > 0 &&
          <InstructorTable 
            title="Decision: Debit or Credit"
            tableColumnHeader="Decision Options"
            tableHeaders={tableHeaders}
            tableData={level3TableData}
            isDataLoading={isDataLoading}
          />
      }
      {
        level2TableData.length > 0 &&
          <InstructorTable 
            title="Decision: Accounts Impacted"
            tableColumnHeader="Decision Options"
            tableHeaders={tableHeaders}
            tableData={level2TableData}
            isDataLoading={isDataLoading}
          />
      }
    </SubPagesLayout>
  );
}
