import axiosClient from '../../api/axiosClient.js';
import { fetchPageData } from '../../api/utils.js';

async function getGames() {
  return fetchPageData(`/api/games/games/`);
}

export const gamesQuery = () => {
  return {
    queryKey: ['games'],
    queryFn: async () => getGames(),
    refetchOnWindowFocus: false,
    cacheTime: 0
  };
};

async function getScenarioLevels(scenarioId) {  
  return scenarioId ? fetchPageData(`/api/games/scenario-levels/?scenarios=${scenarioId}`) : [];
}

export const scenarioLevelsQuery = ({scenarioId}) => {
  return {
    queryKey: ['scenarioLevels', scenarioId],
    queryFn: async () => getScenarioLevels(scenarioId),
    refetchOnWindowFocus: false,
    cacheTime: 0,
    enabled: !!scenarioId
  };
};

async function getScenarioLevel({scenarioId, level}) {
  const response = await axiosClient.get(`/api/games/scenario-levels/?scenarios=${scenarioId}&level=${level}`);
  return response.data.results[0];
}

export const scenarioLevelQuery = ({scenarioId, level}) => {
  return {
    queryKey: ['scenarioLevel', scenarioId],
    queryFn: async () => getScenarioLevel({scenarioId, level}),
    refetchOnWindowFocus: false,
    cacheTime: 0,
    enabled: !!scenarioId
  };
};

async function getItemOptions() {  
  return fetchPageData(`/api/classification/item-options/`);
}

export const itemOptionsQuery = () => {
  return {
    queryKey: ['itemOptions'],
    queryFn: async () => getItemOptions(),
    refetchOnWindowFocus: false,
    cacheTime: 0
  };
};

async function getItemQuestions(scenarioLevelId) {  
  return scenarioLevelId ? fetchPageData(`/api/classification/item-questions/?scenario_level=${scenarioLevelId}`) : [];
}

export const itemQuestionsQuery = (scenarioLevelId) => {
  return {
    queryKey: ['itemQuestions', scenarioLevelId],
    queryFn: async () => getItemQuestions(scenarioLevelId),
    refetchOnWindowFocus: false,
    cacheTime: 0
  };
};

async function getItems() {
  return fetchPageData(`/api/games/items/`);
}

export const itemsQuery = () => {
  return {
    queryKey: ['items'],
    queryFn: async () => getItems(),
    refetchOnWindowFocus: false,
    cacheTime: 0
  };
};

export async function makeDecision({user, game, question, selected_option, time_elapsed, timestamp, gameSessionId}) {
  const { data } = await axiosClient.post(`api/classification/decisions/`, {
    user,
    game,
    question,
    selected_option,
    time_elapsed,
    timestamp,
    game_session: gameSessionId
  });

  return data;
}

export async function getDecisions({levelNumber, userId, gameId, gameSessionId}) {
  return fetchPageData(`/api/classification/decisions/?level=${levelNumber}&user=${userId}&game=${gameId}&game_session=${gameSessionId}`);
}

export async function getGamesSessions() {
  return fetchPageData(`/api/games/sessions/`);
}

export async function getScoresStats({gameId, attempt}) {
  const response = await axiosClient.get(`/api/classification/scores/stats/${gameId}/?attempt=${attempt}`);
  return response.data;
}

export const scoresStatsQuery = ({gameId, attempt}) => {
  return {
    queryKey: ['scoresStats'],
    queryFn: async () => getScoresStats({gameId, attempt}),
    refetchOnWindowFocus: false,
    enabled: !!gameId && !!attempt
  };
};

export async function getScoresByQuestion({gameId, gameSessionId}) {
  return fetchPageData(`/api/classification/scores/by-question/?game=${gameId}&game_session=${gameSessionId}`);
}

export const scoresByQuestionQuery = ({gameId, gameSessionId}) => {
  return {
    queryKey: ['scoresByQuestion'],
    queryFn: async () => getScoresByQuestion({gameId, gameSessionId}),
    refetchOnWindowFocus: false,
    enabled: !!gameId && !!gameSessionId
  };
};

async function getSessions() {
  return fetchPageData(`/api/games/sessions/`);
}

export const sessionsQuery = () => {
  return {
    queryKey: ['sessions'],
    queryFn: async () => getSessions(),
    refetchOnWindowFocus: false
  };
};

export async function getScores(game, user, currentGameSessionId) {
  return fetchPageData(`/api/classification/scores/?game=${game}&user=${user}&&game_session=${currentGameSessionId}`);
}

export async function updateFeedback({id, feedback1, feedback2, feedback3}) {
  const { data } = await axiosClient.patch(`/api/games/sessions/${id}/`, {
    feedback1,
    feedback2,
    feedback3
  });

  return data;
}

export async function createGameSession({game, user}) {
  const { data } = await axiosClient.post(`/api/games/sessions/`, {
    game,
    user,
    level1: 'in_progress',
    is_ready: true
  });

  return data;
}

export async function updateGameSession({id, levelNumber, levelStatus, isSessionCompleted}) {
  const levelKey = `level${levelNumber}`;
  const requestBody = {
    [levelKey]: levelStatus,
  };

  if (isSessionCompleted) {
    requestBody.completed = true;
  }

  const { data } = await axiosClient.patch(`/api/games/sessions/${id}/`, requestBody);

  return data;
}