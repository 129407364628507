import Hint from '../../hint';
import OptionItem from './optionItem';

import './styles.css';

export default function OptionItemsRow({
  options,
  isHintShown,
  hintContent,
  onHintButtonClick,
  isItemsDisabled
}) {
  return (
    <div className="options-item-row">
      {
        options.map((option, index) => (
          <OptionItem 
            key={index}
            id={option.id}
            accountItemId={option.accountItemId}
            name={option.name} 
            description={option.description}
            type={option.type}
            isItemDisabled={isItemsDisabled}
          />
        ))
      }
      
      {
        isHintShown &&
          <div className="options-item-row-hint-anchor">
            <Hint
              content={hintContent}
              buttonText="Next"
              onButtonClick={onHintButtonClick}
              side="left"
            />
          </div>
      }
    </div>
  );
}
