import AvatarBailey from './images/avatar-bailey.png';
import AvatarBenny from './images/avatar-benny.png';
import AvatarEmma from './images/avatar-emma.png';

import './styles.css';

export default function Avatar({
  name = '',
  showName = false,
  isImageWithBorder = false
}) {
  const avatar = name === 'Bailey' 
    ? AvatarBailey 
    : name === 'Benny'
      ? AvatarBenny
      : AvatarEmma;

  return (
    <div className={`popup-messages-avatar ${name.toLowerCase()}-avatar ${isImageWithBorder ? 'bordered' : ''}`}>
      <img alt={`${name} avatar`} src={avatar}/>
      {
        showName &&
          <span className="avatar-label">{name}</span>
      }
    </div>
  );
}
