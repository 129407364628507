import { useState } from 'react';

import './styles.css';

export default function Select({
  items,
  selectedItemId,
  onSelectChange
}) {
  const [isExpanded, setIsExpanded] = useState(false);

  const handleSelectToggle = () => {
    setIsExpanded(!isExpanded);
  };
  
  const handleSelectChange = (selectItem) => {
    onSelectChange(selectItem);
    setIsExpanded(false);
  };
  
  return (
    <div className="select-wrapper">
      <div className="select-header" onClick={handleSelectToggle}>
        <span className="text-wrapper">
          <span>{selectedItemId ? items.find(i => i.id === selectedItemId)?.title : items[0]?.title}</span>
        </span>
        <span className="select-icon"></span>
      </div>
      <div className={`select-body ${isExpanded ? 'active' : ''}`}>
        <ul>
          {items.map((selectItem, index) => (
            <li 
              key={index}
              onClick={() => handleSelectChange(selectItem)}
            >
              {selectItem.title}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}
