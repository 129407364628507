import { useNavigate, useParams } from 'react-router-dom';

import Layout from '../UI/introGameLayout/layout';

import IntroLevel1 from './videos/intro-level-1.mp4';
import IntroLevel3 from './videos/intro-level-3.mp4';

import './styles.css';
import Video from '../UI/introGameLayout/video';

export default function LoginIntro() {
  const navigate = useNavigate();

  const params = useParams();
  const levelNumber = Number(params.levelNumber);

  const handleContinueButtonClick = () => {
    navigate(`/balanced-equation/level/${levelNumber}/tutorial`);
  }

  const introVideo = levelNumber === 3 ? IntroLevel3 : IntroLevel1;

  return(
    <Layout
      isHeaderVisible={true}
      isContentVertical={true}
      backgroundColor={'#3A507B'}
    >
      <div className="intro-video-wrapper">
        <Video videoSrc={introVideo} />
        <button className="intro-button" onClick={handleContinueButtonClick}>Continue</button>
      </div>
    </Layout>
  );
}
