import { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';

import useAuth from '../../auth/useAuth';

import Layout from '../UI/introGameLayout/layout';

import BackgroundImage from './images/summary-background.jpg';

import { 
  gamesQuery,
  scoresByQuestionQuery,
  scenarioLevelsQuery,
  getGamesSessions
} from './api';

import {
  popupMessagesMostChallenging,
  popupMessagesAccuracyItems,
  popupMessagesAccuracyGeneralTexts
} from './data';

import Analysis from '../UI/game/analysis';
import PopupMessages from '../UI/introGameLayout/popupMessages';

import './styles.css';

export default function NormalBalanceMostChallenging() {
  const params = useParams();
  const levelNumber = Number(params.levelNumber);

  const navigate = useNavigate();
  
  const { 
    getUser,
    getGuidedPlay,
    getCurrentNormalGameId
  } = useAuth();

  const user = getUser();
  const username = user?.alias_name 
    ? user?.alias_name 
    : user?.first_name 
      ? user?.first_name
      : user?.username
        ? user?.username 
        : '';
  
  const gameId = getCurrentNormalGameId();

  const [scenarioId, setScenarioId] = useState(0);

  const shouldGuidedPagesBeDisplayed = getGuidedPlay();

  const [data, setData] = useState([]); 
  const [levelsCount, setLevelsCount] = useState(0);

  const { data: gamesSessions } = useQuery({
      queryKey: ['gamesSessionsMostChallenging'],
      queryFn: getGamesSessions,
      refetchOnWindowFocus: false,
    });
  
    const [currentGameSessionId, setCurrentGameSessionId] = useState(0);
  
    useEffect(() => {
      if(gamesSessions && gameId && user) {
        const currentGameSession = gamesSessions.find(session => session.user === user?.id && session.game === gameId && !session.completed);
        
        if (currentGameSession) {
          setCurrentGameSessionId(currentGameSession?.id);
        } else {
          const completedGameSession = gamesSessions
            .filter(session => session.user === user?.id && session.game === gameId && session.completed)
            .reduce((maxSession, session) => (session.id > (maxSession?.id || 0) ? session : maxSession), null);
    
          setCurrentGameSessionId(completedGameSession?.id);
        }
      }
    }, [gamesSessions, gameId, user]);

  const { data: scoresByQuestion } = useQuery(scoresByQuestionQuery({ gameId, gameSessionId: currentGameSessionId }));
  const { data: scenarioLevels } = useQuery(scenarioLevelsQuery({scenarioId}));

  const { data: games } = useQuery(gamesQuery()) || {};

  useEffect(() => {
    if(games) {
      const game = games.find(game => game.game_type.includes('Normal Balance') && game.id === gameId);

      if(game && game.scenario) {
        setScenarioId(game.scenario);
      }
    }
  }, [games, gameId]);

  useEffect(() => {
    if(scoresByQuestion && scenarioLevels?.length) {
      let scenarioLevelsCount = scenarioLevels.length;
      setLevelsCount(scenarioLevelsCount);

      const levelScoresByQuestion = scoresByQuestion
        .filter(scoreByQuestion => scoreByQuestion.level === levelNumber && scoreByQuestion.user === user.id)
        .sort((a, b) => a.total_points - b.total_points)
        .slice(0, 5);

      const barsData = [
        { title: 'Mastery', score: 90, color: '#70A1FF' },
        ...levelScoresByQuestion.map(scoreByQuestion => ({
          title: scoreByQuestion.question_name,
          score: scoreByQuestion.total_points
        }))
      ];
      const data = [
        {
          title: 'Accounts',
          subtitle: 'Max points = 100',
          bars: barsData
        }
      ]
      setData(data);
    }
  }, [username, scoresByQuestion, levelNumber, scenarioLevels, user.id]);

  const [dataLoaded, setDataLoaded] = useState(false);

  useEffect(() => {
    if (games && scoresByQuestion && scenarioLevels) {
      setDataLoaded(true);
    }
  }, [games, scoresByQuestion, scenarioLevels]);

  const items = popupMessagesAccuracyItems[levelNumber - 1];
  const generalTexts = popupMessagesAccuracyGeneralTexts[levelNumber - 1];

  const popupMessages = popupMessagesMostChallenging.map(message => ({
    ...message,
    text: message.text.replace('{items}', items)
      .replace('{name}', username)
      .replace('{generalTexts}', generalTexts)
  }));

  const handlePopupMessagesNextButtonClick = () => {
    let redirectUrl;

    if(shouldGuidedPagesBeDisplayed) {
      redirectUrl = `/normal/level/${levelNumber + 1}/intro`;
    } else {
      if(levelNumber === 4) {
        redirectUrl = `/normal/level/5/tutorial`;
      } else {
        redirectUrl = `/normal/level/${levelNumber + 1}/`;
      }
    }

    navigate(redirectUrl);
  }

  const analysisHeading = !shouldGuidedPagesBeDisplayed && levelNumber === levelsCount ? 'Analysis' : `Level ${levelNumber} Analysis`;

  return(
    <Layout
      backgroundImage={BackgroundImage}
      isBackgroundBlurred={false}
      isHeaderVisible={true}
      isFooterVisible={false}
    >
      {
        dataLoaded && popupMessages?.length > 0 &&
          <PopupMessages
            messages={popupMessages}
            buttonText="Next"
            onButtonClick={levelNumber < levelsCount ? handlePopupMessagesNextButtonClick : undefined}
            messageSize="small"
          />
      }

      <Analysis
        heading={analysisHeading}
        data={data}
      />
    </Layout>
  );
}
