import React from 'react';
import { Navigate } from 'react-router-dom';
import useAuth from '../auth/useAuth';

const AuthenticatedRoute = ({ element }) => {
  const { isUserAuthenticated } = useAuth();

  return isUserAuthenticated() ? (
    element
  ) : (
    <Navigate to="/acc/login" replace />
  );
};

export default AuthenticatedRoute;
