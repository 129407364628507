import "react-datetime/css/react-datetime.css";
import './styles.css';
import { balancedEquationAccountsTexting } from './data';

export default function InstructorAssignmentLevelSavedAccounts({ gameType, scenarioLevels, gamesItems, activeScenarioLevel }) {
  const getScenarioData = (typeKey) =>
    gameType?.includes('Account Classification')
      ? scenarioLevels[activeScenarioLevel][typeKey]
      : gameType?.includes('Normal Balance')
        ? scenarioLevels[activeScenarioLevel][`normal_${typeKey}`]
        : gameType?.includes('Balanced Equation')
          ? scenarioLevels[activeScenarioLevel][`be_${typeKey}`]
          : [];

  const options = getScenarioData('options');
  const questions = getScenarioData('questions');

  const renderQuestions = (questions, label) => (
    <div className="instructor-level-saved-accounts-column">
      {
        gameType === 'Balanced Equation' &&
          <p>{balancedEquationAccountsTexting[activeScenarioLevel] || balancedEquationAccountsTexting[2]}</p>
      }
      <h4>{label}</h4>
      <ul className="instructor-level-saved-accounts-list">
        {
          questions.map((relatedQuestion, relatedQuestionIndex) => (
            <li
              key={`saved-account-question-${relatedQuestionIndex}`}
            >
              {relatedQuestion.description}
            </li>
          ))
        }
      </ul>
    </div>
  );

  if (gameType?.includes('Balanced Equation')) {
    return (
      <div className="instructor-level-saved-accounts">
        {renderQuestions(questions, 'Transactions')}
      </div>
    );
  }

  return (
    <div className="instructor-level-saved-accounts">
      {
        options.map((option, optionIndex) => {
          const relatedQuestions = questions.filter(question => {
            return gamesItems.some(gameItem =>
              gameItem.parents.includes(option.account_item.id) &&
              gameItem.id === question.account_item.id
            );
          });

          return (
            <div
              key={`saved-column-${optionIndex}`}
              className="instructor-level-saved-accounts-column"
            >
              <h4>{option.account_item?.name}</h4>
              <ul className="instructor-level-saved-accounts-list">
                {
                  relatedQuestions.map((relatedQuestion, relatedQuestionIndex) => (
                    <li
                      key={`saved-account-question-${relatedQuestionIndex}`}
                    >
                      {relatedQuestion.account_item.name}
                    </li>
                  ))
                }
              </ul>
            </div>
          );
        })
      }
    </div>
  );
}
