import { useState, useEffect } from 'react';
import { useDrop } from 'react-dnd';

import List from './list';

export default function DropArea({ 
  container,
  onDrop,
  side,
  children,
  prePlacedItems = [],
  isGameSorted,
  onSortedItemClick
}) {

  const [droppedItems, setDroppedItems] = useState([...prePlacedItems]);

  useEffect(() => {
    const newItems = prePlacedItems.filter(pItem => !droppedItems.some(dItem => dItem.id === pItem.id));
    
    if (newItems.length > 0) {
      setDroppedItems(current => [...current, ...newItems]);
    }
  }, [prePlacedItems]);
  

  const handleFallingItemDrop = (item) => {
    onDrop(item, container, (error, result) => {
      if (error) {
        console.log('Error in Decision Made: ', error);
        return;
      }
      
      const newItem = { 
        ...item,
        isWrong: !result.is_correct,
        hasAnimated: false,
        points: result.score_points
      };
  
      setDroppedItems(prevItems => {
        const updatedPrevItems = prevItems.map(item => ({ ...item, hasAnimated: true }));
        const updatedItems = [...updatedPrevItems, newItem];
        return updatedItems.sort((a, b) => a.order - b.order);
      });
  
      if (newItem.isWrong) {
        setTimeout(() => {
          setDroppedItems(prevItems => prevItems.filter(i => i.id !== item.id));
        }, 1500);
      }
    });
  };

  const handleItemRemoveFromPrevDropArea = (draggedItemId) => {
    let itemRemoved = false;

    const updatedDroppedItems = droppedItems.filter(item => {
      if (!itemRemoved && item.id === draggedItemId) {
        itemRemoved = true;
        return false;
      }
      return true;
    });

    const updatedItemsWithAnimation = updatedDroppedItems.map(item => ({
      ...item,
      hasAnimated: true
    }));

    setDroppedItems(updatedItemsWithAnimation);
  };
  
  const handleItemIsCorrectlyPlaced = (correctlyPlacedItem) => {      
    const updatedDroppedItems = droppedItems.map(item => {
      if (item.id === correctlyPlacedItem.id) {
        return { ...item, isCorrectlyPlaced: true };
      }
      return item;
    });
    setDroppedItems(updatedDroppedItems);
  };

  const handleSortedItemDrop = (item) => {
    const sameDropArea = droppedItems.some(existingItem => existingItem.id === item.id);
    setDroppedItems(prevItems => {
      if (sameDropArea) {
        return prevItems;
      } else {
        if(item.isSortingTutorial) {
          const containerName = container.name;

          let gameTypeColumnText = '';
          if(containerName === 'Debit' || containerName === 'Credit') {
            gameTypeColumnText = 'Normal Balance';
          } else {
            gameTypeColumnText = 'Financial Statement Element';
          }
          const article = /^[aeiou]/i.test(containerName) ? 'an' : 'a';
          item.description = `Is this ${article} <b>${containerName} account?</b> If so, click on the account to confirm. If not, drag it to the ${gameTypeColumnText} it belongs under.`;
        }
        return [...prevItems, item];
      }
    });

    if(item.onItemDropped && !sameDropArea) {
      item.onItemDropped(item.id, sameDropArea);
    }
  };
  

  const [, drop] = useDrop({
    accept: 'block',
    drop: isGameSorted ? handleSortedItemDrop : handleFallingItemDrop,
  });

  const shouldDisplayChildren = children && children.length > 0;

  const dropAreaRef = shouldDisplayChildren ? null : drop;

  return (
    <div ref={dropAreaRef} className="blocks-drop-area">
      <div className="level-blocks-title"><span>{container.name}</span></div>
      {shouldDisplayChildren ? (
        <div className="level-blocks-children">
          {children}
        </div>
      ) : (
        <div className="level-blocks-content">
          <List 
            droppedItems={droppedItems}
            side={side}
            isGameSorted={isGameSorted}
            onItemsDropped={handleItemRemoveFromPrevDropArea}
            onItemsCorrectlyPlaced={handleItemIsCorrectlyPlaced}
            onSortedItemClick={onSortedItemClick}
            container={container}
          />
        </div>
      )}
    </div>
  );
};
