import './styles.css';

export default function AssignmentInfo({ title, description }) {
  return (
    <div className={`games-assignment-info ${!description ? 'no-description' : ''}`}>
      <h5>{title}</h5>
      {
        description &&
          <p>{description}</p>
      }
    </div>
  );
}
