import { useState } from 'react';
import { useQuery } from '@tanstack/react-query';

import useAuth from '../../auth/useAuth';

import { navigation } from './data';

import { 
  gamesQuery,
  sessionsQuery,
  scoresClassificationQuery,
  scoresNormalQuery,
  scoresEquationQuery
} from './api';

import './styles.css';
import SubPagesLayout from '../UI/layout/subPages';
import AssignmentsList from '../UI/assignments';

export default function Games() {
  const { getUser } = useAuth();

  const user = getUser();

  const gamesResponse = useQuery(gamesQuery()) || {};
  const games = gamesResponse.data;

  const sessionsResponse = useQuery(sessionsQuery()) || {};
  const sessions = sessionsResponse.data;

  const { data: scoresClassification } = useQuery(scoresClassificationQuery({ userId: user?.id }));
  const { data: scoresEquation } = useQuery(scoresEquationQuery({ userId: user?.id }));
  const { data: scoresNormal } = useQuery(scoresNormalQuery({ userId: user?.id }));

  const scores = {
    'Account Classification': scoresClassification,
    'Balanced Equation': scoresEquation,
    'Normal Balance | (debits & credits)': scoresNormal
  }

  return (
    <SubPagesLayout
      navigation={navigation}
      contentClassName="assignments-page-wrapper"
    >
      <h1>Assignments</h1>
      <AssignmentsList
        games={games}
        sessions={sessions}
        scores={scores}
      />
    </SubPagesLayout>
  );
}
