import Timer from '../timer';
import Score from '../score';

import { getFormattedTime } from '../../../../utils';
import useAuth from '../../../../auth/useAuth';

import SoundButton from './soundButton';
import UserName from './userName';

import Logo from './images/logo.png';
import FeedbackIcon from './images/icon-feedback.png';
import HomeIcon from './images/icon-home.svg';

import './styles.css';
import { Link } from 'react-router-dom';

export default function Header({
  time = null,
  totalScore = null,
  shouldScorePlaySound = false,
  scoreLabel
}) {
  const formattedTime = getFormattedTime(time);

  const { logout, isStudent } = useAuth();

  const handleLogoutClick = () => {
    logout();
  }

  return (
    <header className="game-header">
      <div className="game-header-left-part">
        <div className="game-logo">
          <img src={Logo} alt="Logo" />
          <span>Market Games</span>
        </div>
      </div>
      <div className="container">
        <div className="game-header-columns">
          {
            time !== null && 
              <Timer time={formattedTime}/>
          }
          {
            totalScore !== null &&
              <Score 
                score={totalScore}
                playSound={shouldScorePlaySound}
                scoreLabel={scoreLabel}
              />
          }
        </div>
      </div>
      <div className="game-header-right-part">
        {
          isStudent() &&
            <Link
              to="#"
              title="Home"
              className="home-link"
            >
              <img src={HomeIcon} alt="Provide Feedback" />
            </Link>
        }
        <UserName />
        {
          isStudent() &&
            <Link
              to="/classification/feedback"
              title="Provide Feedback"
              className="provide-feedback-link"
              target="_blank"
            >
              <img src={FeedbackIcon} alt="Provide Feedback" />
            </Link>
        }
        <SoundButton />
        <button className="button-logout" onClick={handleLogoutClick}>Logout</button>
      </div>
    </header>
  );
}
