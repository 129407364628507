import { useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { useQuery, useMutation } from '@tanstack/react-query';

import useAuth from '../../auth/useAuth';

import Layout from '../UI/introGameLayout/layout';

import InstructorVideo from './videos/intro-instructor.mp4';
import StudentVideo from './videos/intro-student.mp4';

import { 
  updateCourseMemberViewWelcome,
  getCourseMembers,
  updateUserOnboarding
} from './api';

import './styles.css';
import Video from '../UI/introGameLayout/video';

export default function LoginIntro() {
  const navigate = useNavigate();

  const {
    getUser,
    isInstructor,
    isStudent
  } = useAuth();

  const { data: courseMembers } = useQuery({
    queryKey: ['courseMembers'],
    queryFn: getCourseMembers,
    refetchOnWindowFocus: false
  });

  const user = getUser();
  const userIsInstructor = isInstructor();
  const userIsStudent = isStudent();
  
  const updateCourseMemberViewWelcomeMutation = useMutation({
    mutationFn: (data) => updateCourseMemberViewWelcome(data),
    onSuccess: () => {
      navigate('/instructor/dashboard');
    },
    onError() {
      console.log('Server error');
    }
  });
  
  const updateUserOnboardingMutation = useMutation({
    mutationFn: (data) => updateUserOnboarding(data),
    onSuccess: () => {
      navigate('/assignments');
    },
    onError() {
      console.log('Server error');
    }
  });

  const handleContinueButtonClick = () => {
    let redirectUrl;
    
    if(userIsInstructor && courseMembers?.length) {
      const courseMember = courseMembers.find(courseMember => courseMember.user_id === user.id);
      updateCourseMemberViewWelcomeMutation.mutate({id: courseMember?.id});
    } else if(userIsStudent) {
      if (user && user.alias_name) {
        updateUserOnboardingMutation.mutate({id: user.id});
      } else {
        redirectUrl = '/onboarding';
      }
    }

    navigate(redirectUrl);
  }

  const videoSrc = isInstructor() ? InstructorVideo : StudentVideo;

  return(
    <Layout
      isHeaderVisible={true}
      isContentVertical={true}
      backgroundColor={'#3A507B'}
    >
      <div className="intro-video-wrapper">
        <Video videoSrc={videoSrc} />
        <button className="intro-button" onClick={handleContinueButtonClick}>Continue</button>
      </div>
    </Layout>
  );
}
