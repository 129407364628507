import './styles.css';

export default function Buttons({ 
  isNextVisible,
  isPrevVisible,
  onNextButtonClick,
  onPrevButtonClick
}) {
  return (
    <div className="transactions-buttons">
      { isPrevVisible && <button className="button-navi" onClick={onPrevButtonClick}>Last</button> }
      { isNextVisible && <button className="button-navi" onClick={onNextButtonClick}>Next</button> }
    </div>
  );
}
