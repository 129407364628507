import axiosClient from '../../api/axiosClient.js';

export async function updateUserAlias({id, alias, email, username}) {
  const { data } = await axiosClient.patch(`/api/users/users/${id}/`, {
    alias_name: alias,
    email,
    username
  });

  return data;
}

async function fetchGames(url, allSessions = []) {
  const response = await axiosClient.get(url);
  const sessions = [...allSessions, ...response.data.results];
  
  if (response.data.next) {
    return fetchGames(response.data.next, sessions);
  } else {
    return sessions;
  }
}

export async function getGames() {
  return fetchGames(`/api/games/games/`);
}

export const gamesQuery = () => {
  return {
    queryKey: ['games'],
    queryFn: async () => getGames(),
    refetchOnWindowFocus: false,
    cacheTime: 0
  };
};

async function fetchGamesSessions(url, allSessions = []) {
  const response = await axiosClient.get(url);
  const sessions = [...allSessions, ...response.data.results];
  
  if (response.data.next) {
    return fetchGamesSessions(response.data.next, sessions);
  } else {
    return sessions;
  }
}

export async function getGamesSessions() {
  return fetchGamesSessions(`/api/games/sessions/`);
}