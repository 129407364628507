
import { useState, useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';

import { 
  gamesQuery,
  getScenarioLevels,
  getCourseMembers,
  getClassificationScores,
  getNormalScores,
  getProfitScores,
  getClassificationScoresByQuestion,
  getNormalScoresByQuestion,
  getProfitScoresByQuestion
} from '../api';

import { calculateTimeInSeconds } from '../../../../utils';

import './styles.css';

import { Leaderboard } from '../../../UI/instructor/leaderboard';

import { sortAndSelectTop } from '../../../../utils';
import SubPagesLayout from '../../../UI/layout/subPages';

import { navigation } from '../data';

export default function InstructorInsightsAverageTime() {
  const navigate = useNavigate();

  const [scenarioId, setScenarioId] = useState(0);
  const [courseId, setCourseId] = useState(0);

  const [selectedLevel, setSelectedLevel] = useState(0);
  const [selectedGame, setSelectedGame] = useState(null);

  const [scoreBy, setScoreBy] = useState('account');
  const [sort, setSort] = useState('desc');
  const [isRandomOrder, setIsRandomOrder] = useState(true);

  const [data, setData] = useState(null);

  const [isUpdateResultsButtonClicked, setIsUpdateResultsButtonClicked] = useState(false);

  const gamesResponse = useQuery(gamesQuery()) || {};
  const games = gamesResponse.data;

  useEffect(() => {
    if (games) {
      let game = games.find(game => game.game_type.includes('Account Classification'));
  
      if (!game) {
        game = games[0];
      }
  
      if (game) {
        setScenarioId(game.scenario);
        setCourseId(game.course);
        setSelectedGame({ id: game.id, type: game.game_type });
      }
    }
  }, [games]); 

  const { data: scenarioLevels, refetch: refetchScenarioLevels, isFetching: isScenarioLevelsFetching } = useQuery({
    queryKey: ['scenarioLevels'],
    queryFn: async () => getScenarioLevels(scenarioId),
    enabled: !!scenarioId,
    retry: false,
    refetchOnWindowFocus: false
  });

  useEffect(() => {
    if (scenarioLevels?.length > 0) {
      setSelectedLevel(scenarioLevels[0].level);
    }
  }, [scenarioLevels]);

  const { data: classificationScores, refetch: refetchClassificationScores, isFetching: isClassificationScoresFetching } = useQuery({
    queryKey: ['classificationScores'],
    queryFn: async () => getClassificationScores(selectedGame?.id),
    enabled: false,
    retry: false,
  });

  const { data: normalScores, refetch: refetchNormalScores, isFetching: isNormalScoresFetching } = useQuery({
    queryKey: ['normalScores'],
    queryFn: async () => getNormalScores(selectedGame?.id),
    enabled: false,
    retry: false,
  });

  const { data: profitScores, refetch: refetchProfitScores, isFetching: isProfitScoresFetching } = useQuery({
    queryKey: ['profitScores'],
    queryFn: async () => getProfitScores(selectedGame?.id),
    enabled: false,
    retry: false,
  });
    
  const { data: classificationScoresByQuestion, refetch: refetchClassificationScoresByQuestion, isFetching: isClassificationScoresByQuestionFetching } = useQuery({
    queryKey: ['classificationScoresByQuestion'],
    queryFn: async () => getClassificationScoresByQuestion(selectedGame?.id),
    enabled: false,
    retry: false,
  });
    
  const { data: normalScoresByQuestion, refetch: refetchNormalScoresByQuestion, isFetching: isNormalScoresByQuestionFetching } = useQuery({
    queryKey: ['normalScoresByQuestion'],
    queryFn: async () => getNormalScoresByQuestion(selectedGame?.id),
    enabled: false,
    retry: false,
  });
    
  const { data: profitScoresByQuestion, refetch: refetchProfitScoresByQuestion, isFetching: isProfitScoresByQuestionFetching } = useQuery({
    queryKey: ['profitScoresByQuestion'],
    queryFn: async () => getProfitScoresByQuestion(selectedGame?.id),
    enabled: false,
    retry: false,
  });
  
  const { data: courseMembers } = useQuery({
    queryKey: ['courseMembers'],
    queryFn: async () => getCourseMembers(courseId),
    enabled: !!courseId,
    retry: false,
  });

  const isLoading = isClassificationScoresFetching
    || isNormalScoresFetching
    || isProfitScoresFetching
    || isClassificationScoresByQuestionFetching
    || isNormalScoresByQuestionFetching
    || isProfitScoresByQuestionFetching
    || isScenarioLevelsFetching;

  const handleLevelChange = (selectedItem) => {
    setSelectedLevel(selectedItem.id);
    setSort('desc');
    setIsRandomOrder(true);
  };

  const handleGameChange = (selectedItem) => {
    setSort('desc');
    setIsRandomOrder(true);

    const game = games.find(game => game.id === selectedItem.id);

    if(game) {
      setSelectedGame({id: selectedItem.id, type: selectedItem.title});
      setScenarioId(game.scenario);
      setSelectedLevel(0);
    }
  };

  useEffect(() => {
    if (selectedGame) {
      if(selectedGame.type.includes('Account Classification')) {
        refetchClassification();
      } else if(selectedGame.type.includes('Normal Balance')) {
        refetchNormal();
      } else if(selectedGame.type.includes('The Profit')) {
        refetchProfit();
      }
    }
  }, [selectedGame]); 

  useEffect(() => {
    if (scenarioId) {
      refetchScenarioLevels();
    }
  }, [scenarioId]); 
  
  const selectLevelItems = scenarioLevels?.sort((a, b) => a.level - b.level).map(level => ({
    id: level.level,
    title: `Level ${level.level}`,
  })) || [{id: 0, title: 'Level'}];
  
  const selectGameItems = games
    ?.filter(game => game.game_type !== "Balanced Equation")
    .map(game => ({
      id: game.id,
      title: game.game_type,
    })) || [{id: 0, title: 'Game'}];

  const handleScoreByClick = (selectedScoreBy) => {
    setScoreBy(selectedScoreBy);
    setSort('desc');
    setIsRandomOrder(true);
  };

  const refetchClassification = () => {
    refetchClassificationScores();
    refetchClassificationScoresByQuestion();
  }
  
  const refetchNormal = () => {
    refetchNormalScores();
    refetchNormalScoresByQuestion();
  }

  const refetchProfit = () => {
    refetchProfitScores();
    refetchProfitScoresByQuestion();
  }
  
  const handleSortClick = () => {
    const changedSort = sort === 'desc' 
      ? isRandomOrder
        ? 'desc'
        : 'asc'
      : 'desc';
    setIsRandomOrder(false);
    setSort(changedSort);
  };
  
  const handleUpdateResultsClick = () => {
    setIsUpdateResultsButtonClicked(true);
    setSort('desc');
    setIsRandomOrder(true);
    
    if(selectedGame.type.includes('Account Classification')) {
      refetchClassification();
    } else if(selectedGame.type.includes('Normal Balance')) {
      refetchNormal();
    } else if(selectedGame.type.includes('The Profit')) {
      refetchProfit();
    } 
  };

  const formatLeaderboardData = () => {    
    let wholeLeaderboardData = [];
    let scores, scoresByQuestion;

    if(selectedGame.type.includes('Account Classification')) {
      scores = classificationScores;
      scoresByQuestion = classificationScoresByQuestion;
    } else if(selectedGame.type.includes('Normal Balance')) {
      scores = normalScores;
      scoresByQuestion = normalScoresByQuestion
    } else if(selectedGame.type.includes('The Profit')) {
      scores = profitScores;
      scoresByQuestion = profitScoresByQuestion;
    } 
    
    if(scoreBy === 'player') {
      wholeLeaderboardData = courseMembers.map(courseMember => {
        const itemScore = scores.find(score => score.user === courseMember.user_id && score.level === selectedLevel);
        const avarageTime = itemScore ? calculateTimeInSeconds(itemScore.average_time) : 0;
        const formattedScore = avarageTime.toFixed(2);
        
        return {
          name: courseMember.name,
          score: avarageTime,
          formattedScore
        }
      });
    } else if(scoreBy === 'account') {
      const levelScoresByQuestion = scoresByQuestion.filter(scoresByQuestionItem => scoresByQuestionItem.level === selectedLevel);
      wholeLeaderboardData = Object.values(levelScoresByQuestion.reduce((acc, curr) => {
        const { question_name, principle_name, average_time } = curr;
        const name = selectedGame.type.includes('The Profit') 
          ? principle_name
          : question_name;
      
        const timeInSeconds = calculateTimeInSeconds(average_time);

        if (acc[name]) {
          acc[name].total += timeInSeconds;
          acc[name].count += 1;
        } else {
          acc[name] = {
            name: name,
            total: timeInSeconds,
            count: 1,
          };
        }
      
        let score;
        score = acc[name].total / acc[name].count;
        acc[name].formattedScore = score.toFixed(2);
        acc[name].score = score;
      
        return acc;
      }, {}));
    }

    const sortedLeaderboardData = sortAndSelectTop(wholeLeaderboardData, sort, isRandomOrder);

    setData(sortedLeaderboardData);
  }

  useEffect(() => {
    if(
      selectedLevel 
      && selectedGame 
      && courseMembers 
      && ((selectedGame.type.includes('Account Classification') && classificationScoresByQuestion && classificationScores) 
        || (selectedGame.type.includes('Normal Balance') && normalScoresByQuestion && normalScores)
        || (selectedGame.type.includes('The Profit') && profitScoresByQuestion && profitScores))
      && !isLoading
    ) {
      formatLeaderboardData();
    }
  }, [
    selectedLevel,
    selectedGame,
    courseMembers,
    classificationScores,
    normalScores,
    profitScores,
    classificationScoresByQuestion,
    normalScoresByQuestion,
    profitScoresByQuestion,
    isLoading,
    scoreBy,
    sort,
    isRandomOrder
  ]);
  
  const maxScore = data ? data.reduce((max, item) => (item.score > max ? item.score : max), -Infinity) : 0;

  const handlePrevButtonClick = () => {
    navigate('/instructor/insights/overall/accuracy');
  }

  const handleNextButtonClick = () => {
    navigate('/instructor/insights/decision-insights/overall');
  }

  return (
    <SubPagesLayout
      navigation={navigation}
      contentClassName="insights-content-container"
      onFirstButtonClick={handlePrevButtonClick}
      onSecondButtonClick={handleNextButtonClick}
    >
      <h1>Insights | Overall</h1>
      <h2>Average Time</h2>
      <p>This chart displays the average time it took your class to categorize the various accounts correctly.</p>
      <div className="insights-container">
        <Leaderboard
          title={<span>Average Time <span className="small">(in seconds)</span></span>}
          showFormattedScore={true}
          data={data}
          maxScore={maxScore}
          isUpdateResultsButtonClicked={isUpdateResultsButtonClicked}
          onUpdateResultsClick={handleUpdateResultsClick}
          selectLevelItems={selectLevelItems}
          selectedLevel={selectedLevel}
          onLevelSelectChange={handleLevelChange}
          selectGameItems={selectGameItems}
          selectedGame={selectedGame}
          onGameSelectChange={handleGameChange}
          isLoading={isLoading}
          sort={sort}
          onSortClick={handleSortClick}
          scoreBy={scoreBy}
          onScoreByClick={handleScoreByClick}
        />
      </div>
    </SubPagesLayout>
  );
}
