import ErrorIcon from './images/icon-error.png';
import SuccessIcon from './images/icon-success.png';
import InfoIcon from './images/icon-info.png';

export default function Icon({type}) {
  let iconSrc;
  if (type === 'success') {
    iconSrc = SuccessIcon;
  } else if (type === 'error') {
    iconSrc = ErrorIcon;
  } else {
    iconSrc = InfoIcon;
  }

  return (
    <img src={iconSrc} alt={type} />
  );
}