import { Item } from './item';

import './styles.css';

export const LeaderboardOverall = ({
  title,
  data,
  filterBy,
  onFilterByClick,
  maxScore,
  labels = []
}) => {
  return (
    <div className="results-leaderboard-overall-container">
      <div className="leaderboard">
        <div className="leaderboard-header">
          <h2>{title}</h2>
        </div>
        <div className="leaderboard-body">
          {
            labels.length > 0 &&
              <ul className="leaderboard-labels">
                {
                  labels.map((label, index) => (
                    <li key={index}><span className="label-color" style={{backgroundColor: label.color}}></span>{label.name}</li>
                  ))
                }
              </ul>
          }
          <div className='leaderboard-filter-by'>
            <p>Filter By</p>
            <ul>
              <li><button className={filterBy === 'accuracy' ? 'active' : ''} onClick={() => onFilterByClick('accuracy')}>Accuracy</button></li>
              <li><button className={filterBy === 'points' ? 'active' : ''} onClick={() => onFilterByClick('points')}>Points</button></li>
            </ul>
          </div>
          <ul className="leaderboard-data">
            {
              data && data.map((leaderboardItem, index) => (
                <Item
                  key={index + Date()}
                  name={leaderboardItem.name}
                  score={leaderboardItem.score}
                  formattedScore={leaderboardItem.formattedScore}
                  maxScore={maxScore}
                  showFormattedScore={filterBy === 'accuracy'}
                  scoreIndicator={filterBy}
                  backgroundColor={leaderboardItem.backgroundColor}
                  lines={leaderboardItem.lines}
                />
              ))
            }
          </ul>
        </div>
      </div>
    </div>
  );
};
