import { useRef, useState } from 'react';

import './styles.css';

export default function Video({videoSrc}) {
  const [isPlaying, setIsPlaying] = useState(false);
  const videoRef = useRef(null);

  const handleVideoClick = () => {
    if (videoRef.current) {
      if (isPlaying) {
        videoRef.current.pause();
      } else {
        videoRef.current.play();
      }
      setIsPlaying(!isPlaying);
    }
  };

  return (
    <div className="video-wrapper">
      <video
        ref={videoRef}
        className="intro-video"
        src={videoSrc}
        onClick={handleVideoClick}
        onPlay={() => setIsPlaying(true)}
        onPause={() => setIsPlaying(false)}
      ></video>
      <div
        className={`video-overlay ${isPlaying ? 'hidden' : ''}`}
        onClick={handleVideoClick}
      >
        <button className="play-pause-button">
          {isPlaying ? '❚❚' : '▶'}
        </button>
      </div>
    </div>
  );
}
