import { useNavigate } from 'react-router-dom';

import './styles.css';

export default function InstructorAddAssignmentsRow() {
  const navigate = useNavigate();

  return (
    <div className="instructor-assignments">
      <div className="add-assignment">
        <button className="button-blue button-add-assignment" onClick={() => navigate('add')}>
          <span>+</span> Assignment
        </button>
      </div>
      <hr />
    </div>
  );
}
