import { useEffect, useState, useRef } from 'react';
import './styles.css';
// import ScoreUpdateSound from './sounds/score-update.mp3';

export default function Score({ score, playSound, scoreLabel = 'Points' }) {
  const [displayedScore, setDisplayedScore] = useState(0);
  const prevScoreRef = useRef();

  useEffect(() => {
    const updateScoreWithDelay = () => {
      const end = score;
      if (displayedScore !== end) {
        const duration = 1500;
        const steps = 20;
        const stepTime = duration / steps;
        let currentStep = 0;

        const totalChange = end - displayedScore;
        const changePerStep = totalChange / steps;

        const interval = setInterval(() => {
          currentStep++;
          const newScore = displayedScore + changePerStep * currentStep;
          setDisplayedScore(newScore);

          if (currentStep === steps) {
            clearInterval(interval);
            setDisplayedScore(end);
          }
        }, stepTime);

        return () => clearInterval(interval);
      }
    };

    const delayTimeout = setTimeout(updateScoreWithDelay, 1300);

    prevScoreRef.current = score;

    return () => {
      clearTimeout(delayTimeout);
    };
  }, [score]);

  return (
    <div className="score-wrapper">
      <div className="score">
        <span>{scoreLabel}: <b>{Math.round(displayedScore)}</b></span>
      </div>
    </div>
  );
}
