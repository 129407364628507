import { Item } from './item';

import Select from '../../select';

import './styles.css';

export const Leaderboard = ({
  title,
  data,
  maxScore,
  scoreIndicator,
  showFormattedScore,
  isUpdateResultsButtonClicked,
  onUpdateResultsClick,
  selectLevelItems,
  selectedLevel,
  onLevelSelectChange,
  selectGameItems,
  selectedGame,
  onGameSelectChange,
  isLoading,
  sort,
  onSortClick,
  scoreBy,
  onScoreByClick
}) => {
  return (
    <div className="results-leaderboard-container">
      <div className="leaderboard-group">
        <button 
          className={`button-navi button-update-results ${isUpdateResultsButtonClicked ? 'clicked' : ''}`}
          onClick={onUpdateResultsClick}
        >
          Update Results
        </button>
        
        <div className="instructor-insights-selects">
          <Select
            items={selectLevelItems}
            selectedItemId={selectedLevel}
            onSelectChange={onLevelSelectChange}
            />
          <Select
            items={selectGameItems}
            selectedItemId={selectedGame?.id}
            onSelectChange={onGameSelectChange}
          />
        </div>
      </div>
      <div className="leaderboard">
        <div className="leaderboard-header">
          <h2>{title}</h2>
        </div>
        <div className="leaderboard-body">
          <button className={`button-sort ${sort === 'asc' ? 'active' : ''}`} onClick={onSortClick}>Sort</button>
          {
            scoreBy &&
              <div className="leaderboard-buttons leaderboard-body-buttons">
                <button className={scoreBy === 'account' ? 'active' : ''} onClick={() => onScoreByClick('account')}>By Account</button>
                <button className={scoreBy === 'player' ? 'active' : ''} onClick={() => onScoreByClick('player')}>By User</button>
              </div>
          }
          {
            isLoading &&
              <div className="leaderboard-loader"></div>
          }
          {
            !isLoading &&
              
            <ul className="instructor-leaderboard">
              {
                data && data.map((leaderboardItem, index) => (
                  <Item
                    key={index + Date()}
                    name={leaderboardItem.name}
                    score={leaderboardItem.score}
                    maxScore={maxScore}
                    formattedScore={leaderboardItem.formattedScore}
                    showFormattedScore={showFormattedScore}
                    scoreIndicator={scoreIndicator}
                  />
                ))
              }
            </ul>
          }
        </div>
      </div>
    </div>
  );
};
