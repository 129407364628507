import { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import moment from 'moment';

import useAuth from '../../../auth/useAuth';

import './styles.css';

import Alert from '../alert';

import IconChecked from './images/icon-checked.svg';
import IconUnchecked from './images/icon-unchecked.svg';

import AssignmentInfo from './info';

import { useIsInstructorContainingPage } from '../../../utils';


export default function AssignmentItem({ 
  game,
  onGameDeletionConfirm,
  currentGameSessions,
  currentGameScore
}) {
  const navigate = useNavigate();

  const { saveCurrentGameId, isInstructor } = useAuth();

  const [isAlertVisible, setIsAlertVisible] = useState(false);

  const [isInfoVisible, setIsInfoVisible] = useState(false);

  const handleEditButtonClick = () => {
    navigate(`/instructor/settings/assignments/edit/${game.id}`);
  }

  const handleDeleteAssignmentButtonClick = () => {
    setIsAlertVisible(true);
  }

  const handleConfirmAlertButtonClick = () => {
    onGameDeletionConfirm(game.id, handleCloseAlert);
  }

  const handleCloseAlert = () => {
    setIsAlertVisible(false);
  }

  function formatDate(endTime) {
    return moment(endTime).format('MMM D [at] h:mm a');
  }

  const handlePreviewButtonClick = () => {
    saveCurrentGameId(game.id, game.game_type);
    window.open('/acc/transition', '_blank');
  }

  const handleInfoButtonMouseEnter = () => {
    setIsInfoVisible(true);
  }

  const handleInfoButtonMouseLeave = () => {
    setIsInfoVisible(false);
  }

  const handleViewResultsButtonClick = () => {
    saveCurrentGameId(game.id, game.game_type);
    window.open('/classification/feedback', '_blank');
  }

  const isInstructorPage = useIsInstructorContainingPage();

  const isSessionCompleted = currentGameSessions.some(session => session.completed);
  const isSessionIncompleted = currentGameSessions.some(session => !session.completed);
  let assignmentButtonText = 'Start';
  let isAssignmentButtonDisabled = false;
  let assignmentButtonTitle = '';

  if (currentGameSessions.length) {    
    if(currentGameSessions.length >= 3 && !isSessionIncompleted) {
      assignmentButtonText = 'Unavailable';
      isAssignmentButtonDisabled = true;
      assignmentButtonTitle = 'You’ve completed all 3 attempts.';
    } else {
      if(isSessionIncompleted) {
        assignmentButtonText = 'Continue';
      } else {
        assignmentButtonText = 'Try Again?';
      }
    }
  }
  
  const currentTime = moment();
  const gameStartTime = moment(game.start_time);
  const gameEndTime = moment(game.end_time);
  if(currentTime.isBefore(gameStartTime) || currentTime.isAfter(gameEndTime) || (currentGameSessions.length >= 3 && !isSessionIncompleted)) {
    assignmentButtonText = 'Unavailable';
    isAssignmentButtonDisabled = true;
    assignmentButtonTitle = currentTime.isBefore(gameStartTime)
      ? 'Assignment is not open yet.'
      : currentTime.isAfter(gameEndTime)
        ? 'Assignment is now closed.'
        : 'You’ve completed all 3 attempts.';
  }

  return (
    <div className="games-assignment-item">
      {
        (isInstructor() && isInstructorPage) &&
          <button className="edit-assignment" onClick={handleEditButtonClick}></button>
      }
      {
        !isInstructorPage &&
          <img 
            className="assignment-session-completed-status"
            src={isSessionCompleted ? IconChecked : IconUnchecked}
            alt={isSessionCompleted ? 'Completed' : 'Not Completed'}
          />
      }
      <div className="games-assignment-item-info">
        <div className="games-assignment-item-info-heading">
          <h4>{game.name}</h4>
          {
            isInstructor() && onGameDeletionConfirm &&
              <button className="delete-assignment" onClick={handleDeleteAssignmentButtonClick}></button>
          }
          {
            !isInstructorPage &&
              <div className="games-assignment-info-container">
                <button 
                  className="assignment-info-icon"
                  onMouseEnter={handleInfoButtonMouseEnter}
                  onMouseLeave={handleInfoButtonMouseLeave}
                ></button>
                {
                  isInfoVisible &&
                    <AssignmentInfo title={game.name} description={game.description} />
                }
              </div>
          }
        </div>
        <div className="games-assignment-item-info-footer">
          <p>
            <span>Open </span>{formatDate(game.start_time)} <span>| Close</span> {formatDate(game.end_time)}
            {
              !isInstructorPage &&
                <>
                  <span> |</span> {currentGameScore?.toLocaleString()} points 
                  {(game.game_type === 'Account Classification' && isSessionCompleted) && <></>}
                  {/* <span> |</span> <button onClick={handleViewResultsButtonClick}>View Results</button> */}
                </>
            }
          </p>
        </div>
      </div>

      {
        isAlertVisible &&
          <Alert 
            type="warning"
            title={`Are you sure you want to delete ${game.name}?`}
            confirmButtonText="Yes"
            cancelButtonText="No"
            onCancelButtonClick={handleCloseAlert}
            onConfirmButtonClick={handleConfirmAlertButtonClick}
          />
      }

      <button 
        className="preview-assignment button-navi"
        onClick={handlePreviewButtonClick}
        disabled={isAssignmentButtonDisabled}
        title={assignmentButtonTitle}
      >
        {assignmentButtonText}
      </button>
    </div>
  );
}
