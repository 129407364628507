import axios from 'axios';
import AuthService from '../auth/AuthService';
import { BASE_URL } from './endpoints';

const axiosClient = axios.create({
  baseURL: BASE_URL,
  headers: {
    'Accept': 'application/json',
  }
});

axiosClient.interceptors.request.use(
  (config) => {
    const token = AuthService.getToken(); 
    if (token) {
      config.headers['Authorization'] = `Token ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default axiosClient;
