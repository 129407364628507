import { fetchPageData } from '../../api/utils.js';

async function getGames() {
  return fetchPageData(`/api/games/games/`);
}

export const gamesQuery = () => {
  return {
    queryKey: ['games'],
    queryFn: async () => getGames(),
    refetchOnWindowFocus: false
  };
};


async function getSessions() {
  return fetchPageData(`/api/games/sessions/`);
}

export const sessionsQuery = () => {
  return {
    queryKey: ['sessions'],
    queryFn: async () => getSessions(),
    refetchOnWindowFocus: false
  };
};

export async function getScoresClassification({userId}) {
  return fetchPageData(`/api/classification/scores/?user=${userId}`);
}

export const scoresClassificationQuery = ({userId}) => {
  return {
    queryKey: ['scoresClassification'],
    queryFn: async () => getScoresClassification({userId}),
    refetchOnWindowFocus: false,
    enabled: !!userId
  };
};


export async function getScoresNormal({userId}) {
  return fetchPageData(`/api/normal/scores/?user=${userId}`);
}

export const scoresNormalQuery = ({userId}) => {
  return {
    queryKey: ['scoresNormal'],
    queryFn: async () => getScoresNormal({userId}),
    refetchOnWindowFocus: false,
    enabled: !!userId
  };
};

export async function getScoresEquation({userId}) {
  return fetchPageData(`/api/equation/scores/?user=${userId}`);
}

export const scoresEquationQuery = ({userId}) => {
  return {
    queryKey: ['scoresEquation'],
    queryFn: async () => getScoresEquation({userId}),
    refetchOnWindowFocus: false,
    enabled: !!userId
  };
};