import DroppedItem from './droppedItem'; 

export default function List({
  droppedItems,
  side,
  isGameSorted,
  onItemsDropped,
  onItemsCorrectlyPlaced,
  onSortedItemClick,
  container
}) {
  return (
    <>
      {droppedItems.map((item, index) =>
        (
          <DroppedItem
            key={index}
            item={item}
            side={side}
            isGameSorted={isGameSorted}
            onItemDropped={onItemsDropped}
            onItemCorrectlyPlaced={onItemsCorrectlyPlaced}
            onSortedItemClick={onSortedItemClick}
            container={container}
          />
        )
      )}
    </>
  );
}