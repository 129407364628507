import React from 'react';
import { Navigate } from 'react-router-dom';
import useAuth from '../auth/useAuth';

const InstructorRoute = ({ element }) => {
  const { isUserAuthenticated, isInstructor } = useAuth();

  return isUserAuthenticated() && isInstructor() ? (
    element
  ) : (
    <Navigate to="/acc/login" replace />
  );
};

export default InstructorRoute;