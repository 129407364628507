import './styles.css';

export default function InstructorTable({
  title,
  tableColumnHeader,
  tableHeaders,
  tableData,
  tableClassName,
  isDataLoading
}) {
  const noDataTitle = isDataLoading ? 'Loading...' : 'No data available';
  return (
    <div className="sorting-items-container">
      <h3>{title}</h3>
      {
        (tableHeaders && tableData?.length > 0 && !isDataLoading) ? (
          <table className={tableClassName}>
            <thead>
              <tr>
                <th>{tableColumnHeader}</th>
                {tableHeaders.map((header, index) => (
                  <th key={index}>{header}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {tableData.map((row, rowIndex) => (
                <tr key={rowIndex}>
                  {row.isParent ? (
                    <th colSpan={tableHeaders.length + 1} className="item-parent-heading">{row.question}</th>
                  ) : (
                    <>
                      <td>{row.question}</td>
                      {tableHeaders.map((header, index) => (
                        <td
                          key={index}
                          style={{
                            backgroundColor: row[header]?.background,
                          }}
                        >
                          {row[header]?.value || 0}
                        </td>
                      ))}
                    </>
                  )}
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <h4>{noDataTitle}</h4>
        )
      }
    </div>
  );
}
