import './styles.css';

import DropArea from './dropArea'; 

export default function Column({ 
  side,
  options,
  levelNumber,
  handleDrop,
  isDecisionCorrect,
  prePlacedItems = [],
  gameType,
  isGameSorted,
  onSortedItemClick
}) {
  const renderDropAreas = (option) => {
    const itemsForThisOption = prePlacedItems[option.id] || [];
    
    return (
      <DropArea
        key={option.id}
        container={option}
        onDrop={handleDrop}
        side={side}
        isDecisionCorrect={isDecisionCorrect}
        prePlacedItems={itemsForThisOption}
        isGameSorted={isGameSorted}
        onSortedItemClick={onSortedItemClick}
      >
        {option.children.map(child => renderDropAreas(child))}
      </DropArea>
    );
  };

  const gameTypeClass = gameType.includes('Normal Balance')
    ? 'game-type-normal'
    : '';

  return (
    <div className={`game-column-wrapper`}>
      <div className={`game-column ${side}-column level-${levelNumber}-column ${gameTypeClass}`}>
        {options && renderDropAreas(options)}
      </div>
    </div>
  );
};