import { useState, useRef, useEffect } from 'react';
import Message from './message';
import './styles.css';

export default function PopupMessages({ 
  messages,
  buttonText,
  onButtonClick,
  onTypingCompleted,
  messageSize
}) {
  const [visibleMessages, setVisibleMessages] = useState([]);
  const scrollToRef = useRef(null);
  const currentMessageIndex = visibleMessages.length;

  useEffect(() => {
    setVisibleMessages([]);
  }, [messages]);

  useEffect(() => {
    if (scrollToRef.current) {
      scrollToRef.current.scrollIntoView({ behavior: 'smooth', block: 'end' });
    }
  }, [visibleMessages]);

  const handleTypingComplete = (name, text, alignment) => {
    setTimeout(() => {
      setVisibleMessages(prevMessages => [...prevMessages, { name, text, alignment }]);
      
      if (!onButtonClick && currentMessageIndex === messages.length - 1) {
        if (onTypingCompleted) {
          onTypingCompleted();
        }
      }
    }, 500);
  };

  return (
    <div className="popup-messages-container">
      {visibleMessages.map((message, index) => (
        <Message 
          key={index}
          name={message.name}
          text={message.text}
          alignment={message.alignment}
          shouldPlayAppearMessage={false}
          fadeIn={false}
          displayAvatar={index === 0 || visibleMessages[index - 1].name !== message.name}
          size={messageSize}
        />
      ))}
      {currentMessageIndex < messages.length ? (
        <div ref={scrollToRef}>
          <Message
            key={Date()}
            name={messages[currentMessageIndex].name}
            text={messages[currentMessageIndex].text}
            alignment={messages[currentMessageIndex].alignment}
            onTypingCompleted={() => handleTypingComplete(messages[currentMessageIndex].name, messages[currentMessageIndex].text, messages[currentMessageIndex].alignment)}
            fadeIn={true}
            displayAvatar={currentMessageIndex === 0 || visibleMessages[currentMessageIndex - 1].name !== messages[currentMessageIndex].name}
            size={messageSize}
          />
        </div>
      ) : (
        onButtonClick && (
          <button className="popup-messages-button button-red" onClick={onButtonClick}>
            {buttonText}
          </button>
        )
      )}
    </div>
  );
}
